import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Typography } from '@mui/material';
import MiscChargeTab from 'components/summaries/MiscChargeTab';
import MiscPaymentTab from 'components/summaries/MiscPaymentTab';
import { useState } from 'react';
import OnlineTransaction from './OnlineTransaction';
import LoginService from 'services/login.service';

const TransactionSummary = ({ registrationDTO, defaultExpanded, isPrint, updateSingleRegistration }) => {
    const [expanded, setExpanded] = useState(defaultExpanded && true);

    return (
        <>
            <Accordion key='acc_registration_financial_summary' sx={{ mb: 2 }} expanded={expanded} onChange={() => setExpanded(!expanded)}>
                <AccordionSummary key={'acc_registration_financial_summary'} expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="title"><strong>Transactions</strong></Typography>
                </AccordionSummary>
                <AccordionDetails key='acc__detailsRegistration_financial_summary'>
                    {(LoginService.isAdmin() || (registrationDTO.paymentHistoryDTOs && registrationDTO.paymentHistoryDTOs.length > 0)) &&
                        <OnlineTransaction registration={registrationDTO} updateSingleRegistration={updateSingleRegistration} isPrint={isPrint} />
                    }
                    {(LoginService.isAdmin() || (registrationDTO.miscPayments && registrationDTO.miscPayments.length > 0)) &&
                        <MiscPaymentTab registration={registrationDTO} updateSingleRegistration={updateSingleRegistration} isPrint={isPrint} />
                    }
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default TransactionSummary;

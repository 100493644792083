import { Box, Container, Paper, Typography, createTheme, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ThemeProvider } from '@mui/styles';
import { useEffect, useState } from 'react';
import ApiRequest from 'services/api.service';
import LoginService from 'services/login.service';
import { formatTimestamp } from 'services/utils';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2196f3',
        },
        secondary: {
            main: '#f50057',
        },
    },
});

const SponsorshipHistory = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [paymentHistoryDTOs, setPaymentHistoryDTOs] = useState([])

    useEffect(() => {
        ApiRequest.handleGetApiRequest('/payment/sponsorshipHistory').then((response) => {
            setPaymentHistoryDTOs(response || [])
            setIsLoading(false);
        });
    }, []);

    if (isLoading) {
        return (
            <div>Loading</div>
        )
    }

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="xl" sx={{ marginTop: 4, overflow: 'auto' }}>
                {/* <Paper elevation={3} sx={{ p: 2, mb: 2 }}> */}
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="sponsorship history table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sr no</TableCell>
                                    {LoginService.isAdmin() && (
                                        <>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Number</TableCell>
                                        </>
                                    )}
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Pledge</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Txn Id</TableCell>
                                    <TableCell>Date</TableCell>
                                    {/* <TableCell>Comments</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paymentHistoryDTOs.map((transaction, index) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">
                                            {index + 1}
                                        </TableCell>
                                        {LoginService.isAdmin() && (
                                            <>
                                                <TableCell>{transaction.name}</TableCell>
                                                <TableCell>{transaction.email}</TableCell>
                                                <TableCell>{`${transaction.countryCode} - ${transaction.contactNumber}`}</TableCell>
                                            </>
                                        )}
                                        <TableCell>{transaction.amountPaid}</TableCell>
                                        <TableCell>{transaction.pledge ? 'Yes' : 'No'}</TableCell>
                                        <TableCell>{transaction.sponsorshipType}</TableCell>
                                        <TableCell>{transaction.paymentId}</TableCell>
                                        <TableCell>{formatTimestamp(transaction.creationTimestamp)}</TableCell>
                                        {/* <TableCell>{transaction.comments}</TableCell> */}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                {/* </Paper> */}
            </Container>
        </ThemeProvider>
    );
};

export default SponsorshipHistory;

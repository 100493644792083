import React, { useState } from 'react';
import { TextField, Button, Typography, Box, IconButton, Card, CardContent } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import { AddBox } from '@mui/icons-material';

const YatraChargesForm = ({ onYatraChargesChange }) => {
  const [yatraCharge, setYatraCharge] = useState({
    id: null,
    displayText: '',
    value: ''
  });

  const [isEditing, setIsEditing] = useState(false);
  const [yatraCharges, setYatraCharges] = useState([]);
  const [error, setError] = useState('');

  const handleYatraChargeChange = (e) => {
    const { name, value } = e.target;
    setYatraCharge({
      ...yatraCharge,
      [name]: value
    });
  };

  const handleAddYatraCharge = () => {
    if (yatraCharge.displayText.trim() !== '' && yatraCharge.value.trim() !== '') {
      if (isEditing) {
        const updatedYatraCharges = yatraCharges.map((charge, index) =>
          index === yatraCharge.id ? yatraCharge : charge
        );
        setYatraCharges(updatedYatraCharges);
        setIsEditing(false);
      } else {
        setYatraCharges([...yatraCharges, yatraCharge]);
      }
      resetYatraCharge();
      setError('');
    } else {
      setError('Both Display Text and Value are required.');
    }
  };

  const handleEditYatraCharge = (index) => {
    const editedYatraCharge = yatraCharges[index];
    setYatraCharge({
      ...editedYatraCharge,
      id: index
    });
    setIsEditing(true);
  };

  const handleDeleteYatraCharge = (index) => {
    const updatedYatraCharges = yatraCharges.filter((charge, i) => i !== index);
    setYatraCharges(updatedYatraCharges);
  };

  const resetYatraCharge = () => {
    setYatraCharge({
      id: null,
      displayText: '',
      value: ''
    });
  };

  const handleClearError = () => {
    setError('');
  };

  const handleUpdateYatraCharge = () => {
    if (isEditing) {
      handleAddYatraCharge();
    }
  };

  return (
    <Box>
      <Typography variant="h6">Yatra Charges</Typography>
      {error && <Typography variant="body2" color="error">{error}</Typography>}
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
          <tbody>
            {yatraCharges.map((charge, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>{charge.displayText}</td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>{charge.value}</td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                  <IconButton onClick={() => handleEditYatraCharge(index)} aria-label="edit">
                    <EditIcon />
                  </IconButton>
                </td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                  <IconButton onClick={() => handleDeleteYatraCharge(index)} aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        {yatraCharges.map((charge, index) => (
          <Card key={index} sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="subtitle1">{charge.displayText}</Typography>
              <Typography variant="body2">{charge.value}</Typography>
              <IconButton onClick={() => handleEditYatraCharge(index)} aria-label="edit">
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleDeleteYatraCharge(index)} aria-label="delete">
                <DeleteIcon />
              </IconButton>
            </CardContent>
          </Card>
        ))}
      </Box>
      <TextField name="displayText" label="Display Text" value={yatraCharge.displayText} onChange={handleYatraChargeChange} />
      <TextField name="value" label="Value" value={yatraCharge.value} onChange={handleYatraChargeChange} />
      <br />
      <Button variant="contained" onClick={handleAddYatraCharge} sx={{ mt: 1 }}>
        {isEditing ? <UpdateIcon sx={{ mr: 1 }} /> : <AddBox sx={{ mr: 1 }} />} {isEditing ? 'Update Yatra Charge' : 'Add Yatra Charge'}
      </Button>
    </Box>
  );
};

export default YatraChargesForm;

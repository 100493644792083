import React, { useState } from 'react';
import { TextField, Button, Typography, Box, IconButton, Card, CardContent } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import { AddBox } from '@mui/icons-material';

const TransportationDetailsForm = ({ onTransportationChange }) => {
  const [transportation, setTransportation] = useState({
    id: null,
    displayText: '',
    value: ''
  });

  const [isEditing, setIsEditing] = useState(false);
  const [transportationDetails, setTransportationDetails] = useState([]);
  const [error, setError] = useState('');

  const handleTransportationChange = (e) => {
    const { name, value } = e.target;
    setTransportation({
      ...transportation,
      [name]: value
    });
  };

  const handleAddTransportation = () => {
    if (transportation.displayText.trim() !== '' && transportation.value.trim() !== '') {
      if (isEditing) {
        const updatedTransportationDetails = transportationDetails.map((detail, index) =>
          index === transportation.id ? transportation : detail
        );
        setTransportationDetails(updatedTransportationDetails);
        setIsEditing(false);
      } else {
        setTransportationDetails([...transportationDetails, transportation]);
      }
      resetTransportation();
      setError('');
    } else {
      setError('Both Display Text and Value are required.');
    }
  };

  const handleEditTransportation = (index) => {
    const editedTransportation = transportationDetails[index];
    setTransportation({
      ...editedTransportation,
      id: index,
    });
    setIsEditing(true);
  };

  const handleDeleteTransportation = (index) => {
    const updatedTransportationDetails = transportationDetails.filter((detail, i) => i !== index);
    setTransportationDetails(updatedTransportationDetails);
  };

  const resetTransportation = () => {
    setTransportation({
      id: null,
      displayText: '',
      value: ''
    });
  };

  const handleClearError = () => {
    setError('');
  };

  const handleUpdateTransportation = () => {
    if (isEditing) {
      handleAddTransportation();
    }
  };

  return (
    <Box>
      <Typography variant="h6">Transportation Details</Typography>
      {error && <Typography variant="body2" color="error">{error}</Typography>}
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
          <tbody>
            {transportationDetails.map((detail, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>{detail.displayText}</td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>{detail.value}</td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                  <IconButton onClick={() => handleEditTransportation(index)} aria-label="edit">
                    <EditIcon />
                  </IconButton>
                </td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                  <IconButton onClick={() => handleDeleteTransportation(index)} aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        {transportationDetails.map((detail, index) => (
          <Card key={index} sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="subtitle1">{detail.displayText}</Typography>
              <Typography variant="body2">{detail.value}</Typography>
              <IconButton onClick={() => handleEditTransportation(index)} aria-label="edit">
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleDeleteTransportation(index)} aria-label="delete">
                <DeleteIcon />
              </IconButton>
            </CardContent>
          </Card>
        ))}
      </Box>
      <TextField name="displayText" label="Display Text" value={transportation.displayText} onChange={handleTransportationChange} />
      <TextField name="value" label="Value" value={transportation.value} onChange={handleTransportationChange} />
      <br />
      <Button variant="contained" onClick={handleAddTransportation} sx={{ mt: 1 }}>
        {isEditing ? <UpdateIcon sx={{ mr: 1 }} /> : <AddBox sx={{ mr: 1 }} />} {isEditing ? 'Update Transportation Detail' : 'Add Transportation Detail'}
      </Button>
    </Box>
  );
};

export default TransportationDetailsForm;

import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ApiRequest from 'services/api.service';
import { useState } from 'react';

const validationSchema = Yup.object().shape({
    amount: Yup.number().required('Amount is required').min(0, 'Amount must be greater than or equal to 0'),
    type: Yup.string().required('Type is required'),
    description: Yup.string().required('Description is required'),
});

const initialValues = {
    amount: '',
    type: '',
    description: '',
};

const AddMiscChargeDialog = ({ openDialog, handleCloseDialog, registration, updateSingleRegistration }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (values) => {
        setIsSubmitting(true)
        const response = await ApiRequest.handlePostApiRequest("/admin/add/miscCharge", values, { regId: registration.id });
        if (!response) {
            alert("Error while saving the data");
            setIsSubmitting(false);
            return;
        }
        await updateSingleRegistration(response);
        setIsSubmitting(false);
        handleCloseDialog();
    };

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        handleCloseDialog();
    }

    return (
        <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle>Add Misc Charge</DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm }) => {
                        handleSubmit(values);
                        resetForm();
                    }}
                >
                    {({ errors, touched }) => (
                        <Form>
                            <Field
                                as={TextField}
                                name="amount"
                                label="Amount *"
                                type="number"
                                fullWidth
                                InputProps={{ inputProps: { min: 0 } }}
                                error={errors.amount && touched.amount}
                                helperText={errors.amount && touched.amount && errors.amount}
                            />

                            <FormControl fullWidth error={errors.type && touched.type}>
                                <InputLabel id="type-label">Type *</InputLabel>
                                <Field
                                    as={Select}
                                    name="type"
                                    labelId="type-label"
                                >
                                    <MenuItem value="WAIVE_OFF">WAIVE_OFF</MenuItem>
                                    <MenuItem value="CHARGE">CHARGE</MenuItem>
                                </Field>
                                <FormHelperText>{errors.type && touched.type && errors.type}</FormHelperText>
                            </FormControl>

                            <Field
                                as={TextField}
                                name="description"
                                label="Description *"
                                fullWidth
                                error={errors.description && touched.description}
                                helperText={errors.description && touched.description && errors.description}
                            />

                            <DialogActions>
                                <div className="registration-form-content-actions" style={{ 'justifyContent': 'space-around' }}>
                                    <Button variant="contained" onClick={handleCloseDialog} disabled={isSubmitting}>Cancel</Button>
                                    <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>Submit</Button>
                                </div>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default AddMiscChargeDialog;

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Button, Container, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Typography, createTheme } from '@mui/material';
import { ThemeProvider, makeStyles } from '@mui/styles';
import { ValidationPopup } from 'components/generics/ValidationPopup';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import ApiRequest from 'services/api.service';
import RoomInventoryCard from './RoomInventoryCard';
import RoomInventoryDialog from './RoomInventoryDialog';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2196f3',
        },
        secondary: {
            main: '#f50057',
        },
    },
});

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%', // Ensure the container takes full height
        display: 'flex', // Make container flexbox
        flexDirection: 'column', // Set flex direction to column
        overflowY: 'auto', // Add overflow property to enable scrolling
    },
    card: {
        overflow: 'auto', // Add overflow property to make the card scrollable
        flexGrow: 1, // Allow the card to grow to fill remaining space
    },
    divider: {
        // margin: theme.spacing(2, 0), // Add margin to the divider
    },
    noBorder: {
        border: 'none', // Remove border from TableCell
    },
}));

const RoomInventory = () => {

    const [error, setError] = useState('');
    const [confirmMessage, setConfirmMessage] = useState('')
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupAction, setPopupAction] = useState('error');
    const [popupType, setPopupType] = useState('error');

    const [openDialog, setOpenDialog] = useState(false);
    const [expanded, setExpanded] = useState(true);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const classes = useStyles();

    // Define state for destinations
    const [destinations, setDestinations] = useState([]);
    const [destination, setDestination] = useState(null);
    const [option, setOption] = useState(null);
    const [roomInventoryItems, setRoomInventoryItems] = useState([])
    const [roomInventoryItem, setRoomInventoryItem] = useState({})

    // Define state for loading destinations
    const [isLoadingDestinations, setIsLoadingDestinations] = useState(true);

    // Define state for selected destination
    const [selectedDestination, setSelectedDestination] = useState(0);

    const [registrationStatus, setRegistrationStatus] = useState("REGISTERED"); // Default value for registration status

    const handleAddInventory = (destination, option) => {
        setDestination(destination)
        setOption(option)
        setRoomInventoryItem({})
        handleOpenDialog()
    }

    const handleEditInventory = (item, destination, option) => {
        setDestination(destination)
        setOption(option)
        setRoomInventoryItem(item)
        handleOpenDialog()
    }

    const handleDeleteInventory = (roomInventoryItem) => {
        setPopupType('confirm');
        setConfirmMessage('Are you sure, you want to delete this inventory?')
        setIsPopupOpen(true);
        setPopupAction(() => async () => {
            setIsPopupOpen(false);
            setPopupType('error');
            const roomInventoryItemsResponse = await ApiRequest.handleDeleteApiRequest('/admin/roomAllocation/roomInventoryItem', {}, roomInventoryItem);
            roomInventoryItemsResponse && setRoomInventoryItems(roomInventoryItemsResponse)
        });
    }

    const handleRegistrationStatusChange = (event) => {
        const status = event.target.value;
        setRegistrationStatus(status);
        handleReloadData(status === "ALL" ? null : status);
    };

    const handleReloadData = async (status) => {
        const response = await ApiRequest.handleGetApiRequest('/admin/stats/destinations', { registrationStatus: status });
        const roomInventoryItemsResponse = await ApiRequest.handleGetApiRequest('/admin/roomAllocation/roomInventoryItem');
        // const roomInventoryItemsResponse = roomInventoryItems
        if (response && roomInventoryItemsResponse) {
            setDestinations(response);
            setRoomInventoryItems(roomInventoryItemsResponse)
            setIsLoadingDestinations(false);
        } else {
            alert("Error fetching the data");
        }
    }

    const [expandedAccordions, setExpandedAccordions] = useState({});
    const handleAccordionChange = (optionType) => (event, isExpanded) => {
        setExpandedAccordions((prev) => ({
            ...prev,
            [optionType]: isExpanded,
        }));
    };

    // Fetch destinations
    useEffect(() => {
        handleReloadData(registrationStatus)
    }, []);

    if (isLoadingDestinations) {
        return <div>Loading...</div>; // Render loading indicator while data is being fetched
    }

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="md" className={classes.container}>
                <RoomInventoryDialog
                    openDialog={openDialog}
                    handleCloseDialog={handleCloseDialog}
                    setRoomInventoryItems={setRoomInventoryItems}
                    roomInventoryItem={roomInventoryItem}
                    destination={destination}
                    option={option}
                />

                <ValidationPopup
                    isOpen={isPopupOpen}
                    message={popupType === 'error' ? error : confirmMessage}
                    type={popupType}
                    onConfirm={popupAction}
                    onCancel={() => {
                        setIsPopupOpen(false);
                        setPopupType('error');
                        setError('')
                    }}
                />

                <div className="page-section">
                    {/* Title */}
                    <div className="registration-form-content-actions">
                        <Grid container justifyContent="space-between" alignItems="center" marginTop='20px'>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body2" align='left' style={{ fontSize: '1rem' }}>
                                    <strong>
                                        {'Destination ' + (selectedDestination + 1) + ' : ' + destinations[selectedDestination].location.toUpperCase()}
                                    </strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="destination-label">Select Destination</InputLabel>
                                    <Select
                                        labelId="destination-label"
                                        id="destination-select"
                                        value={selectedDestination}
                                        onChange={(e) => setSelectedDestination(e.target.value)}
                                    >
                                        {destinations.map((destination, index) => (
                                            <MenuItem key={index} value={destination.destinationNumber}>
                                                {destination.displayText}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                                    <InputLabel id="registration-status-label">Registration Status</InputLabel>
                                    <Select
                                        labelId="registration-status-label"
                                        id="registration-status-select"
                                        value={registrationStatus}
                                        onChange={handleRegistrationStatusChange}
                                        label="Registration Status"
                                        style={{ marginRight: '10px' }}
                                    >
                                        <MenuItem value="ALL">ALL</MenuItem>
                                        <MenuItem value="REGISTERED">REGISTERED</MenuItem>
                                        <MenuItem value="NOT_REGISTERED">NOT_REGISTERED</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body2" align='left' style={{ fontSize: '0.9rem' }}>
                                    <strong>{destinations[selectedDestination].info}</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body2" align='right' style={{ fontSize: '0.7rem', color: 'orange' }}>
                                    {dayjs(destinations[selectedDestination].checkIn).format('MMM DD, YYYY')} to {dayjs(destinations[selectedDestination].checkOut).format('MMM DD, YYYY')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>

                    <Grid container justifyContent="space-between" alignItems="center" >
                        <Grid item xs={12}>
                            {Object.values(destinations[selectedDestination].roomOptionStats)
                                .filter(option => option.type !== 'own_arrangement' && option.type !== 'not_attending')
                                .map((option, index) => (
                                    <Accordion key={index}
                                        //expanded={expanded} onChange={() => setExpanded(!expanded)}
                                        expanded={expandedAccordions[option.type] ?? true}
                                        onChange={handleAccordionChange(option.type)}
                                    >
                                        <AccordionSummary
                                            aria-controls="panel-content"
                                            expandIcon={<ExpandMoreIcon />}
                                            id="panel-header"
                                        >
                                            <Grid container justifyContent="space-between" alignItems="center" marginTop='20px'>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography>{option.displayText}</Typography>
                                                </Grid>
                                                <Grid item xs={6} sm={3}>
                                                    <Typography>{option.count}</Typography>
                                                </Grid>
                                                <Grid item xs={6} sm={3}>
                                                    <div className="registration-form-content-actions">
                                                        <Button
                                                            variant="contained"
                                                            onClick={(event) => { event.stopPropagation(); handleAddInventory(destinations[selectedDestination], option); }}
                                                            sx={{ mt: 1 }}
                                                        >
                                                            <span style={{ fontSize: '12px' }}>
                                                                Add Inventory
                                                            </span>
                                                        </Button>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {roomInventoryItems[selectedDestination][option.type] && roomInventoryItems[selectedDestination][option.type].map((item, idx) => (
                                                <div key={idx}>
                                                    <RoomInventoryCard
                                                        item={item}
                                                        onEdit={handleEditInventory}
                                                        onDelete={handleDeleteInventory}
                                                        destination={destinations[selectedDestination]}
                                                        option={option}
                                                    />
                                                    <Divider sx={{ mt: 4 }} />
                                                </div>
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </ThemeProvider>
    );
};

export default RoomInventory;

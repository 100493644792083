// App.js
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ApiRequest from '../../services/api.service';
import { isEqual } from '../../services/is.equal';
import Info from './Info';
import MemberDetailsForm from './MemberDetailsForm';
import NavBar from './NavBar';
import PaymentTab from './PaymentTab';
import RoomDetailsForm from './RoomDetailsForm';
import SummaryTab from './SummaryTab';
import UserDetailsForm from './UserDetailsForm';
import { useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';
import LoginService from 'services/login.service';

const RegistrationForm = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const registration = location.state?.registration;
  const inputConfigData = location.state?.inputConfigData;
  const from = location.state?.from;
  // const {registration, inputConfigData} = {location.state.registration, location.state.inputConfigData}

  const [regId, setRegId] = useState(registration?.id || undefined);
  const [configData, setConfigData] = useState(inputConfigData || undefined);
  const [isConfigLoading, setIsConfigLoading] = useState(true);
  // const [reloadInitiated, setReloadInitiated] = useState(true);
  const [registrationDTO, setRegistrationDTO] = useState(() => {
    if (registration) {
      return registration;
    } else {
      return {
        id: null,
        userDetails: {},
        members: [],
        roomChoices: [],
        comments: "",
        isRoomsFinalized: false,
      };
    }
  });

  useEffect(() => {
    setIsConfigLoading(true);
    if (!configData) {
      ApiRequest.handleGetApiRequest('/config')
        .then((response) => {
          setConfigData(response);
        })
        .catch((error) => {
          console.error("Failed to fetch config:", error);
          // Handle error appropriately
        })
        .finally(() => {
          setIsConfigLoading(false);
        });
    } else {
      setIsConfigLoading(false);
    }
  }, []); // Empty dependency array means this runs once on mount

  useEffect(() => {
    if (configData) {
      setRegistrationDTO((currentDTO) => {
        // If registration is already set, return it without modification
        if (registration) {
          return registration;
        }
        // Otherwise, set up the default DTO including yatraId from configData
        return {
          ...currentDTO, // Spread in current DTO to preserve structure
          yatraId: configData.id,
        };
      });
    }
  }, [configData, registration]); // Depend on configData and registration


  const [currentStep, setCurrentStep] = useState(1);
  const [step, setStep] = useState(1);

  // const navigate = useNavigate();

  // Function to haconst navigate = useNavigate();

  // Function to handle the beforeunload event
  const handleBeforeUnload = (event) => {
    // Display a confirmation message
    event.preventDefault();
    event.returnValue = 'Are you sure you want to leave? Your changes may not be saved.';
    // Set a flag for redirection after refresh
    sessionStorage.setItem('refreshed', 'true');
  };

  useEffect(() => {
    // Add the beforeunload event listener
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Check if the 'refreshed' flag is set in sessionStorage on component mount
    if (sessionStorage.getItem('refreshed')) {
      // Perform the redirection
      navigate(from);
      // Optionally, clear the flag after redirecting
      sessionStorage.removeItem('refreshed');
    }

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [navigate]);

  if (isConfigLoading) {
    return <div>Loading...</div>; // Render loading indicator while data is being fetched
  }

  const nextStep = () => {
    const newStep = currentStep + 1;
    setCurrentStep(newStep);
    setStep(step + 1);
  }
  const prevStep = () => {
    const newStep = currentStep - 1;
    setCurrentStep(newStep);
    setStep(step - 1);
  }

  const handleCrudInDB = async (key, value) => {
    // If there is value and both are equal then return
    if (value !== undefined && registrationDTO[key] !== undefined && isEqual(registrationDTO[key], value))
      return;

    // Create a new object with the updated key-value pair
    const updatedRegistrationDTO = {
      ...registrationDTO,
      [key]: value, // Use computed property name syntax to set the key dynamically
    };

    // Update the registrationDTO state with the new object
    setRegistrationDTO(updatedRegistrationDTO);

    // Call the function to handle save or edit operation with the updated registrationDTO
    await handleSaveEditRegistration(updatedRegistrationDTO);
  }


  const handleSaveEditRegistration = async (registrationDTO) => {

    // console.log(registrationDTO)
    try {
      let response;

      if (regId) {
        response = await ApiRequest.handlePutApiRequest("/registration", registrationDTO);
      } else {
        response = await ApiRequest.handlePostApiRequest("/registration", registrationDTO);
      }

      if (!response) {
        alert("Error while saving the data");
      } else {
        setRegId(response.id);
        setRegistrationDTO(response);
      }
    } catch (error) {
      console.error("API request failed:", error);
      alert("Error while saving the data");
    }
  };

  if(!LoginService.isUserLoggedIn()) {
    navigate('/login')
  }

  return (

    <Container maxWidth="md" >
      <div class="registration-form">
        <div className="registration-form-content">
          <NavBar currentStep={currentStep} />
          {currentStep === 1 &&
            <Info
              nextStep={nextStep}
              configData={configData}
              from={from}
            // setReloadInitiated={setReloadInitiated}
            />
          }
          {currentStep === 2 &&
            <UserDetailsForm
              configData={configData}
              registrationDTO={registrationDTO}
              handleCrudInDB={handleCrudInDB}
              nextStep={nextStep} prevStep={prevStep} />
          }
          {currentStep === 3 &&
            <MemberDetailsForm
              configData={configData}
              registrationDTO={registrationDTO}
              handleCrudInDB={handleCrudInDB}
              nextStep={nextStep} prevStep={prevStep} />
          }
          {currentStep === 4 &&
            <RoomDetailsForm
              configData={configData}
              registrationDTO={registrationDTO}
              handleCrudInDB={handleCrudInDB}
              nextStep={nextStep} prevStep={prevStep} />
          }
          {currentStep === 5 &&
            <SummaryTab
              configData={configData}
              registrationDTO={registrationDTO}
              handleCrudInDB={handleCrudInDB}
              nextStep={nextStep} prevStep={prevStep}
            />
          }
          {currentStep === 6 &&
            <PaymentTab
              registrationDTO={registrationDTO}
              from={from}
              // setReloadInitiated={setReloadInitiated}
              prevStep={prevStep}
            />
          }
        </div>
      </div>
    </Container>

  );
};

export default RegistrationForm;

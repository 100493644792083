import React from 'react';

import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';

import { footerNavigationLinksControls } from 'constants/jsons/footer-controls';
import { headerLinkControls, adminLinkControls, registeredUserLinkControls, accountsLinkControls } from 'constants/jsons/sidebar-links';

import styles from './Sidebar.module.scss';

function Sidebar(props) {

  const { loginStatus, isAdmin, isAccountsPermission, onClose } = props;

  function renderSidebarHeader() {

    const closeControlAttributes = {
      className: styles.closeControl,
      onClick: onClose
    };

    return (
      <div className={styles.sidebarHeader}>
        <label>Dham Yatra</label>
        <IconButton {...closeControlAttributes}>
          <CloseIcon />
        </IconButton>
      </div>
    );
  }

  function renderSidebarLinkControl(item) {

    const sidebarLinkControlAttributes = {
      to: item.link,
      key: item.label,
      className: styles.sidebarLink,
      onClick: onClose
    };

    return <Link {...sidebarLinkControlAttributes}>{item.label}</Link>
  }

  function renderHeaderLinkControls() {

    return (
      <React.Fragment>
        {
          headerLinkControls.map((item) => (
            renderSidebarLinkControl(item)
          ))
        }
        <div className={styles.sectionDivider}></div>
      </React.Fragment>
    );

  }

  function renderFooterLinkControls() {

    return (
      <React.Fragment>
        {
          footerNavigationLinksControls.map((item) => (
            renderSidebarLinkControl(item)
          ))
        }
        <div className={styles.sectionDivider}></div>
      </React.Fragment>
    );

  }

  function renderRegisteredUserLinkControls() {

    if (!loginStatus) {
      return;
    }

    return (
      <React.Fragment>
        {
          registeredUserLinkControls.map((item) => (
            renderSidebarLinkControl(item)
          ))
        }
        <div className={styles.sectionDivider}></div>
      </React.Fragment>
    );

  }

  function renderAccountsLinkControls() {
    if (!isAccountsPermission) {
      return;
    }

    return (
      <React.Fragment>
        {
          accountsLinkControls.map((item) => (
            renderSidebarLinkControl(item)
          ))
        }
        <div className={styles.sectionDivider}></div>
      </React.Fragment>
    );
  }

  function renderAdminLinkControls() {

    if (!isAdmin) {
      return;
    }

    return (
      <React.Fragment>
        {
          adminLinkControls.map((item) => (
            renderSidebarLinkControl(item)
          ))
        }
        <div className={styles.sectionDivider}></div>
      </React.Fragment>
    );

  }

  const sidebarMainAttributes = {
    className: styles.sidebarMain,
    onClick: onClose
  };

  const sidebarContainerAttributes = {
    className: styles.sidebarContainer,
    onClick(event) {
      event.stopPropagation();
    }
  };

  return (
    <div {...sidebarMainAttributes}>
      <div {...sidebarContainerAttributes}>
        {renderSidebarHeader()}
        <div className={styles.sidebarLinksContainer}>
          {renderHeaderLinkControls()}
          {renderRegisteredUserLinkControls()}
          {renderAccountsLinkControls()}
          {renderAdminLinkControls()}
          {renderFooterLinkControls()}
        </div>
      </div>
    </div>
  );

}

export default Sidebar;
import { Button, Container, Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, createTheme } from '@mui/material';
import { ThemeProvider, makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import ApiRequest from 'services/api.service';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2196f3',
        },
        secondary: {
            main: '#f50057',
        },
    },
});

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%', // Ensure the container takes full height
        display: 'flex', // Make container flexbox
        flexDirection: 'column', // Set flex direction to column
        overflowY: 'auto', // Add overflow property to enable scrolling
    },
    card: {
        overflow: 'auto', // Add overflow property to make the card scrollable
        flexGrow: 1, // Allow the card to grow to fill remaining space
    },
    divider: {
        // margin: theme.spacing(2, 0), // Add margin to the divider
    },
    noBorder: {
        border: 'none', // Remove border from TableCell
    },
}));

const AgeStats = () => {
    const classes = useStyles();
    const [isAgeLoading, setAgeLoading] = useState(true);
    const [ageStats, setAgeStats] = useState([]);
    const [ageLimit, setAgeLimit] = useState("5");
    const [ageLimitInput, setAgeLimitInput] = useState("5");
    const [memberStatus, setMemberStatus] = useState("REGISTERED"); // Default value for member status
    const [showCostOrCount, setShowCostOrCount] = useState(false); // Default value for show cost or count

    useEffect(() => {
        handleSubmit();
    }, []);

    const handleAgeLimitChange = (event) => {
        // Only allow numbers
        const inputAge = event.target.value.replace(/\D/, ''); // Replace non-numeric characters with an empty string
        setAgeLimitInput(inputAge);
    };

    const handleMemberStatusChange = (event) => {
        setMemberStatus(event.target.value);
    };

    const handleShowCostOrCountChange = (event) => {
        setShowCostOrCount(event.target.value);
    };

    const handleSubmit = () => {
        // Validate age input
        const inputAge = parseInt(ageLimitInput);
        if (isNaN(inputAge) || inputAge <= 0) {
            // If age is not a positive number, show an error message and return early
            alert("Please enter a valid age.");
            return;
        }

        setAgeLimit(inputAge);
        const status = memberStatus === 'ALL' ? null : memberStatus;
        ApiRequest.handleGetApiRequest('/admin/stats/age', { ageLimit: inputAge, memberStatus: status, showCost: showCostOrCount }).then((response) => {
            response.sort((a, b) => new Date(a.date) - new Date(b.date));
            setAgeStats(response);
            setAgeLoading(false);
        });
    };

    if (isAgeLoading) {
        return <div>Loading...</div>; // Render loading indicator while data is being fetched
    }

    return (

        <ThemeProvider theme={theme}>
            <Container maxWidth="md" className={classes.container}>

                <div className="page-section">
                    {/* Title */}
                    <div className="registration-form-content-actions">
                        <Grid container justifyContent="space-between" alignItems="center" marginTop='20px'>
                            <Grid item xs={6} sm={3}>
                                <TextField
                                    id="age-limit-input"
                                    label="Age"
                                    fullWidth
                                    variant="outlined"
                                    value={ageLimitInput}
                                    onChange={handleAgeLimitChange}
                                    style={{ marginRight: '10px' }}
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                                    <InputLabel id="member-status-label">Member Status</InputLabel>
                                    <Select
                                        labelId="member-status-label"
                                        id="member-status-select"
                                        value={memberStatus}
                                        onChange={handleMemberStatusChange}
                                        label="Member Status"
                                        style={{ marginRight: '10px' }}
                                    >
                                        <MenuItem value="ALL">ALL</MenuItem>
                                        <MenuItem value="REGISTERED">REGISTERED</MenuItem>
                                        <MenuItem value="NOT_REGISTERED">NOT_REGISTERED</MenuItem>
                                        <MenuItem value="CANCELLED">CANCELLED</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="show-cost" name="show-cost" value={showCostOrCount} onChange={handleShowCostOrCountChange}>
                                            <FormControlLabel value="true" control={<Radio />} label="Cost" />
                                        </RadioGroup>
                                    </FormControl>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="show-count" name="show-count" value={showCostOrCount} onChange={handleShowCostOrCountChange}>
                                            <FormControlLabel value="false" control={<Radio />} label="Count" />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Button variant="contained" onClick={handleSubmit}>Submit</Button>
                            </Grid>
                        </Grid>
                    </div>

                    <Grid container justifyContent="space-between" alignItems="center" >
                        <Grid item xs={12}>
                            <Paper style={{ overflowY: 'auto' }}>
                                <Table style={{ minWidth: "300px" }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><strong>Date</strong></TableCell>
                                            <TableCell><strong>{ageLimit} years old or younger</strong></TableCell>
                                            <TableCell><strong>Elder than {ageLimit}</strong></TableCell>
                                            <TableCell><strong>Total</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {ageStats.map((row, idx) => (
                                            <TableRow key={idx}>
                                                <TableCell>{row.date ? row.date : 'Over all'}</TableCell>
                                                <TableCell>{row.lessThan}</TableCell>
                                                <TableCell>{row.greaterThan}</TableCell>
                                                <TableCell>{row.total}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <Divider sx={{ mt: 4 }} />
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </ThemeProvider>
    );
};

export default AgeStats;

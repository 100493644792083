import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Box, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { AddBox } from '@mui/icons-material';
import UpdateIcon from '@mui/icons-material/Update';

const PreferredLanguagesForm = ({ onLanguagesChange, initialLanguages }) => {
  const [language, setLanguage] = useState('');
  const [languages, setLanguages] = useState(initialLanguages || []);
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [error, setError] = useState('');

  // useEffect(() => {
  //   setLanguages(initialLanguages || []);
  // }, [initialLanguages]);

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  const handleAddLanguage = () => {
    if (language.trim() !== '') {
      if (isEditing) {
        const updatedLanguages = [...languages];
        updatedLanguages[editIndex] = language;
        setLanguages(updatedLanguages);
        setIsEditing(false);
        setEditIndex(null);
      } else {
        setLanguages([...languages, language]);
      }
      setLanguage('');
      setError('');
    } else {
      setError('Language cannot be empty.');
    }
  };

  const handleEditLanguage = (index) => {
    setLanguage(languages[index]);
    setIsEditing(true);
    setEditIndex(index);
  };

  const handleDeleteLanguage = (index) => {
    const updatedLanguages = languages.filter((lang, i) => i !== index);
    setLanguages(updatedLanguages);
  };

  const handleClearError = () => {
    setError('');
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ mt: 2 }}>Preferred Hearing Languages</Typography>
      {error && <Typography variant="body2" color="error">{error}</Typography>}
      {/* Display in table format on laptop */}
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
          <tbody>
            {languages.map((lang, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>{lang}</td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                  <IconButton onClick={() => handleEditLanguage(index)} aria-label="edit">
                    <EditIcon />
                  </IconButton>
                </td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                  <IconButton onClick={() => handleDeleteLanguage(index)} aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
      {/* Display in list format on mobile */}
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        {languages.map((lang, index) => (
          <Box key={index} display="flex" alignItems="center">
            <Typography>{lang}</Typography>
            <IconButton onClick={() => handleEditLanguage(index)} aria-label="edit">
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleDeleteLanguage(index)} aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
      </Box>
      <TextField
        name="language"
        label="Language"
        value={language}
        onChange={handleLanguageChange}
        error={error !== ''}
        helperText={error}
        onFocus={handleClearError}
      />
      <br />
      <Button variant="contained" onClick={handleAddLanguage} sx={{ mt: 1 }}>
        {isEditing ? <UpdateIcon sx={{ mr: 1 }} /> : <AddBox sx={{ mr: 1 }} />} {isEditing ? 'Update Language' : 'Add Language'}
      </Button>
    </Box>
  );
};

export default PreferredLanguagesForm;

import { AddBox, Delete as DeleteIcon } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import LoginService from 'services/login.service';
import SaveIcon from '@mui/icons-material/Save';
import ApiRequest from 'services/api.service';
import { ValidationPopup } from 'components/generics/ValidationPopup';
import RoomAllocationCard from './RoomAllocationCard';

function StatusTypography({ status }) {
  // Determine the color based on the status
  const getColor = (status) => {
    switch (status) {
      case 'REGISTERED':
        return 'green';
      case 'CANCELLED':
        return 'red';
      case 'NOT_REGISTERED':
        return 'blue';
      default:
        return 'default'; // default color if none of the above
    }
  };

  return (
    <Typography style={{ color: getColor(status) }}>
      {status}
    </Typography>
  );
}

const UserDetailsSummary = ({ userDetails, defaultExpanded }) => {
  const [expanded, setExpanded] = useState(defaultExpanded && true);
  return (<Accordion key='userDetails' sx={{ mb: 2 }} expanded={expanded} onChange={() => setExpanded(!expanded)}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography variant="title"><strong>Primary user information</strong></Typography>
    </AccordionSummary>
    <AccordionDetails>
      {/* User details content */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} sx={{ border: '1px solid #e0e0e0' }}><Typography><strong>Area Coordinator:</strong> {userDetails.areaCoordinator}</Typography></Grid>
        <Grid item xs={12} sm={12} sx={{ border: '1px solid #e0e0e0' }}><Typography><strong>Reference Temple Name:</strong> {userDetails.refTempleName}</Typography></Grid>
        <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }}><Typography><strong>Name:</strong> {userDetails.name}</Typography></Grid>
        <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }}><Typography><strong>Initiated Name:</strong> {userDetails.initiatedName}</Typography></Grid>
        <Grid item xs={12} sm={12} sx={{ border: '1px solid #e0e0e0' }}><Typography><strong>Email:</strong> {userDetails.email}</Typography></Grid>
        <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }}><Typography><strong>Country Code:</strong> {userDetails.countryCode}</Typography></Grid>
        <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }}><Typography><strong>Contact Number:</strong> {userDetails.contactNumber}</Typography></Grid>
        <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }}><Typography><strong>City:</strong> {userDetails.city}</Typography></Grid>
        <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }}><Typography><strong>Country:</strong> {userDetails.country}</Typography></Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>)
};

const MemberSummary = ({ member, memberIndex, handleEditMember, handleDeleteMember, defaultExpanded, isPrint, updateSingleRegistration }) => {

  const [expanded, setExpanded] = useState(defaultExpanded && true);
  const [error, setError] = useState('');
  const [confirmMessage, setConfirmMessage] = useState('')
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupAction, setPopupAction] = useState('error');
  const [popupType, setPopupType] = useState('error');
  const isAdmin = LoginService.isAdmin();

  const [memberStatus, setMemberStatus] = useState(member.status); // Default value for member status
  const handleMemberStatusChange = (event) => {
    setMemberStatus(event.target.value);
  };
  const handleSave = async () => {
    setPopupType('confirm');
    setConfirmMessage('Are you sure you want to update the member status to : ' + memberStatus + '?');
    setIsPopupOpen(true);
    setPopupAction(() => async () => {
      try {
        setConfirmMessage('Started updating member status.... please wait')
        const registrationDTO = await ApiRequest.handlePostApiRequest('/admin/registration/member/status', {}, { memberId: member.id, status: memberStatus });
        if (registrationDTO) {
          await updateSingleRegistration(registrationDTO);
        }
        setConfirmMessage('Done..!!!')
        setIsPopupOpen(false);
        setPopupType('error');
      } catch (error) {
        alert(error.message)
        alert('Could not update member status')
      }
    });
  };
  return (
    <Accordion key={'member' + memberIndex} sx={{ mb: 2 }} expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">
              <strong>{member.name.toUpperCase()}</strong>
            </Typography>
          </Grid>
          <Grid item xs={8} sm={4}>
            <StatusTypography status={member.status} />
          </Grid>
          <Grid item xs={4} sm={2}>
            {handleEditMember && (member.status === 'NOT_REGISTERED' || isAdmin) ? (
              <IconButton onClick={(event) => { event.stopPropagation(); handleEditMember(memberIndex); }} aria-label="edit">
                <EditIcon />
              </IconButton>
            ) : (<></>)}
            {handleDeleteMember && member.status === 'NOT_REGISTERED' ? (
              <IconButton onClick={(event) => { event.stopPropagation(); handleDeleteMember(memberIndex); }} aria-label="delete">
                <DeleteIcon />
              </IconButton>
            ) : (<></>)}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <ValidationPopup
          isOpen={isPopupOpen}
          message={popupType === 'error' ? error : confirmMessage}
          type={popupType}
          onConfirm={popupAction}
          onCancel={() => {
            setIsPopupOpen(false);
            setPopupType('error');
            setError('')
          }}
        />
        <Grid container spacing={2}>
          {isAdmin && updateSingleRegistration && !isPrint &&
            <Grid item xs={12} sm={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
              <Typography variant="subtitle1"><strong>Update member status</strong></Typography>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="member-status-label">Member Status</InputLabel>
                <Select
                  labelId="member-status-label"
                  id="member-status-select"
                  value={memberStatus}
                  onChange={handleMemberStatusChange}
                  label="Member Status"
                  style={{ marginRight: '10px' }}
                >
                  <MenuItem value="NOT_REGISTERED">NOT_REGISTERED</MenuItem>
                  <MenuItem value="REGISTERED">REGISTERED</MenuItem>
                  <MenuItem value="CANCELLED">CANCELLED</MenuItem>
                </Select>
              </FormControl>
              <IconButton onClick={handleSave} aria-label="save">
                <SaveIcon />
              </IconButton>
            </Grid>}
          <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }}><Typography variant="subtitle1"><strong>Name:</strong> {member.name}</Typography></Grid>
          <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Initiated Name:</strong> {member.initiatedName}</Typography></Grid>
          <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Age:</strong> {member.age}</Typography></Grid>
          <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Gender:</strong> {member.gender}</Typography></Grid>

          <Grid item xs={12} sm={4} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Country Code:</strong> {member.countryCode}</Typography></Grid>
          <Grid item xs={12} sm={4} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Contact Number:</strong> {member.contactNumber}</Typography></Grid>
          <Grid item xs={12} sm={4} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Email:</strong> {member.email}</Typography></Grid>

          <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>City:</strong> {member.city}</Typography></Grid>
          <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Preferred Hearing Language:</strong> {member.preferredHearingLanguage}</Typography></Grid>

          <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Arrival Date:</strong> {dayjs(member.arrivalTime).format('DD/MM/YYYY')}</Typography></Grid>
          <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Departure Date:</strong> {dayjs(member.departureTime).format('DD/MM/YYYY')}</Typography></Grid>

          <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Transportation preference:</strong> {member.transportationPreference}</Typography></Grid>
          <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Transportation charges per day:</strong> {member.transportationEstimate}</Typography></Grid>
          <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Prasadam charges per day:</strong> {member.prasadamEstimate}</Typography></Grid>
          <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Registration Charges:</strong> {member.registrationCharges}</Typography></Grid>
          <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Yatra Charges per day:</strong> {member.yatraCharges}</Typography></Grid>
          <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Late Fees:</strong> {member.lateFees}</Typography></Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
};

const MemberDetailsSummary = ({ memberDetails, handleEditMember, handleDeleteMember, defaultExpanded, isPrint, updateSingleRegistration }) => {
  const [expanded, setExpanded] = useState(defaultExpanded && true);
  return (
    <Accordion key='memberDetails' sx={{ mb: 2 }} expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="title"><strong>Member details</strong></Typography>
      </AccordionSummary>
      <AccordionDetails>
        {memberDetails.map((member, memberIndex) => (
          <MemberSummary
            member={member}
            memberIndex={memberIndex}
            handleEditMember={handleEditMember}
            handleDeleteMember={handleDeleteMember}
            defaultExpanded={defaultExpanded}
            isPrint={isPrint}
            updateSingleRegistration={updateSingleRegistration}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  )
};

const RoomSummary = ({ room, roomIndex, handleEditRoom, handleDeleteRoom, defaultExpanded, roomView, destinations }) => {
  const [expanded, setExpanded] = useState(defaultExpanded && true);
  useEffect(() => {
    setExpanded(defaultExpanded || roomView);
  }, [roomView]);
  return (
    <Accordion key={'acc' + roomIndex} sx={{ mb: 2 }} expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary key={'acc_sum' + roomIndex}>
        <Typography variant="subtitle1"><strong>ROOM - {roomIndex + 1}</strong></Typography>
        <div style={{ marginLeft: 'auto' }}>
          {handleEditRoom ? (
            <IconButton onClick={(event) => { event.stopPropagation(); handleEditRoom(roomIndex); }} aria-label="edit">
              <EditIcon />
            </IconButton>
          ) : (<></>)}
          {handleDeleteRoom ? (
            <IconButton onClick={(event) => { event.stopPropagation(); handleDeleteRoom(roomIndex); }} aria-label="delete">
              <DeleteIcon />
            </IconButton>
          ) : (<></>)}
        </div>
      </AccordionSummary>
      <AccordionDetails key={'acc_details_' + roomIndex}>
        <Grid key={roomIndex} container spacing={2}>
          {room
            .sort((a, b) => a.destinationNumber - b.destinationNumber)
            .map((r, i) => (
              <>
                <Grid item xs={12} sm={8}>
                  <Typography variant="body2">
                    <strong>Destination: </strong>
                    {r.location.charAt(0).toUpperCase() + r.location.slice(1)}
                  </Typography>
                  <Typography variant="body2"><strong>Type: </strong> {r.displayText}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2">
                    {dayjs(destinations[i].checkIn).format('MMM DD, YYYY')} to {dayjs(destinations[i].checkOut).format('MMM DD, YYYY')}
                  </Typography>
                </Grid>
              </>
            ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
};

const RoomDetailsSummary = ({ roomDetails, handleEditRoom, handleDeleteRoom, defaultExpanded, roomView, destinations, registrationId }) => {
  const [expanded, setExpanded] = useState(defaultExpanded && true);
  useEffect(() => {
    setExpanded(defaultExpanded || roomView);
  }, [roomView]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleOpenDialog = (destination) => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Accordion key='acc_room' sx={{ mb: 2 }} expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary key={'acc_summary_room'} expandIcon={<ExpandMoreIcon />}>
          <Typography variant="title"><strong>Room Details</strong></Typography>
        </AccordionSummary>
        <AccordionDetails key='acc_details_room'>
          {/* Room details content */}
          {roomDetails.map((room, index) => (
            <RoomSummary
              room={room}
              roomIndex={index}
              handleEditRoom={handleEditRoom}
              handleDeleteRoom={handleDeleteRoom}
              defaultExpanded={defaultExpanded}
              roomView={roomView}
              destinations={destinations}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </>
  )
};



const ShortSummary = ({ userDetails, memberDetails, roomDetails, defaultExpanded, destinations, updateSingleRegistration, roomView, registration }) => (
  <Box className="page-section">
    {!roomView && <>
      <UserDetailsSummary userDetails={userDetails} defaultExpanded={defaultExpanded} />
    </>}
    <MemberDetailsSummary memberDetails={memberDetails} defaultExpanded={defaultExpanded} updateSingleRegistration={updateSingleRegistration} />
    <RoomDetailsSummary roomDetails={roomDetails} defaultExpanded={defaultExpanded} roomView={roomView} destinations={destinations} registrationId={registration?.id} />
    <RoomAllocationCard roomDetails={roomDetails} defaultExpanded={defaultExpanded} roomView={roomView} destinations={destinations} registration={registration} updateSingleRegistration={updateSingleRegistration}/>
  </Box>
)

export { MemberDetailsSummary, MemberSummary, RoomDetailsSummary, RoomSummary, ShortSummary, UserDetailsSummary };
export default ShortSummary;

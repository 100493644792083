import { Box, Button, Card, CardContent, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup, Switch, TextField, Typography } from '@mui/material';
import PaymentButton from 'components/registration/PaymentButton';
import { useEffect, useState } from 'react';
import ApiRequest from 'services/api.service';

function SponsorshipItem({ item, setTabIndex }) {
    const [count, setCount] = useState(1);
    const [partialAmount, setPartialAmount] = useState(null);
    const [pledgeAmount, setPledgeAmount] = useState(null);
    const [amountToPay, setAmountToPay] = useState(item.amount);
    const [selectedOption, setSelectedOption] = useState('fullAmount');
    const [pledgeByAmount, setPledgeByAmount] = useState(true);

    const from = '/sponsorship';

    useEffect(() => {
        setAmountToPay(calculateAmount(selectedOption));
    }, [selectedOption, count, partialAmount]);

    const handleCustomUnitsChange = (event) => {
        setCount(event.target.value);
    };

    const handlePartialAmountChange = (event) => {
        setPartialAmount(event.target.value);
    };

    const handlePledgeAmountChange = (event) => {
        setPledgeAmount(event.target.value);
    };

    const handlePledgeByAmountChange = () => {
        setPledgeByAmount(!pledgeByAmount);
    };

    const postPaymentAction = () => {
        setTabIndex(1);
    };

    const pledge = () => {
        if (pledgeByAmount && (!pledgeAmount || pledgeAmount <= 0)) {
            alert("Please enter a valid pledge amount.")
            return
        }
        if (!pledgeByAmount && (!count || count <= 0)) {
            alert("Please enter a valid count.")
            return
        }
        ApiRequest.handlePostApiRequest('/payment/createPledge', {
            amount: pledgeByAmount ? pledgeAmount : (item.amount / item.counts) * count,
            comments: `Sponsoring ${pledgeByAmount ? pledgeAmount : (item.amount / item.counts) * count} in the service for ${item.title}`,
            paymentMethod: 'Sponsorship',
            sponsorshipType: item.type,
        }).then((response) => {
            if (response) {
                alert('Created the pledge, thank you for being so generous.')
            } else {
                alert('Failed to create the pledge')
            }
            setTabIndex(1);
        });
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const calculateAmount = (option) => {
        switch (option) {
            case 'fullAmount':
                return item.amount;
            case 'customUnits':
                return (item.amount / item.counts) * count;
            case 'singleUnit':
                return item.amount / item.counts;
            case 'partialAmount':
                return partialAmount;
            default:
                return 0;
        }
    };

    return (
        <Card variant="outlined" sx={{ marginBottom: 2 }}>
            <CardContent>
                <Typography variant="h6" component="div">
                    {item.title}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    <strong>Cost:</strong> {item.cost}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    <strong>Total:</strong> {item.total}
                </Typography>
                <Grid container justifyContent="space-between" alignItems="center" marginTop="20px">
                    <Grid item xs={12} sm={12}>
                        <RadioGroup value={selectedOption} onChange={handleOptionChange}>
                            <FormControlLabel value="fullAmount" control={<Radio />} label="Pay full amount" />
                            {item.counts > 1 && (
                                <Box display="flex" alignItems="center">
                                    <FormControlLabel value="customUnits" control={<Radio />} label="Pay for custom number of units" />
                                    <TextField
                                        type="number"
                                        value={count}
                                        onChange={handleCustomUnitsChange}
                                        inputProps={{ min: 1, max: item.counts }}
                                        disabled={selectedOption !== 'customUnits'}
                                        sx={{ width: 80, marginLeft: 2 }}
                                    />
                                </Box>
                            )}
                            <Box display="flex" alignItems="center">
                                <FormControlLabel value="partialAmount" control={<Radio />} label="Pay a partial amount" />
                                <TextField
                                    type="number"
                                    value={partialAmount}
                                    onChange={handlePartialAmountChange}
                                    disabled={selectedOption !== 'partialAmount'}
                                    sx={{ width: 80, marginLeft: 2 }}
                                />
                            </Box>
                            <Box display="flex" flexDirection="column">
                                <FormControlLabel value="pledge" control={<Radio />} label="Commit now and pay later" />
                                {item.counts > 1 ? (
                                    <Box display="flex" alignItems="center" marginTop={1}>
                                        {selectedOption === 'pledge' && (
                                            <>
                                                <TextField
                                                    type="number"
                                                    value={count}
                                                    onChange={handleCustomUnitsChange}
                                                    inputProps={{ min: 1, max: item.counts }}
                                                    disabled={selectedOption !== 'pledge' || pledgeByAmount}
                                                    sx={{ width: 80, marginLeft: 2 }}
                                                    helperText="Enter number of units"
                                                />
                                                <TextField
                                                    type="number"
                                                    value={pledgeAmount}
                                                    onChange={handlePledgeAmountChange}
                                                    disabled={selectedOption !== 'pledge' || !pledgeByAmount}
                                                    sx={{ width: 80, marginLeft: 2 }}
                                                    helperText="Enter pledge amount"
                                                />
                                                <Switch
                                                    checked={pledgeByAmount}
                                                    onChange={handlePledgeByAmountChange}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    sx={{ marginLeft: 2 }}
                                                />
                                                <FormHelperText sx={{ marginLeft: 2 }}>
                                                    {pledgeByAmount ? "Number of units" : "Amount" }
                                                </FormHelperText>
                                            </>
                                        )}
                                    </Box>
                                ) : (
                                    <Box display="flex" alignItems="center" marginTop={1}>
                                        {selectedOption === 'pledge' && (
                                            <TextField
                                                type="number"
                                                value={pledgeAmount}
                                                onChange={handlePledgeAmountChange}
                                                disabled={selectedOption !== 'pledge' || !pledgeByAmount}
                                                sx={{ width: 80, marginLeft: 2 }}
                                                helperText="Enter pledge amount"
                                            />
                                        )}
                                    </Box>
                                )}
                            </Box>
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Box mt={4} />
                        {selectedOption === 'pledge' ? (
                            <Button
                                variant="contained"
                                onClick={pledge}
                                sx={{ marginLeft: 'auto' }}
                            >
                                Pledge the sponsorship
                            </Button>
                        ) : (
                            <PaymentButton
                                from={from}
                                amount={amountToPay}
                                isSponsor={true}
                                handlePostPaymentAction={postPaymentAction}
                                sponsorshipType={item.type}
                                comments={"Sponsoring " + amountToPay + " in the service for " + item.title}
                            />
                        )}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default SponsorshipItem;

import GetAppIcon from '@mui/icons-material/GetApp';
import FinalBill from 'components/summaries/FinalBill';
import FinancialSummary from 'components/summaries/FinancialSummary';
import RegistrationBill from 'components/summaries/RegistrationBill';
import { MemberDetailsSummary, RoomDetailsSummary, UserDetailsSummary } from 'components/summaries/ShortSummary';
import TransactionSummary from 'components/summaries/TransactionSummary';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { useRef, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import RoomAllocationCard from 'components/summaries/RoomAllocationCard';

const PrintRegistrationCard = ({ registration, configData }) => {

    // const registrationBillRef = useRef(null);
    const finalBillRef = useRef(null);
    const transactionSummaryRef = useRef(null);
    // const financialSummaryRef = useRef(null);
    // const userSummaryRef = useRef(null);
    const memberSummaryRef = useRef(null);
    // const roomSummaryRef = useRef(null);
    const roomAllocationRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    // Common style object
    const hiddenDivStyle = {
        position: 'absolute',
        top: '-10000px',
        left: '-10000px',
        width: '601px', // Example width, adjust based on your content and scale
    };

    const printDocument = async () => {
        setIsLoading(true);
        const pdf = new jsPDF({
            orientation: 'p',
            unit: 'mm',
            format: 'a4',
        });

        const pageWidth = pdf.internal.pageSize.getWidth() - 20; // 20mm for margins
        const pageHeight = pdf.internal.pageSize.getHeight() - 30; // 30mm for margins

        // Array of refs for each section
        const sections = [
            // userSummaryRef,
            memberSummaryRef,
            // roomSummaryRef,
            roomAllocationRef,
            // registrationBillRef,
            transactionSummaryRef,
            finalBillRef,
            // financialSummaryRef,
        ];

        for (let i = 0; i < sections.length; i++) {
            const section = sections[i].current;
            if (!section) continue;

            const canvas = await html2canvas(section, {
                scale: 2, // Adjust scale as needed
                useCORS: true, // To handle CORS issues with images
                windowHeight: section.scrollHeight,
                windowWidth: section.scrollWidth,
            });

            const imgData = canvas.toDataURL('image/png');
            const imgWidth = 190; // Adjust based on your margins
            let imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;

            let position = 0;

            // Split the image across multiple pages if needed
            while (heightLeft >= 0) {
                const pageContentHeight = Math.min(pageHeight, heightLeft);
                pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight, undefined, 'FAST', 0, -position);
                heightLeft -= pageHeight;
                position -= pageHeight;

                if (heightLeft > 0) {
                    pdf.addPage();
                }
            }

            if (i < sections.length - 1) {
                pdf.addPage();
            }
        }

        pdf.save('registration' + '_' + registration.id + '.pdf');
        setIsLoading(false);
    };

    const renderPdfForRegistrationCard = () => {
        return (
            <>
                {/* <div ref={userSummaryRef} style={hiddenDivStyle}>
                    <UserDetailsSummary
                        userDetails={registration.userDetails}
                        defaultExpanded={true}
                    />
                </div> */}
                <div ref={memberSummaryRef} style={hiddenDivStyle}>
                    <MemberDetailsSummary
                        memberDetails={registration.members}
                        defaultExpanded={true}
                        isPrint={true}
                    />
                </div>
                {/* <div ref={roomSummaryRef} style={hiddenDivStyle}>
                    <RoomDetailsSummary
                        roomDetails={registration.roomChoices}
                        defaultExpanded={true}
                        destinations={configData.destinations}
                    />
                </div> */}
                <div ref={roomAllocationRef} style={hiddenDivStyle}>
                    <RoomAllocationCard
                        defaultExpanded={true}
                        roomView={false}
                        registration={registration}
                        isPrint={true}
                    />
                </div>
                {/* <div ref={registrationBillRef} style={hiddenDivStyle}>
                    <RegistrationBill
                        registrationDTO={registration}
                        defaultExpanded={true}
                        isFullSummary={true}
                    />
                </div> */}
                <div ref={transactionSummaryRef} style={hiddenDivStyle}>
                    <TransactionSummary
                        registrationDTO={registration}
                        defaultExpanded={true}
                        isPrint={true}
                    />
                </div>
                <div ref={finalBillRef} style={hiddenDivStyle}>
                    <FinalBill
                        registrationDTO={registration}
                        configDestinations={configData.destinations}
                        defaultExpanded={true}
                        isPrint={true}
                    />
                </div>
                {/* <div ref={financialSummaryRef} style={hiddenDivStyle}>
                    <FinancialSummary
                        registrationDTO={registration}
                        defaultExpanded={true}
                    />
                </div> */}
            </>
        )
    }

    return (
        <>
            {renderPdfForRegistrationCard()}
            {isLoading ? (
                <CircularProgress size={20} className='ml-3' />
            ) : (
                <GetAppIcon onClick={(event) => { event.stopPropagation(); printDocument() }} className='ml-3' />
            )}
        </>
    )
}

export default PrintRegistrationCard;
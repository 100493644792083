import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Paper, Typography, createTheme } from '@mui/material';
import { ThemeProvider, makeStyles } from '@mui/styles';
import dayjs from 'dayjs';
import { useState } from 'react';
import MiscChargeTab from './MiscChargeTab';
import LoginService from 'services/login.service';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2196f3',
        },
        secondary: {
            main: '#f50057',
        },
    },
});

const FinalBill = ({ registrationDTO, defaultExpanded, isPrint, updateSingleRegistration }) => {

    const [expanded, setExpanded] = useState(defaultExpanded && true);

    // Calculate GST and Convenience fee for all members
    const members = registrationDTO.members;
    const roomChoices = registrationDTO.roomChoices;
    const roomsAllocated = registrationDTO.roomsAllocated;

    const numDays = (member) => {
        return dayjs(member.departureTime).diff(dayjs(member.arrivalTime), 'day') + 1;
    }

    const numNights = (room) => {
        return dayjs(room.checkOut).diff(dayjs(room.checkIn), 'day');
    }

    const memberTotal = (member) => {
        return (member.prasadamEstimate * numDays(member))
            + (member.transportationEstimate * numDays(member))
            + (member.yatraCharges * numDays(member))
            + member.lateFees
            + (member.cancellationCharge || 0)
            + (member.prasadamEstimateSurcharge * numDays(member))
            + (member.yatraChargesSurcharge * numDays(member))
            + (member.transportationEstimateSurcharge * numDays(member))
    }

    const allMembersBill = members.reduce((total, member) => {
        return total + memberTotal(member);
    }, 0);

    const roomPrice = (room) => {
        return room.pricePerDay * numNights(room);
    }

    const allRoomsBill = roomsAllocated.reduce((total, room) => {
        return total + roomPrice(room.room.roomInventory)
    }, 0);

    return (
        <>
            <Accordion key='acc_final_financial_summary' sx={{ mb: 2 }} expanded={expanded} onChange={() => setExpanded(!expanded)}>
                <AccordionSummary key={'acc_final_financial_summary'} expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="title"><strong>Final bill</strong></Typography>
                </AccordionSummary>
                <AccordionDetails key='acc__details_final_financial_summary'>
                    <ThemeProvider theme={theme}>
                        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                            {members.map((member, index) => (
                                <Box key={index} mb={1}>
                                    <Typography variant="body1"><strong>{member.name.toUpperCase()}</strong></Typography>
                                    <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>Prasadam estimate:({numDays(member)} days)</span>
                                        <span>{(member.prasadamEstimate+member.prasadamEstimateSurcharge) * numDays(member)}</span>
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>Transportation estimate:({numDays(member)} days)</span>
                                        <span>{(member.transportationEstimate+member.transportationEstimateSurcharge) * numDays(member)}</span>
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>Yatra charge:({numDays(member)} days)</span>
                                        <span>{(member.yatraCharges+member.yatraChargesSurcharge) * numDays(member)}</span>
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>Late Fees:</span>
                                        <span>{member.lateFees}</span>
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>Cancellation charge:</span>
                                        <span>{(member.cancellationCharge || 0)}</span>
                                    </Typography>
                                    <Divider sx={{ mt: 0.5 }} />
                                    {/* <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>Prasadam waiver:({numDays(member)} days)</span>
                                        <span>{(member.prasadamEstimateSurcharge * numDays(member))}</span>
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>Transportation surcharge:({numDays(member)} days)</span>
                                        <span>{(member.transportationEstimateSurcharge * numDays(member))}</span>
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>Yatra charge waiver:({numDays(member)} days)</span>
                                        <span>{(member.yatraChargesSurcharge * numDays(member))}</span>
                                    </Typography>
                                    <Divider sx={{ mt: 0.5 }} /> */}
                                    <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>Total:</span>
                                        <span>{memberTotal(member)}</span>
                                    </Typography>
                                    <Divider sx={{ mt: 2 }} />
                                </Box>
                            ))}
                            <Typography variant="h6" mt={1} sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                <span>Grand Total (A):</span>
                                <span>{allMembersBill}</span>
                            </Typography>
                            <Divider sx={{ mt: 2 }} />
                        </Paper>
                        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                            <Typography variant="body1"><strong>Room bill</strong></Typography>
                            {roomsAllocated.map((room, roomIndex) => (
                                <>
                                    <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        {room.room.roomInventory.location.charAt(0).toUpperCase() + room.room.roomInventory.location.slice(1)}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        {room.room.roomInventory.displayText}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        {dayjs(room.room.roomInventory.checkIn).format('MMM DD, YYYY')} to {dayjs(room.room.roomInventory.checkOut).format('MMM DD, YYYY')}
                                    </Typography>
                                    <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>Price :({numNights(room.room.roomInventory)} night)</span>
                                        <span>
                                            {roomPrice(room.room.roomInventory)}
                                        </span>
                                    </Typography>
                                    <Divider sx={{ mt: 1 }} />
                                </>
                            ))}
                            <Typography variant="h6" mt={1} sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                <span>Grand Total (B):</span>
                                <span>{allRoomsBill}</span>
                            </Typography>
                        </Paper>
                        {(LoginService.isAdmin() || (registrationDTO.miscCharges && registrationDTO.miscCharges.length > 0)) &&
                            <MiscChargeTab registration={registrationDTO} updateSingleRegistration={updateSingleRegistration} isPrint={isPrint} />
                        }
                        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                                <Typography variant="body1" mb={2}><strong>Final payable</strong></Typography>
                            <Box key="box_summary" mb={1}>
                                <Typography variant="body1" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Final bill(A+B):</span>
                                    <span>{registrationDTO.finalBill === 0 ? 'Not yet available' : (registrationDTO.finalBill)}</span>
                                </Typography>
                                {registrationDTO.miscCharge && registrationDTO.miscCharge != 0 &&
                                    <Typography variant="body1" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>Total Misc Charge (C): </span>
                                        <span>{registrationDTO.miscCharge}</span>
                                    </Typography>
                                }
                                <Divider sx={{ mt: 2 }} />
                                <Typography variant="body1" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Total credits (O+P): </span>
                                    <span>{-registrationDTO.onlineAmount - registrationDTO.miscPaymentAmount}</span>
                                </Typography>
                                <Divider sx={{ mt: 2 }} />
                                <Typography variant="body1" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span><strong>Final due: </strong></span>
                                    <span><strong>{registrationDTO.finalBill === 0 ? 'Not yet available' : registrationDTO.finalBill + registrationDTO.miscCharge - registrationDTO.onlineAmount - registrationDTO.miscPaymentAmount}</strong></span>
                                </Typography>
                                <Divider sx={{ mt: 2 }} />
                            </Box>
                        </Paper>
                    </ThemeProvider>
                </AccordionDetails>
            </Accordion >


        </>
    );
};

export default FinalBill;

import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Box, IconButton, Card, CardContent } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import { AddBox } from '@mui/icons-material';

const YatraRoomForm = ({ onRoomChoicesChange }) => {
  const [roomChoice, setRoomChoice] = useState({
    id: null,
    location: '',
    type: '',
    displayText: '',
    info: '',
    checkIn: '',
    checkOut: '',
    minPrice: 0,
    maxPrice: 0,
  });

  const [isEditing, setIsEditing] = useState(false);
  const [roomChoices, setRoomChoices] = useState([]);
  const [clickedDates, setClickedDates] = useState([]);
  const [error, setError] = useState('');

  const handleRoomChoiceChange = (e) => {
    const { name, value } = e.target;
    setRoomChoice({
      ...roomChoice,
      [name]: value
    });
  };

  const handleAddRoomChoice = () => {
    if (validateForm()) {
      setRoomChoices([...roomChoices, roomChoice]);
      resetRoomChoice();
      setError('');
    } else {
      setError('All fields are required.');
    }
  };

  const handleEditRoomChoice = (index) => {
    const editedRoomChoice = roomChoices[index];
    setRoomChoice({
      ...editedRoomChoice,
      id: index
    });
    setIsEditing(true);
  };

  const handleUpdateRoomChoice = () => {
    if (validateForm()) {
      if (isEditing) {
        const updatedRoomChoices = roomChoices.map((choice, index) =>
          index === roomChoice.id ? roomChoice : choice
        );
        setRoomChoices(updatedRoomChoices);
      } else {
        handleAddRoomChoice();
      }
      resetRoomChoice();
      setIsEditing(false);
      setError('');
    } else {
      setError('All fields are required.');
    }
  };

  const handleDeleteRoomChoice = (index) => {
    const updatedRoomChoices = roomChoices.filter((choice, i) => i !== index);
    setRoomChoices(updatedRoomChoices);
  };

  const resetRoomChoice = () => {
    setRoomChoice({
      id: null,
      location: '',
      type: '',
      displayText: '',
      info: '',
      checkIn: '',
      checkOut: '',
      minPrice: 0,
      maxPrice: 0,
    });
  };

  const handleDateClick = (dateField) => {
    setClickedDates([...clickedDates, dateField]);
  };

  const validateForm = () => {
    return (
      roomChoice.type.trim() !== '' &&
      roomChoice.location.trim() !== '' &&
      roomChoice.minPrice !== '' &&
      roomChoice.maxPrice !== '' &&
      roomChoice.checkIn !== '' &&
      roomChoice.checkOut !== '' &&
      roomChoice.displayText.trim() !== '' &&
      roomChoice.info.trim() !== ''
    );
  };

  useEffect(() => {
    onRoomChoicesChange(roomChoices);
  }, [roomChoices]);

  return (
    <Box>
      <Typography variant="h6">Room Choices</Typography>
      {error && <Typography variant="body2" color="error">{error}</Typography>}
      {/* Display in tabular form on laptop */}
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Location</th>
              <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Type</th>
              <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Display Text</th>
              <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Info</th>
              <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Check-In</th>
              <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Check-Out</th>
              <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Price Range</th>
              <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {roomChoices.map((choice, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>{choice.location}</td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>{choice.type}</td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>{choice.displayText}</td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>{choice.info}</td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                  {clickedDates.includes(`checkIn-${index}`) ? null : (
                    <Typography onClick={() => handleDateClick(`checkIn-${index}`)}>{choice.checkIn}</Typography>
                  )}
                </td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                  {clickedDates.includes(`checkOut-${index}`) ? null : (
                    <Typography onClick={() => handleDateClick(`checkOut-${index}`)}>{choice.checkOut}</Typography>
                  )}
                </td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>{`${choice.minPrice} - ${choice.maxPrice}`}</td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                  <IconButton onClick={() => handleEditRoomChoice(index)} aria-label="edit">
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteRoomChoice(index)} aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
      {/* Display in card form on mobile */}
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        {roomChoices.map((choice, index) => (
          <Card key={index} sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="subtitle1">{choice.location}</Typography>
              <Typography variant="body2">{choice.type}</Typography>
              <Typography variant="body2">{choice.displayText}</Typography>
              <Typography variant="body2">{choice.info}</Typography>
              <Typography variant="body2">
                {clickedDates.includes(`checkIn-${index}`) ? null : (
                  <Typography onClick={() => handleDateClick(`checkIn-${index}`)}>{choice.checkIn}</Typography>
                )}
              </Typography>
              <Typography variant="body2">
                {clickedDates.includes(`checkOut-${index}`) ? null : (
                  <Typography onClick={() => handleDateClick(`checkOut-${index}`)}>{choice.checkOut}</Typography>
                )}
              </Typography>
              <Typography variant="body2">{`${choice.minPrice} - ${choice.maxPrice}`}</Typography>
              <IconButton onClick={() => handleEditRoomChoice(index)} aria-label="edit">
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleDeleteRoomChoice(index)} aria-label="delete">
                <DeleteIcon />
              </IconButton>
            </CardContent>
          </Card>
        ))}
      </Box>

      <TextField name="location" label="Location" value={roomChoice.location} onChange={handleRoomChoiceChange} />
      <TextField name="type" label="Type" value={roomChoice.type} onChange={handleRoomChoiceChange} />
      <TextField name="displayText" label="Display Text" value={roomChoice.displayText} onChange={handleRoomChoiceChange} />
      <TextField name="info" label="Info" value={roomChoice.info} onChange={handleRoomChoiceChange} />
      <TextField name="checkIn" label="Check-In" type="date" value={roomChoice.checkIn} onChange={handleRoomChoiceChange} InputLabelProps={{ shrink: true }} />
      <TextField name="checkOut" label="Check-Out" type="date" value={roomChoice.checkOut} onChange={handleRoomChoiceChange} InputLabelProps={{ shrink: true }} />
      <TextField name="minPrice" label="Min Price" type="number" value={roomChoice.minPrice} onChange={handleRoomChoiceChange} />
      <TextField name="maxPrice" label="Max Price" type="number" value={roomChoice.maxPrice} onChange={handleRoomChoiceChange} />
      <br />
      <Button variant="contained" onClick={handleUpdateRoomChoice} sx={{ mt: 1 }}>
        {isEditing ? <UpdateIcon sx={{ mr: 1 }} /> : <AddBox sx={{ mr: 1 }} />} {isEditing ? 'Save Edited Room Choice' : 'Add Room Choice'}
      </Button>
    </Box>
  );
};

export default YatraRoomForm;

import React from 'react';

import styles from './RefundPolicy.module.scss';

function RefundPolicy() {

  return (
    <div className={styles.refundPolicyMain}>
      <h3 className={styles.heading}>Refund and Credits</h3>

      <div className={styles.parentSection}>
        <h5 className={styles.sectionHeading}>Refunds</h5>
        <p>
          Refunds will be processed and paid as promptly as possible as per the conditions of your package. If we need to recover funds already paid to our partners for your services, your refund will be issued after those funds have been recovered. Most refunds are processed within six weeks of being requested. Refunds for payments by credit card will be credited to that card within 4 to 6 working days, provided your refund does not exceed the amount paid on the card. Otherwise, your refund will be issued by check.
        </p>
      </div>

      <div className={styles.parentSection}>
        <h5 className={styles.sectionHeading}>Travel Credits</h5>
        <p>
          In many cases, we may issue you credits for trip cancellations, referring new travelers to DHAM YATRA LLP, promotions, or other reasons. In some cases, these credits have a stated expiration date, while other credits may have no expiration. To redeem credits, they must be used towards a booking that is made before the credits expire, but the travel need not be complete before the expiration. Expired credits are void and may not be redeemed. In most cases, credits used toward a booking which you later cancel are forfeited, unless the cancelled booking is covered by our Carefree Booking policy or otherwise stated.
        </p>
      </div>

    </div>
  );

}

export default RefundPolicy;
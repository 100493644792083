import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';

export const  footerNavigationLinksControls = [
  {
    label: 'Contact Us',
    link: '/contact-us'
  },
  {
    label: 'Terms and Conditions',
    link: '/terms-and-conditions'
  },
  {
    label: 'Privacy Policy',
    link: '/privacy-policy'
  },
  {
    label: 'Pricing Policy',
    link: '/pricing-policy'
  },
  {
    label: 'Refund Policy',
    link: '/refund-policy'
  },
  {
    label: 'Legal Responsibility',
    link: '/legal-responsibility'
  },
  {
    label: 'Yatra Quotation',
    link: '/yatra-quatation'
  },
  {
    label: 'Yatra Pricing',
    link: '/yatra-pricing'
  },
  {
    label: 'FAQs',
    link: '/faqs'
  },
];

export const socialMediaLinks = [
  {
    icon: YouTubeIcon,
    href: 'https://www.youtube.com/c/RomapadaswamiChannel'
  },
  {
    icon: FacebookIcon,
    href: 'https://www.facebook.com/HHRomapadaSwami/'
  },
  {
    icon: InstagramIcon,
    href: 'https://www.instagram.com/romapadaswami/?hl=en'
  },
  {
    icon: XIcon,
    href: 'https://twitter.com/Romapada_Swami'
  }
];
function isObject(obj) {
    return obj !== null && typeof obj === 'object';
}

function isDate(obj) {
    return obj instanceof Date;
}

export function isEqual(obj1, obj2) {
    // If both are the same reference or identical primitives, return true
    if (obj1 === obj2) {
        return true;
    }

    // Check if one is a string and the other is a date with the same value
    if ((typeof obj1 === 'string' && isDate(obj2)) || (isDate(obj1) && typeof obj2 === 'string')) {
        return String(obj1) === String(obj2);
    }

    if (isDate(obj1) && isDate(obj2)) {
        return obj1.getTime() === obj2.getTime();
    }

    // If either is not an object (including arrays), they are not equal
    if (!isObject(obj1) || !isObject(obj2)) {
        return false;
    }

    // If both are Array instances, compare as arrays
    if (Array.isArray(obj1) && Array.isArray(obj2)) {
        if (obj1.length !== obj2.length) {
            return false;
        }
        for (let i = 0; i < obj1.length; i++) {
            if (!isEqual(obj1[i], obj2[i])) {
                return false;
            }
        }
        return true; // Arrays are equal
    } else if (Array.isArray(obj1) || Array.isArray(obj2)) {
        return false; // One is an array, the other is not
    }

    // If both are objects (not arrays), compare as objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (!keys2.includes(key) || !isEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
}

import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import SponsorshipPage from './SponsorshipPage';
import SponsorshipHistory from './SponsorshipHistory';
import SponsorshipStats from './SponsorshipStats';
import LoginService from 'services/login.service';


function Sponsorship() {
    const [tabIndex, setTabIndex] = useState(0);
    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    const tabsContainerStyle = {
        display: 'flex',
        justifyContent: 'center', // Center the tabs horizontally
    };

    return (
        <>
            <Box maxWidth='md' sx={tabsContainerStyle}> {/* Added container for tabs */}
                <Tabs value={tabIndex} onChange={handleTabChange}>
                    <Tab label="Sponsorship" />
                    <Tab label="Transactions" />
                    {LoginService.isAdmin() && ( <Tab label="Stats" /> )}
                </Tabs>
            </Box>
            <TabPanel value={tabIndex} index={0}>
                <SponsorshipPage setTabIndex={setTabIndex}/>
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
                <SponsorshipHistory />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
                <SponsorshipStats />
            </TabPanel>
        </>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </Box>
    );
}

export default Sponsorship;

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Divider, Drawer, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { ClearIcon } from '@mui/x-date-pickers';
import { ValidationPopup } from 'components/generics/ValidationPopup';
import RegistrationCard from 'components/registration/RegistrationCard';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ApiRequest from 'services/api.service';
import CloseIcon from '@mui/icons-material/Close';

const AllApplication = () => {

    const useStyles = makeStyles((theme) => ({

    }));

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedRegistration, setSelectedRegistration] = useState(null);
    const [selectedRegistrationData, setSelectedRegistrationData] = useState(null);

    // Page level states
    const location = useLocation();
    const from = location.pathname
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [filterAnchorEl, setFilterAnchorEl] = React.useState(null);
    const [allRegistration, setAllRegistration] = useState([]);
    const [filteredRegistration, setFilteredRegistration] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isConfigLoading, setIsConfigLoading] = useState(true);
    const [uniqueValues, setUniqueValues] = useState([]);
    const [configData, setConfigData] = useState({});

    const classes = useStyles();

    // Popup state
    const [error, setError] = useState('');
    const [confirmMessage, setConfirmMessage] = useState('')
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupAction, setPopupAction] = useState('error');
    const [popupType, setPopupType] = useState('error');

    // Search and page parameter
    const defaultSortConfig = { key: 'id', direction: 'desc', name: 'Id' }
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('rowsPerPage') || 5);
    const [filterText, setFilterText] = useState(localStorage.getItem('filterText') || '');
    const [sortConfig, setSortConfig] = useState(JSON.parse(localStorage.getItem('sortConfig')) || defaultSortConfig);
    const [exactMatch, setExactMatch] = useState(localStorage.getItem('exactMatch') || false);
    const [startWithMatch, setStartWithMatch] = useState(localStorage.getItem('startWithMatch') || false);
    const [caseSensitive, setCaseSensitive] = useState(localStorage.getItem('caseSensitive') || false);

    const [registrationView, setRegistrationView] = useState(localStorage.getItem('registrationView') || "REGISTRATION");
    const handleRegistrationViewChange = (event) => {
        localStorage.setItem('registrationView', event.target.value);
        if('ROOM_ALLOCATION' === event.target.value) {
            setRegistrationStatus('REGISTERED');
        } else {
            setRegistrationStatus(localStorage.getItem('registrationStatus') || "ALL")
        }
        setRegistrationView(event.target.value);
    };

    // Filter parameter
    const [registrationStatus, setRegistrationStatus] = useState(localStorage.getItem('registrationStatus') || "ALL");
    const handleRegistrationStatusChange = (event) => {
        localStorage.setItem('registrationStatus', event.target.value);
        setRegistrationStatus(event.target.value);
    };
    const [paymentStatus, setPaymentStatus] = useState(localStorage.getItem('paymentStatus') || "ALL");
    const handlePaymentStatusChange = (event) => {
        localStorage.setItem('paymentStatus', event.target.value);
        setPaymentStatus(event.target.value);
    };
    const [roomAllocationStatus, setRoomAllocationStatus] = useState(localStorage.getItem('roomAllocationStatus') || "ALL");
    const handleRoomAllocationStatusChange = (event) => {
        localStorage.setItem('roomAllocationStatus', event.target.value);
        setRoomAllocationStatus(event.target.value);
    };
    const [areaCoordinator, setAreaCoordinator] = useState(localStorage.getItem('areaCoordinator') || "ALL");
    const areaCoordinatorOptions = configData.areaCoordinator || [];
    const handleAreaCoordinatorChange = (event) => {
        localStorage.setItem('areaCoordinator', event.target.value);
        setAreaCoordinator(event.target.value);
    };

    useEffect(() => {
        if('ROOM_ALLOCATION' === registrationView) {
            setRegistrationStatus('REGISTERED');
        }
        ApiRequest.handleGetApiRequest('/config').then((response) => {
            setConfigData(response);
            setIsConfigLoading(false);
        });
    }, []);

    const resetSettings = () => {
        // Reset to your desired default values
        setRowsPerPage(5);
        setFilterText('');
        setSortConfig(defaultSortConfig);
        setExactMatch(false);
        setStartWithMatch(false);
        setCaseSensitive(false);
        setRegistrationView('REGISTRATION')
        setRegistrationStatus('ALL');
        setPaymentStatus('ALL');
        setRoomAllocationStatus('ALL');
        setAreaCoordinator('ALL');

        // Clear from localStorage
        localStorage.removeItem('rowsPerPage');
        localStorage.removeItem('filterText');
        localStorage.removeItem('sortConfig');
        localStorage.removeItem('exactMatch');
        localStorage.removeItem('startWithMatch');
        localStorage.removeItem('caseSensitive');
        localStorage.removeItem('registrationView');
        localStorage.removeItem('registrationStatus');
        localStorage.removeItem('paymentStatus');
        localStorage.removeItem('roomAllocationStatus')
        localStorage.removeItem('areaCoordinator');
    };

    const handleReloadData = async () => {
        setIsLoading(true);
        await ApiRequest.handleGetApiRequest('/admin/all/registration/lite',
            {
                registrationStatus: registrationStatus === "ALL" ? null : registrationStatus,
                paymentStatus: paymentStatus === "ALL" ? null : paymentStatus,
                areaCoordinator: areaCoordinator === "ALL" ? null : areaCoordinator,
                roomAllocationStatus: roomAllocationStatus === "ALL" ? null : roomAllocationStatus
            })
            .then((response) => {
                setAllRegistration(response);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const updateSingleRegistration = (updatedRegistration) => {
        setAllRegistration(prevList =>
            prevList.map(obj =>
                obj.id === updatedRegistration.id ? updatedRegistration : obj
            )
        );
        setFilteredRegistration(prevList =>
            prevList.map(obj =>
                obj.id === updatedRegistration.id ? updatedRegistration : obj
            )
        );
    };

    useEffect(() => {
        handleReloadData()
    }, [registrationStatus, paymentStatus, roomAllocationStatus, areaCoordinator]);

    useEffect(() => {
        // Apply sorting to the original data
        if (!isLoading && !isConfigLoading) {
            const sortedData = handleSorting(sortConfig.key, sortConfig.direction);
            filterData(filterText, sortedData);
        }
    }, [isLoading, sortConfig, filterText, startWithMatch, caseSensitive, exactMatch]);

    useEffect(() => {
        if(isLoading || !allRegistration)
            return;
        const uniqueValues = new Set(); // Initialize a set to store unique values

        allRegistration.forEach(row => {
            uniqueValues.add(row.id);

            if (typeof row.registrationStatus === 'string') {
                uniqueValues.add(row.registrationStatus); // Add registration status to the set
            }

            if (typeof row.paymentStatus === 'string') {
                uniqueValues.add(row.paymentStatus); // Add payment status to the set
            }

            Object.values(row.userDetails).forEach(val => {
                if (typeof val === 'string') {
                    uniqueValues.add(val); // Add string values to the set
                }
            });

        });

        setUniqueValues(Array.from(uniqueValues))
    }, [isLoading])

    function matchString(input, target) {

        // Convert non-string inputs to strings to ensure consistent behavior
        const inputString = String(input);
        const targetString = String(target);

        // Apply case sensitivity
        const finalInput = caseSensitive ? inputString : inputString.toLowerCase();
        const finalTarget = caseSensitive ? targetString : targetString.toLowerCase();

        // Apply matching strategy
        if (exactMatch) {
            return finalInput === finalTarget;
        } else if (startWithMatch) {
            return finalTarget.startsWith(finalInput);
        } else {
            return finalTarget.includes(finalInput);
        }
    }

    const filterData = (value = filterText, sortedRegistration) => {
        if (!value) {
            setFilteredRegistration(sortedRegistration);
            setPage(0);
            return;
        }

        const filteredData = sortedRegistration.filter((row) => {
            const idMatch = matchString(value, row.id);
            
            // Check if any user details match the filter value
            const userDetailsMatch = Object.values(row.userDetails).some(val => {
                // Perform case-sensitive exact match if selected, otherwise perform case-insensitive match
                return typeof val === 'string' && matchString(value, val);//(exactMatch ? val === value : val.toLowerCase().includes(value.toLowerCase()))
            });

            const registrationMatch = matchString(value, row.registrationStatus) || matchString(value, row.paymentStatus);

            // return idMatch || memberMatch || userDetailsMatch || registrationMatch || roomMatch || paymentHistoryMatch;
            return idMatch || userDetailsMatch || registrationMatch;
        });

        setFilteredRegistration(filteredData);
        setPage(0);
    }

    // Sort data based on column header click
    const handleSorting = (key = sortConfig.key, direction = sortConfig.direction) => {

        const sortedData = [...allRegistration].sort((a, b) => {
            // Split the key by dot to handle nested keys
            const keys = key.split('.');
            let aValue = a;
            let bValue = b;

            // Traverse the object to get the value of the nested key
            for (let nestedKey of keys) {
                aValue = aValue[nestedKey];
                bValue = bValue[nestedKey];
            }

            if (aValue < bValue) return direction === 'asc' ? -1 : 1;
            if (aValue > bValue) return direction === 'asc' ? 1 : -1;
            return 0;
        });
        return sortedData;
    };

    if (isLoading || isConfigLoading) {
        return <div>Loading...</div>; // Render loading indicator while data is being fetched
    }

    // Sort data based on column header click
    const handleSort = (key, name) => {
        if (!key || key === '') {
            setSortConfig({ key, direction: 'asc', name });
            return;
        }
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction, name });
        localStorage.setItem('sortConfig', JSON.stringify({ key, direction, name }));
    }

    // Pagination handlers
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        localStorage.setItem('rowsPerPage', event.target.value);
        setPage(0);
    };

    // Example all registration slicing for current page
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredRegistration.length - page * rowsPerPage);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleFilterClick = (event) => {
        setFilterAnchorEl(event.currentTarget);
    };

    const handleFilterClose = () => {
        setFilterAnchorEl(null);
    };

    const handleRowClick = async (row) => {
        const registrationData =  await ApiRequest.handleGetApiRequest('/registration/', {registrationId: row.id})
        setSelectedRegistration(row); // Set the clicked row's data
        setSelectedRegistrationData(registrationData); // Set the clicked row's data
        setDrawerOpen(true); // Open the drawer
    };

    const handleCloseDrawer = () => {
        setDrawerOpen(false);
        setSelectedRegistration(null); // Clear the selected registration when closing
        setSelectedRegistrationData(null); // Clear the selected registration when closing
    };

    return (
        <div>
            <ValidationPopup
                isOpen={isPopupOpen}
                message={popupType === 'error' ? error : confirmMessage}
                type={popupType}
                onConfirm={popupAction}
                onCancel={() => {
                    setIsPopupOpen(false);
                    setPopupType('error');
                    setError('')
                }}
            />
            {/* Title */}
            <div className="rps-flex section-header" style={{ paddingTop: '30px' }}>
                <h4 className="flex-one">
                    <strong>
                        All registrations
                    </strong>
                </h4>
                <FormControl variant="outlined" >
                    <InputLabel id="view-label">View</InputLabel>
                    <Select
                        labelId="view-label"
                        id="registration-view"
                        value={registrationView}
                        onChange={handleRegistrationViewChange}
                        label="Registration View"
                    >
                        <MenuItem value="REGISTRATION">REGISTRATION</MenuItem>
                        <MenuItem value="ROOM_ALLOCATION">ROOM_ALLOCATION</MenuItem>
                    </Select>
                </FormControl>
            </div>

            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={12} sm={12} sx={{ mt: 2, ml: 3, mr: 2 }}>
                    <Autocomplete
                        options={uniqueValues}
                        fullWidth
                        value={filterText}
                        getOptionLabel={(option) => String(option)}
                        onChange={(event, value) => {
                            event.stopPropagation();
                            setFilterText(value);
                            const localStorageValue = !value ? '' : value;
                            localStorage.setItem('filterText', localStorageValue);
                        }}
                        clearOnBlur={true}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Search..."
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <IconButton size="small">
                                            <SearchIcon />
                                        </IconButton>
                                    )
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid container justifyContent="left" item xs={8} md={8}>
                    <Tooltip title="Reset sorting, filter and other settings!">
                        <IconButton onClick={resetSettings}>
                            <ClearIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Reload data!">
                        <IconButton onClick={handleReloadData}>
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Filter!">
                        <IconButton onClick={handleFilterClick}>
                            <FilterAltIcon />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        id="filter-menu"
                        anchorEl={filterAnchorEl}
                        keepMounted
                        open={Boolean(filterAnchorEl)}
                        onClose={handleFilterClose}
                        sx={{ minWidth: '200px' }}
                    >
                        <MenuItem sx={{ minWidth: '200px' }}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="areaCoordinator">Area Coordinator *</InputLabel>
                                <Select
                                    id="areaCoordinator"
                                    name="areaCoordinator"
                                    label="Area Coordinator"
                                    value={areaCoordinator}
                                    onChange={handleAreaCoordinatorChange}
                                >
                                    <MenuItem value="ALL">ALL</MenuItem>
                                    {areaCoordinatorOptions.map((coordinator) => (
                                        <MenuItem key={coordinator} value={coordinator}>
                                            {coordinator}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </MenuItem>
                        <MenuItem sx={{ minWidth: '200px' }}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="registration-status-label">Registration Status</InputLabel>
                                <Select
                                    labelId="registration-status-label"
                                    id="registration-status-select"
                                    value={registrationStatus}
                                    onChange={handleRegistrationStatusChange}
                                    label="Registration Status"
                                >
                                    <MenuItem value="ALL">ALL</MenuItem>
                                    <MenuItem value="REGISTERED">REGISTERED</MenuItem>
                                    <MenuItem value="NOT_REGISTERED">NOT_REGISTERED</MenuItem>
                                </Select>
                            </FormControl>
                        </MenuItem>
                        <MenuItem sx={{ minWidth: '200px' }}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="payment-status-label">Payment Status</InputLabel>
                                <Select
                                    labelId="payment-status-label"
                                    id="payment-status-select"
                                    value={paymentStatus}
                                    onChange={handlePaymentStatusChange}
                                    label="Payment Status"
                                >
                                    <MenuItem value="ALL">ALL</MenuItem>
                                    <MenuItem value="NOT_PAID">NOT_PAID</MenuItem>
                                    <MenuItem value="REGISTRATION_PARTIALLY_PAID">REGISTRATION_PARTIALLY_PAID</MenuItem>
                                    <MenuItem value="REGISTRATION_PAID">REGISTRATION_PAID</MenuItem>
                                    <MenuItem value="PARTIALLY_PAID">PARTIALLY_PAID</MenuItem>
                                    <MenuItem value="FULL_PAID">FULL_PAID</MenuItem>
                                </Select>
                            </FormControl>
                        </MenuItem>
                        <MenuItem sx={{ minWidth: '200px' }}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="room-allocation-status-label">Room Allocation</InputLabel>
                                <Select
                                    labelId="room-allocation-status-label"
                                    id="room-allocation-status-select"
                                    value={roomAllocationStatus}
                                    onChange={handleRoomAllocationStatusChange}
                                    label="Room Allocation"
                                >
                                    <MenuItem value="ALL">ALL</MenuItem>
                                    <MenuItem value="ALLOCATED">ALLOCATED</MenuItem>
                                    <MenuItem value="PARTIALLY_ALLOCATED">PARTIALLY_ALLOCATED</MenuItem>
                                    <MenuItem value="NOT_ALLOCATED">NOT_ALLOCATED</MenuItem>
                                </Select>
                            </FormControl>
                        </MenuItem>
                    </Menu>
                    <Tooltip title="Sort!">
                        <IconButton onClick={handleClick}>
                            {sortConfig.direction === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                            <Typography variant="caption" component="span">{sortConfig.name}</Typography>
                        </IconButton>
                    </Tooltip>
                    <Menu
                        id="sort-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => { handleSort('id', 'Id'); handleClose(); }}>Sort by ID</MenuItem>
                        <MenuItem onClick={() => { handleSort('userDetails.areaCoordinator', 'Area coordinator'); handleClose(); }}>Sort by Area coordinator</MenuItem>
                        <MenuItem onClick={() => { handleSort('userDetails.name', 'Name'); handleClose(); }}>Sort by Name</MenuItem>
                        <MenuItem onClick={() => { handleSort('userDetails.initiatedName', 'Initiated Name'); handleClose(); }}>Sort by Initiated Name</MenuItem>
                        <MenuItem onClick={() => { handleSort('userDetails.email', 'Email'); handleClose(); }}>Sort by Email</MenuItem>
                        <MenuItem onClick={() => { handleSort('userDetails.countryCode', 'Country code'); handleClose(); }}>Sort by Country Code</MenuItem>
                        <MenuItem onClick={() => { handleSort('userDetails.contactNumber', 'Number'); handleClose(); }}>Sort by Contact Number</MenuItem>
                        <MenuItem onClick={() => { handleSort('registrationStatus', 'Registration status'); handleClose(); }}>Sort by Registration Status</MenuItem>
                        <MenuItem onClick={() => { handleSort('paymentStatus', 'Payment status'); handleClose(); }}>Sort by Payment Status</MenuItem>
                        <MenuItem onClick={() => { handleSort('numMembers', 'No of member'); handleClose(); }}>Sort by Number of member</MenuItem>
                        <MenuItem onClick={() => { handleSort('numRooms', 'No of room'); handleClose(); }}>Sort by Number of room</MenuItem>
                    </Menu>
                </Grid>
                <Grid container justifyContent="left" item xs={4} md={4}>
                    <Tooltip title="Exact match!">
                        <IconButton onClick={() => {
                            localStorage.setItem('exactMatch', !exactMatch);
                            setExactMatch(!exactMatch);
                        }}>
                            <Typography
                                variant="body2"
                                style={{
                                    cursor: 'pointer',
                                    color: exactMatch ? 'blue' : 'inherit',
                                }}
                            >
                                [ab]
                            </Typography>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Case sensitive match!">
                        <IconButton onClick={() => {
                            localStorage.setItem('caseSensitive', !caseSensitive);
                            setCaseSensitive(!caseSensitive)
                        }}>
                            {/* <IconButton onClick={() => setCaseSensitive(!caseSensitive)}> */}
                            <Typography
                                variant="body2"
                                style={{
                                    cursor: 'pointer',
                                    color: caseSensitive ? 'blue' : 'inherit',
                                }}
                            >
                                Aa
                            </Typography>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Start with match!">
                        <IconButton onClick={() => {
                            localStorage.setItem('startWithMatch', !startWithMatch);
                            setStartWithMatch(!startWithMatch)
                        }}>
                            <Typography
                                variant="body2"
                                style={{
                                    cursor: 'pointer',
                                    color: startWithMatch ? 'blue' : 'inherit',
                                }}
                            >
                                Aa...
                            </Typography>
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Paper>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            component="div"
                            count={filteredRegistration.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                        <TableContainer>
                            <Table className={classes.table} aria-label="simple table">
                                <TableBody>
                                    {filteredRegistration.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                        <React.Fragment key={row.id}>
                                            <TableRow onClick={() => handleRowClick(row)}>
                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
                                                    <RegistrationCard registration={row}
                                                        configData={configData} from={from}
                                                        updateSingleRegistration={updateSingleRegistration}
                                                        handleReloadData={handleReloadData}
                                                        registrationView={registrationView}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ))}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            component="div"
                            count={filteredRegistration.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={handleCloseDrawer}
                PaperProps={{
                    sx: {
                        width: '75vw', // Set drawer to occupy half the screen width
                    }
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '16px' }}>
                    <IconButton onClick={handleCloseDrawer}>
                        <CloseIcon />
                    </IconButton>
                </div>
                {selectedRegistrationData && (
                    <RegistrationCard 
                        default_expanded={true}
                        registration={selectedRegistration}
                        registrationData={selectedRegistrationData}
                        configData={configData} 
                        from={from}
                        updateSingleRegistration={updateSingleRegistration}
                        handleReloadData={handleReloadData}
                        registrationView={registrationView}
                    />
                )}
            </Drawer>
        </div>
    );
};



const AllRegistration = () => {
    return (
        <>
            <AllApplication />
            <Divider sx={{ mt: 4 }} />
        </>
    )
}

export default AllRegistration;

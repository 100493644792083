import { Hotel as HotelIcon } from '@mui/icons-material';
import { Alert, AlertTitle, Button, Container, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ValidationPopup } from 'components/generics/ValidationPopup';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ApiRequest from 'services/api.service';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const QuoteCalculator = () => {

  const [error, setError] = useState('');
  const [confirmMessage, setConfirmMessage] = useState('')
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupAction, setPopupAction] = useState('error');
  const [popupType, setPopupType] = useState('error');
  const [totalMin, setTotalMin] = useState(0);
  const [totalMax, setTotalMax] = useState(0);

  const [configData, setConfigData] = useState(undefined);
  const [isConfigLoading, setIsConfigLoading] = useState(true);

  const [showNote, setShowNote] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.pathname


  const handleSubmit = async () => {
    const errors = await formik.validateForm()
    if (Object.keys(errors).length !== 0) {
      formik.setTouched(errors);
      if (Object.keys(errors).length > 1) {
        setIsPopupOpen(true);
        setError("Please fill all the mandatory fields.");
        return;
      }
      if (errors.roomTypeAndNumber) {
        if (Object.keys(errors.roomTypeAndNumber).length !== 0) {
          setIsPopupOpen(true);
          setError("Please select all the room type and number of rooms");
          return;
        }
      }
      return;
    }

    calculateTotalCost();
    setShowNote(true)
  };

  const validationSchema = Yup.object({
    adults: Yup.number().min(1, 'Number of adults must be at least 1').required('Number of adults is required'),
    children: Yup.number().min(0, 'Number of children cannot be negative').required('Number of children is required'),
    infants: Yup.number().min(0, 'Number of infants cannot be negative').required('Number of infants is required'),
    arrivalTime: Yup.date().required('Arrival date is required'),
    departureTime: Yup.date().required('Departure date is required'),
    transportationPreference: Yup.string().required('Transportation preference is required'),
    roomTypeAndNumber: Yup.array()
      .of(
        Yup.object().shape({
          roomType: Yup.string().required('Room type is required'),
          numRooms: Yup.number().min(1, 'Number of rooms must be at least 1').required('Number of rooms is required'),
        })
      )
  });

  const formik = useFormik({
    initialValues: {
      adults: 1,
      children: 0,
      infants: 0,
      arrivalTime: null,
      departureTime: null,
      transportationPreference: '',
      roomTypeAndNumber: [],
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      handleSubmit();
    },
  });

  useEffect(() => {
    setIsConfigLoading(true);
    if (!configData) {
      ApiRequest.handleGetApiRequest('/config', {}, false)
        .then((response) => {
          setConfigData(response);
          const destinationsResponse = (response.destinations || []).map(destination => ({
            ...destination,
            options: destination.options.reduce((acc, option) => {
              acc[option.displayText] = option;
              return acc;
            }, {})
          }));

          const roomTAndN = destinationsResponse.map(destination => ({
            roomType: '',
            numRooms: 1
          }));

          formik.setFieldValue('roomTypeAndNumber', roomTAndN);
        })
        .catch((error) => {
          console.error("Failed to fetch config:", error);
        })
        .finally(() => {
          setIsConfigLoading(false);
        });
    } else {
      setIsConfigLoading(false);
    }
  }, []); // Empty dependency array means this runs once on mount

  if (isConfigLoading) {
    return (<>
      Loading...
    </>);
  }

  const numNights = (room) => {
    return dayjs(room.checkOut).diff(dayjs(room.checkIn), 'day');
  }

  const destinationMinApproximation = (destination, index) => {
    const minPrice = destination.options[formik.values.roomTypeAndNumber[index].roomType].minPrice;
    return minPrice * numNights(destination) * formik.values.roomTypeAndNumber[index].numRooms;
  }

  const destinationMaxApproximation = (destination, index) => {
    const maxPrice = destination.options[formik.values.roomTypeAndNumber[index].roomType].maxPrice;
    return maxPrice * numNights(destination) * formik.values.roomTypeAndNumber[index].numRooms;
  }

  const calculateTotalCost = () => {

    const numDays = dayjs(formik.values.departureTime).diff(dayjs(formik.values.arrivalTime), 'day') + 1;

    const allMembersBill =
      (
        (configData.prasadamEstimate
          + formik.values.transportationEstimate
          + configData.yatraCharges
        )
        * numDays
        + (dayjs().isAfter(dayjs(configData.lateFeesDate)) && configData.lateFeesCharge ? configData.lateFeesCharge : 0)
      )
      * formik.values.adults
      + (formik.values.transportationEstimate * numDays * formik.values.children);

    const allRoomMin = destinations.reduce((total, destination, index) => total + destinationMinApproximation(destination, index), 0);
    const allRoomMax = destinations.reduce((total, destination, index) => total + destinationMaxApproximation(destination, index), 0);

    // calculate the cost here and show
    setTotalMin(allMembersBill + allRoomMin);
    setTotalMax(allMembersBill + allRoomMax);

  };

  const transportationPreferences = configData.transportationPreferences.reduce(
    (acc, option) => {
      acc[option.displayText] = option;
      return acc;
    }, {});

  const handleTransportationPreference = (displayText) => {
    const selectedOption = transportationPreferences[displayText];
    formik.setFieldValue('transportationEstimate', selectedOption.amount);
    formik.setFieldValue('transportationPreference', selectedOption.displayText);
  }

  const handleDateChange = (fieldName, newValue) => {
    // Format the date in local time zone instead of converting it to UTC
    const formattedDate = newValue ? newValue.format('YYYY-MM-DD') : '';

    formik.setFieldValue(fieldName, formattedDate);
  };

  const destinations = (configData.destinations || []).map(destination => ({
    ...destination,
    options: destination.options.reduce((acc, option) => {
      acc[option.displayText] = option;
      return acc;
    }, {})
  }));

  const handleAddRegistration = () => {
    navigate('/registration-list', { state: { inputConfigData: configData, from: from } })
  }

  return (
    <Container maxWidth="md" >
      <div class="registration-form">
        <div className="registration-form-content">
          <div className="page-section">
            <div className="registration-form-content-sec">
              <div className="rps-flex section-header">
                <h4 className="flex-one"><strong>Quote Calculator</strong></h4>
              </div>

              <ValidationPopup
                isOpen={isPopupOpen}
                message={popupType === 'error' ? error : confirmMessage}
                type={popupType}
                onConfirm={popupAction}
                onCancel={() => {
                  setIsPopupOpen(false);
                  setPopupType('error');
                  setError('')
                }}
              />

              <Grid container spacing={2} alignItems="center">
                <Grid item xs={4}>
                  <TextField
                    label="Adults > 5 *"
                    type="number"
                    fullWidth
                    {...formik.getFieldProps('adults')}
                    error={formik.touched.adults && Boolean(formik.errors.adults)}
                    helperText={formik.touched.adults && formik.errors.adults}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Children 2-5 *"
                    type="number"
                    fullWidth
                    {...formik.getFieldProps('children')}
                    error={formik.touched.children && Boolean(formik.errors.children)}
                    helperText={formik.touched.children && formik.errors.children}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Infants < 2 *"
                    type="number"
                    fullWidth
                    {...formik.getFieldProps('infants')}
                    error={formik.touched.infants && Boolean(formik.errors.infants)}
                    helperText={formik.touched.infants && formik.errors.infants}
                  />
                </Grid>

                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                  {/* Arrival Date */}
                  <Grid item xs={4} sm={4}>
                    <FormControl fullWidth variant="outlined" error={formik.touched.arrivalTime && Boolean(formik.errors.arrivalTime)}>
                      <DatePicker
                        label="Arrival Date *"
                        fullWidth
                        sx={{ width: '100%' }}
                        {...formik.getFieldProps('arrivalTime')}
                        value={formik.values.arrivalTime ? dayjs(formik.values.arrivalTime, 'YYYY-MM-DD') : null}
                        onChange={(newValue) => handleDateChange('arrivalTime', newValue)}
                        minDate={dayjs(configData.startDate)}
                        maxDate={formik.values.departureTime ? dayjs(formik.values.departureTime) : dayjs(configData.endDate)}
                        error={formik.touched.arrivalTime && Boolean(formik.errors.arrivalTime)}
                        helperText={formik.touched.arrivalTime && formik.errors.arrivalTime}
                      />
                      <FormHelperText>
                        {formik.touched.arrivalTime && formik.errors.arrivalTime}
                      </FormHelperText>
                    </FormControl>

                  </Grid>
                  {/* Departure Date */}
                  <Grid item xs={4} sm={4}>
                    <FormControl fullWidth variant="outlined" error={formik.touched.departureTime && Boolean(formik.errors.departureTime)}>
                      <DatePicker
                        label="Departure Date *"
                        fullWidth
                        sx={{ width: '100%' }}
                        {...formik.getFieldProps('departureTime')}
                        value={formik.values.departureTime ? dayjs(formik.values.departureTime, 'YYYY-MM-DD') : null}
                        onChange={(newValue) => handleDateChange('departureTime', newValue)}
                        minDate={formik.values.arrivalTime ? dayjs(formik.values.arrivalTime) : dayjs(configData.startDate)}
                        maxDate={dayjs(configData.endDate)}
                        error={formik.touched.departureTime && Boolean(formik.errors.departureTime)}
                        helperText={formik.touched.departureTime && formik.errors.departureTime}
                      />
                      <FormHelperText>
                        {formik.touched.departureTime && formik.errors.departureTime}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </LocalizationProvider>
                <Grid item xs={4} sm={4}>
                  <FormControl fullWidth variant="outlined" error={formik.touched.transportationPreference && Boolean(formik.errors.transportationPreference)}>
                    <InputLabel id="transportationPreference-label">Transportation preference *</InputLabel>
                    <Select
                      labelId="transportationPreference-label"
                      id="transportationPreference"
                      label="Transportation Estimate *"
                      {...formik.getFieldProps('transportationPreference')}
                      onChange={(event) => {
                        formik.handleChange(event); // Handle change in Formik
                        handleTransportationPreference(event.target.value); // Custom handling
                      }}
                    >
                      <MenuItem value="">Choose your transportation preference</MenuItem>
                      {Object.entries(transportationPreferences).map(([displayText, transportOption], index) => (
                        <MenuItem key={index} value={displayText}>{displayText}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {formik.touched.transportationPreference && formik.errors.transportationPreference}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                {destinations.map((room, destinationNumber) => (
                  <>
                    <Grid item xs={4} md={4}>
                      <Typography>
                        <strong>
                          {destinations[destinationNumber].location.toUpperCase()}
                        </strong>
                      </Typography>
                      <Typography>
                        {dayjs(destinations[destinationNumber].checkIn).format('MMM DD, YYYY')} to {dayjs(destinations[destinationNumber].checkOut).format('MMM DD, YYYY')}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <FormControl key={destinationNumber} fullWidth >
                        <InputLabel id={`destinations[${destinationNumber}].displayText`} htmlFor={destinations[destinationNumber].displayText}>{destinations[destinationNumber].displayText} *</InputLabel>
                        <Select
                          label={"Destination" + destinationNumber}
                          value={formik.values.roomTypeAndNumber[destinationNumber]?.roomType || ''}
                          onChange={(e) => {
                            const updatedRoomTypeAndNumber = [...formik.values.roomTypeAndNumber];
                            updatedRoomTypeAndNumber[destinationNumber] = { ...updatedRoomTypeAndNumber[destinationNumber], roomType: e.target.value };
                            formik.setFieldValue('roomTypeAndNumber', updatedRoomTypeAndNumber);
                          }}
                          fullWidth
                          variant="outlined"
                          InputProps={{
                            startAdornment: <HotelIcon />,
                          }}
                        >
                          <MenuItem value="">Select an option</MenuItem>
                          {Object.entries(destinations[destinationNumber].options).map(([displayText, roomOption], option) => (
                            <MenuItem key={displayText} value={displayText}>
                              {displayText}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <TextField
                        label="No of Rooms *"
                        type="number"
                        fullWidth
                        variant="outlined"
                        {...formik.getFieldProps(`roomTypeAndNumber[${destinationNumber}].numRooms`)}
                      />
                    </Grid>
                  </>
                ))}
              </Grid>

            </div>
          </div>
          <div className="registration-form-content-actions">
            <Button variant="contained" onClick={handleSubmit} sx={{ mt: 4 }} >
              {isSmallScreen ? <span style={{ fontSize: '8px' }}>Calculate Total Cost</span> : 'Calculate Total Cost'}
            </Button>
            <Typography variant="subtitle">
              Total Cost: ₹{totalMin} - ₹{totalMax}
            </Typography>
          </div>
          {showNote && <Alert
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setShowNote(false)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <AlertTitle>Important Note</AlertTitle>
            Thank you for using our quote calculator. Please keep in mind that the provided estimate is approximate, and your final bill will be based on the actual hotel allocation. Once you fill out the actual registration form and pay the registration amount, we will share the final details with you. To proceed with the registration, please follow the link below.
            <br />
          </Alert>}
          <div className="form-group" alignItems='end'>
            <Button
              variant="contained"
              onClick={handleAddRegistration}
              sx={{

                width: '100%',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {isSmallScreen ? (
                <Typography variant="body2">
                  Proceed with registration
                </Typography>
              ) : (
                <Typography variant="button">
                  Proceed with registration
                </Typography>
              )}
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default QuoteCalculator;

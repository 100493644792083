import { Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, Typography, createTheme } from '@mui/material';
import { ThemeProvider, makeStyles } from '@mui/styles';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import ApiRequest from 'services/api.service';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2196f3',
        },
        secondary: {
            main: '#f50057',
        },
    },
});

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%', // Ensure the container takes full height
        display: 'flex', // Make container flexbox
        flexDirection: 'column', // Set flex direction to column
        overflowY: 'auto', // Add overflow property to enable scrolling
    },
    card: {
        overflow: 'auto', // Add overflow property to make the card scrollable
        flexGrow: 1, // Allow the card to grow to fill remaining space
    },
    divider: {
        // margin: theme.spacing(2, 0), // Add margin to the divider
    },
    noBorder: {
        border: 'none', // Remove border from TableCell
    },
}));

const DestinationStats = () => {
    const classes = useStyles();

    // Define state for destinations
    const [destinations, setDestinations] = useState([]);

    // Define state for loading destinations
    const [isLoadingDestinations, setIsLoadingDestinations] = useState(true);

    // Define state for selected destination
    const [selectedDestination, setSelectedDestination] = useState(0);

    const [registrationStatus, setRegistrationStatus] = useState("REGISTERED"); // Default value for registration status

    const handleRegistrationStatusChange = (event) => {
        const status = event.target.value;
        setRegistrationStatus(status);
        handleReloadData(status === "ALL" ? null : status);
    };

    const handleReloadData = async (status) => {
        const response = await ApiRequest.handleGetApiRequest('/admin/stats/destinations', { registrationStatus: status });
        if(response) {
            setDestinations(response);
            setIsLoadingDestinations(false);
        } else {
            alert("Error fetching the data");
        }
    }

    // Fetch destinations
    useEffect(() => {
        handleReloadData(registrationStatus)
    }, []);

    const getTotal = (roomOptionStats) => {
        const totalCount = Object.values(roomOptionStats).reduce((acc, currentValue) => acc + currentValue.count, 0);
        return totalCount - roomOptionStats.not_attending.count;
    }

    const roomsToBook = (roomOptionStats) => {
        const totalCount = getTotal(roomOptionStats);
        return totalCount - roomOptionStats.own_arrangement.count;
    }

    if (isLoadingDestinations) {
        return <div>Loading...</div>; // Render loading indicator while data is being fetched
    }

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="md" className={classes.container}>
                <div className="page-section">
                    {/* Title */}
                    <div className="registration-form-content-actions">
                        <Grid container justifyContent="space-between" alignItems="center" marginTop='20px'>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body2" align='left' style={{ fontSize: '1rem' }}>
                                    <strong>
                                        {'Destination ' + (selectedDestination + 1) + ' : ' + destinations[selectedDestination].location.toUpperCase()}
                                    </strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="destination-label">Select Destination</InputLabel>
                                    <Select
                                        labelId="destination-label"
                                        id="destination-select"
                                        value={selectedDestination}
                                        onChange={(e) => setSelectedDestination(e.target.value)}
                                    >
                                        {destinations.map((destination, index) => (
                                            <MenuItem key={index} value={destination.destinationNumber}>
                                                {destination.displayText}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                                    <InputLabel id="registration-status-label">Registration Status</InputLabel>
                                    <Select
                                        labelId="registration-status-label"
                                        id="registration-status-select"
                                        value={registrationStatus}
                                        onChange={handleRegistrationStatusChange}
                                        label="Registration Status"
                                        style={{ marginRight: '10px' }}
                                    >
                                        <MenuItem value="ALL">ALL</MenuItem>
                                        <MenuItem value="REGISTERED">REGISTERED</MenuItem>
                                        <MenuItem value="NOT_REGISTERED">NOT_REGISTERED</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body2" align='left' style={{ fontSize: '0.9rem' }}>
                                    <strong>{destinations[selectedDestination].info}</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body2" align='right' style={{ fontSize: '0.7rem', color: 'orange' }}>
                                    {dayjs(destinations[selectedDestination].checkIn).format('MMM DD, YYYY')} to {dayjs(destinations[selectedDestination].checkOut).format('MMM DD, YYYY')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>

                    <Grid container justifyContent="space-between" alignItems="center" >
                        <Grid item xs={12}>
                            <Paper style={{ overflowY: 'auto' }}>
                                <Table style={{ minWidth: "300px" }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><strong>Room Type</strong></TableCell>
                                            <TableCell><strong>Count</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow key="total">
                                            <TableCell>Rooms to book</TableCell>
                                            <TableCell>{roomsToBook(destinations[selectedDestination].roomOptionStats)}</TableCell>
                                        </TableRow>
                                        <TableRow key="total">
                                            <TableCell>Rooms needed(including own arrangement)</TableCell>
                                            <TableCell>{getTotal(destinations[selectedDestination].roomOptionStats)}</TableCell>
                                        </TableRow>
                                        {Object.values(destinations[selectedDestination].roomOptionStats).map((option, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{option.displayText}</TableCell>
                                                <TableCell>{option.count}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </ThemeProvider>
    );
};

export default DestinationStats;

export const firstFiveDaysSchedule = [
  {
    day: 'D-1',
    label: 'Haridwar'
  },
  {
    day: 'D-2',
    label: 'Haridwar'
  },
  {
    day: 'D-3',
    label: 'Base Haridwar: Day visit to Kurukshetra'
  },
  {
    day: 'D-4',
    label: 'Base Haridwar: Rishikesh'
  },
  {
    day: 'D-5',
    label: 'Base Haridwar: Sukratal'
  }
];

export const lastFiveDaysSchedule = [
  {
    day: 'D-6',
    label: 'Base Haridwar: Rishikesh'
  },
  {
    day: 'D-7',
    label: 'Haridwar to Badrinath'
  },
  {
    day: 'D-8',
    label: 'Badrinath: Narrations of Panca Badri, Dev Prayag, Rudra Prayag, etc'
  },
  {
    day: 'D-9',
    label: 'Badrinath: Mana Village, Vyas Gufa, Ganesh Gufa, Nara Narayan Rsi, Saraswati River, etc'
  },
  {
    day: 'D-10',
    label: 'Badrinath to Haridwar'
  }
];
import {
    Map as MapIcon
} from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import { Autocomplete, Button, Drawer, FormControl, Grid, Icon, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { ClearIcon } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';
import ApiRequest from 'services/api.service';
import LoginService from 'services/login.service';
import CloseIcon from '@mui/icons-material/Close';

const StyledIcon = (props) => {
    return <Icon style={{ display: 'initial' }} {...props} />;
};

const FilterPanel = ({ configData, uniqueValues, handleClearSorting, filterParams, setFilterParams, handleReloadData, registrations, setRegistrations, registrationView, setRegistrationView }) => {

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [filterText, setFilterText] = useState(localStorage.getItem('filterText') || '');
    const [inventories, setInventories] = useState([]);
    const [selectedInvenotory, setSelectedInvenotory] = useState(null);

    // Filter parameter
    const [registrationStatus, setRegistrationStatus] = useState(localStorage.getItem('registrationStatus') || "ALL");
    const handleRegistrationStatusChange = (event) => {
        localStorage.setItem('registrationStatus', event.target.value);
        setRegistrationStatus(event.target.value);
    };
    const [paymentStatus, setPaymentStatus] = useState(localStorage.getItem('paymentStatus') || "ALL");
    const handlePaymentStatusChange = (event) => {
        localStorage.setItem('paymentStatus', event.target.value);
        setPaymentStatus(event.target.value);
    };
    const [roomAllocationStatus, setRoomAllocationStatus] = useState(localStorage.getItem('roomAllocationStatus') || "ALL");
    const handleRoomAllocationStatusChange = (event) => {
        localStorage.setItem('roomAllocationStatus', event.target.value);
        setRoomAllocationStatus(event.target.value);
    };
    const [areaCoordinator, setAreaCoordinator] = useState(localStorage.getItem('areaCoordinator') || "ALL");
    const areaCoordinatorOptions = configData.areaCoordinator || [];
    const handleAreaCoordinatorChange = (event) => {
        localStorage.setItem('areaCoordinator', event.target.value);
        setAreaCoordinator(event.target.value);
    };

    const handleRegistrationViewChange = (event) => {
        localStorage.setItem('registrationView', event.target.value);
        if ('ROOM_ALLOCATION' === event.target.value) {
            setRegistrationStatus('REGISTERED');
        } else {
            setRegistrationStatus(localStorage.getItem('registrationStatus') || "ALL")
        }
        setRegistrationView(event.target.value);
    };

    const fetchInventories = async () => {
        const roomInventoryItemsResponse = await ApiRequest.handleGetApiRequest('/admin/roomAllocation/roomInventoryItem');
        const inventoryList = roomInventoryItemsResponse.reduce((acc, map) => {
            const values = Object.values(map).flat();
            return acc.concat(values);
        }, []);
        setInventories(inventoryList);
    }

    useEffect(() => {
        if (!filterText || filterText === '') {
            handleReloadData()
            return
        }

        const filteredData = registrations.filter((row) => {

            const value = String(filterText).toLowerCase();

            const idMatch = value === String(row.id);

            const nameMatch = row.userDetails.name.toLowerCase().startsWith(value);

            const initiatedNameMatch = row.userDetails.initiatedName.toLowerCase().startsWith(value);

            // return idMatch || memberMatch || userDetailsMatch || registrationMatch || roomMatch || paymentHistoryMatch;
            return idMatch || nameMatch || initiatedNameMatch;
        });

        setRegistrations(filteredData);
    }, [filterText])

    const applyFilters = () => {
        const newFilterParams = {
            registrationStatus: registrationStatus === "ALL" ? null : registrationStatus,
            paymentStatus: paymentStatus === "ALL" ? null : paymentStatus,
            areaCoordinator: areaCoordinator === "ALL" ? null : areaCoordinator,
            roomAllocationStatus: roomAllocationStatus === "ALL" ? null : roomAllocationStatus,
            inventoryId: selectedInvenotory?.id
        };
        setFilterParams(newFilterParams)
        localStorage.setItem('filterParams', JSON.stringify(newFilterParams));
    }

    const downloadMembers = () => {
        applyFilters()
        ApiRequest.handleDownloadRequest('/admin/download/members/', filterParams).then(() => {
            alert("Download complete")
        }).catch(error => {
            console.error("Download failed:", error);
        });
    }

    const downloadRooms = () => {
        applyFilters()
        ApiRequest.handleDownloadRequest('/admin/download/rooms/', filterParams).then(() => {
            alert("Download complete")
        }).catch(error => {
            console.error("Download failed:", error);
        });
    };

    const downloadWristband = () => {
        applyFilters()
        ApiRequest.handleDownloadRequest('/admin/download/wristband/', filterParams).then(() => {
            alert("Download complete")
        }).catch(error => {
            console.error("Download failed:", error);
        });
    };

    const downloadRoomsAllocated = () => {
        applyFilters()
        ApiRequest.handleDownloadRequest('/admin/download/rooms/allocated/', filterParams).then(() => {
            alert("Download complete")
        }).catch(error => {
            console.error("Download failed:", error);
        });
    };

    const handleCloseDrawer = () => {
        setDrawerOpen(false);
    };

    const handleFilterClick = () => {
        setDrawerOpen(true);
    }

    const handleClearSettings = () => {
        handleClearSorting()
        setFilterText('');
        setRegistrationStatus('ALL');
        setPaymentStatus('ALL');
        setRoomAllocationStatus('ALL');
        setAreaCoordinator('ALL');
        setSelectedInvenotory(null)

        setFilterParams({
            registrationStatus: null,
            paymentStatus: null,
            roomAllocationStatus: null,
            areaCoordinator: null
        });

        // Clear from localStorage
        localStorage.removeItem('filterParams');
        localStorage.removeItem('rowsPerPage');
        localStorage.removeItem('filterText');
        localStorage.removeItem('registrationStatus');
        localStorage.removeItem('paymentStatus');
        localStorage.removeItem('roomAllocationStatus')
        localStorage.removeItem('areaCoordinator');
    }

    function underscoreToTitleCase(str) {
        return str
            .split('_') // Split the string by underscores
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
            .join(' '); // Join them back together with spaces
    }

    const DisplayRoom = ({ roomInventory }) => {
        const { hotelName, location, contactNumber, checkIn, checkOut, optionType, destinationNumber, address, googleMapAddress } = roomInventory;
        return <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="title">
                        {`${roomInventory.id}, ${underscoreToTitleCase(optionType)}, ${hotelName}`}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Typography variant="body2" color="textSecondary">
                        {`Destination - ${destinationNumber + 1}`}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Typography variant="body2" color="textSecondary">
                        {`${location}`}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Typography variant="body2" color="textSecondary">
                        {`${new Date(checkIn).toLocaleDateString()} - ${new Date(checkOut).toLocaleDateString()}`}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <StyledIcon>
                        <a href={googleMapAddress} target="_blank" rel="noopener noreferrer">
                            Map
                            <MapIcon />
                        </a>
                    </StyledIcon>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <Typography variant="body2" color="textSecondary">
                        {`${contactNumber}`}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Typography variant="body2" color="textSecondary">
                        {`${address}`}
                    </Typography>
                </Grid>
            </Grid>
        </>
    }

    return <>
        <Grid container justifyContent="space-between" alignItems="center">
            <Grid container justifyContent="left" item xs={8} md={8} ml={3}>
                <Autocomplete
                    options={uniqueValues}
                    fullWidth
                    value={filterText}
                    getOptionLabel={(option) => String(option)}
                    onChange={(event, value) => {
                        event.stopPropagation();
                        setFilterText(value);
                        const localStorageValue = !value ? '' : value;
                        localStorage.setItem('filterText', localStorageValue);
                    }}
                    clearOnBlur={true}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Search..."
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <IconButton size="small">
                                        <SearchIcon />
                                    </IconButton>
                                )
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid container justifyContent="left" item xs={3} md={3}>
                <Tooltip title="Settings!">
                    <IconButton onClick={handleFilterClick}>
                        <SettingsIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
        <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={handleCloseDrawer}
            PaperProps={{
                sx: {
                    width: '75vw',
                }
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '16px' }}>
                <IconButton onClick={handleCloseDrawer}>
                    <CloseIcon />
                </IconButton>
            </div>
            <Grid container justifyContent="space-between" alignItems="center" ml={3} mr={3}>
                <Grid container justifyContent="left" item xs={12} md={12} mr={6} mt={4}>
                    <FormControl variant="outlined" fullWidth mt={4}>
                        <InputLabel id="view-label">View</InputLabel>
                        <Select
                            labelId="view-label"
                            id="registration-view"
                            value={registrationView}
                            onChange={handleRegistrationViewChange}
                            label="Registration View"
                        >
                            <MenuItem value="REGISTRATION">REGISTRATION</MenuItem>
                            <MenuItem value="ROOM_ALLOCATION">ROOM_ALLOCATION</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid container justifyContent="left" item xs={6} md={6} mt={4} mb={4}>
                    <Tooltip title="Reset sorting, filter and other settings!">
                        Reset filter and other settings<br />
                        <IconButton onClick={() => {handleClearSettings();handleCloseDrawer();}}>
                            <ClearIcon />
                        </IconButton>
                    </Tooltip>

                </Grid>
                <Grid container justifyContent="left" item xs={6} md={6} mt={4} mb={4}>
                    <Tooltip title="Reload data!">
                        Reload the data<br />
                        <IconButton onClick={() => {handleReloadData(); handleCloseDrawer();}}>
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid container justifyContent="left" item xs={12} md={12} mr={6}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="areaCoordinator">Area Coordinator *</InputLabel>
                        <Select
                            id="areaCoordinator"
                            name="areaCoordinator"
                            label="Area Coordinator"
                            value={areaCoordinator}
                            onChange={handleAreaCoordinatorChange}
                        >
                            <MenuItem value="ALL">ALL</MenuItem>
                            {areaCoordinatorOptions.map((coordinator) => (
                                <MenuItem key={coordinator} value={coordinator}>
                                    {coordinator}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="registration-status-label">Registration Status</InputLabel>
                        <Select
                            labelId="registration-status-label"
                            id="registration-status-select"
                            value={registrationStatus}
                            onChange={handleRegistrationStatusChange}
                            label="Registration Status"
                        >
                            <MenuItem value="ALL">ALL</MenuItem>
                            <MenuItem value="REGISTERED">REGISTERED</MenuItem>
                            <MenuItem value="NOT_REGISTERED">NOT_REGISTERED</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="payment-status-label">Payment Status</InputLabel>
                        <Select
                            labelId="payment-status-label"
                            id="payment-status-select"
                            value={paymentStatus}
                            onChange={handlePaymentStatusChange}
                            label="Payment Status"
                        >
                            <MenuItem value="ALL">ALL</MenuItem>
                            <MenuItem value="NOT_PAID">NOT_PAID</MenuItem>
                            <MenuItem value="REGISTRATION_PARTIALLY_PAID">REGISTRATION_PARTIALLY_PAID</MenuItem>
                            <MenuItem value="REGISTRATION_PAID">REGISTRATION_PAID</MenuItem>
                            <MenuItem value="PARTIALLY_PAID">PARTIALLY_PAID</MenuItem>
                            <MenuItem value="FULL_PAID">FULL_PAID</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="room-allocation-status-label">Room Allocation</InputLabel>
                        <Select
                            labelId="room-allocation-status-label"
                            id="room-allocation-status-select"
                            value={roomAllocationStatus}
                            onChange={handleRoomAllocationStatusChange}
                            label="Room Allocation"
                        >
                            <MenuItem value="ALL">ALL</MenuItem>
                            <MenuItem value="ALLOCATED">ALLOCATED</MenuItem>
                            <MenuItem value="PARTIALLY_ALLOCATED">PARTIALLY_ALLOCATED</MenuItem>
                            <MenuItem value="NOT_ALLOCATED">NOT_ALLOCATED</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid container justifyContent="left" item xs={12} md={12} mt={4} mr={6}>
                    <Autocomplete
                        fullWidth
                        options={inventories}
                        getOptionLabel={(roomInventory) => {
                            if (roomInventory) {
                                return `${roomInventory.id} - ${roomInventory.hotelName} - ${roomInventory.location} - Check-in: ${new Date(roomInventory.checkIn).toLocaleDateString()} - Check-out: ${new Date(roomInventory.checkOut).toLocaleDateString()}`
                            }
                            return ' ';
                        }}
                        onFocus={fetchInventories}
                        value={selectedInvenotory} // Sttach state to value
                        onChange={(event, room) => setSelectedInvenotory(room)} // Update state on change
                        clearOnBlur={true}
                        renderOption={(props, option) => (
                            <li {...props}>
                                <DisplayRoom roomInventory={option} />
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Room Inventory"
                                placeholder="Select Room Inventory"
                            />
                        )}
                    />
                </Grid>
                <Grid container justifyContent="left" item xs={12} md={12} mt={4} mb={4}>
                    <div className="registration-form-content-actions" style={{ 'justify-content': 'end' }}>
                        {LoginService.isAdmin() &&
                            <>
                                <Button
                                    variant="contained"
                                    onClick={() => {applyFilters();handleCloseDrawer()}}
                                    size="small" // Make the button small
                                    sx={{
                                        marginLeft: 'auto' // Push the button to the right side
                                    }}
                                >
                                    Apply settings
                                </Button>
                            </>}
                    </div>
                </Grid>
                <Grid container justifyContent="left" item xs={12} md={5} mt={4}>
                    <div className="registration-form-content-actions" style={{ 'justify-content': 'end' }}>
                        {LoginService.isAdmin() &&
                            <>
                                <Button
                                    variant="contained"
                                    onClick={() => {downloadMembers();handleCloseDrawer();}}
                                    size="small" // Make the button small
                                    sx={{
                                        marginLeft: 'auto' // Push the button to the right side
                                    }}
                                >
                                    Download members
                                </Button>
                            </>}
                    </div>
                </Grid>
                {/* <Grid container justifyContent="left" item xs={12} md={3} mt={4}>
                    <div className="registration-form-content-actions" style={{ 'justify-content': 'end' }}>
                        {LoginService.isAdmin() &&
                            <>
                                <Button
                                    variant="contained"
                                    onClick={() => downloadRooms()}
                                    size="small" // Make the button small
                                    sx={{
                                        marginLeft: 'auto' // Push the button to the right side
                                    }}
                                >
                                    Download rooms
                                </Button>
                            </>}
                    </div>
                </Grid> */}
                <Grid container justifyContent="left" item xs={12} md={5} mr={6} mt={4}>
                    <div className="registration-form-content-actions" style={{ 'justify-content': 'end' }}>
                        {LoginService.isAdmin() &&
                            <>
                                <Button
                                    variant="contained"
                                    onClick={() => {downloadRoomsAllocated(); handleCloseDrawer();}}
                                    size="small" // Make the button small
                                    sx={{
                                        marginLeft: 'auto' // Push the button to the right side
                                    }}
                                >
                                    Download rooms allocated
                                </Button>
                            </>}
                    </div>
                </Grid>

                <Grid container justifyContent="left" item xs={12} md={5} mr={6} mt={4}>
                    <div className="registration-form-content-actions" style={{ 'justify-content': 'end' }}>
                        {LoginService.isAdmin() &&
                            <>
                                <Button
                                    variant="contained"
                                    onClick={() => {downloadWristband(); handleCloseDrawer();}}
                                    size="small" // Make the button small
                                    sx={{
                                        marginLeft: 'auto' // Push the button to the right side
                                    }}
                                >
                                    Download wristband
                                </Button>
                            </>}
                    </div>
                </Grid>

            </Grid>
        </Drawer>
    </>
}

export default FilterPanel
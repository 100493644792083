import React from 'react';

import styles from './faqs.module.scss';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import pitthuImage from '../../../assets/images/pitthu-image.jpg';

function Faqs() {

  return (
    <div id={styles.faqsMain}>

      <h3 className={styles.heading}>Frequently Asked Questions</h3>

      <Accordion>
        <AccordionSummary className={styles.question} expandIcon={<ExpandMoreIcon />}>1. How long is the travel to Badrinath by bus and by car?</AccordionSummary>
        <AccordionDetails>
          <label>Ans. From our Base in Haridwar to our Base in Badrinath it is 300 Kms</label>
          <label>Travel by Car - 10-12 Hrs</label>
          <label>Travel by Bus - 12-14 Hrs.</label>
          <label>There are many factors that might increase the travel time. Like general traffic Jams as its a single lane road or jams due to land slides.</label>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary className={styles.question} expandIcon={<ExpandMoreIcon />}>2. What are the places we are visiting in Badrinath?</AccordionSummary>
        <AccordionDetails>Ans. Pls check schedule for details, but in short one day we will be going to Badrinath main temple and other day we will visit a village named Mana. In that Village we are visiting Ganesh gufa, Vyas Gufa, and Sarswati River Udgam.</AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary className={styles.question} expandIcon={<ExpandMoreIcon />}>3. Can car or bus take us there or should we walk? If yes is it uphill or plains?</AccordionSummary>
        <AccordionDetails>
          <label>Ans. Yes Car and bus will take you till parking space. But from parking you have to walk to the above destinations.</label>
          <label>Or you can take a Pitthu (A person will carry you in a shoulder basket)</label>
          <label>It would cost around 1000-1500/- from our Base in Badrinath to Badrinath main temple.</label>
          <label>And for Mana Village also 1200- 1500 /-  from bus parking to the above 3 place and return to bus / Car parking.</label>
          <label>If your weight is more then 80 kg then they will charge extra.</label>
          <label>For reference how Pitthu will look like or carry you in basket, pls see the Image attached down.</label>
          <img src={pitthuImage} className={styles.image} alt='pitthu' />
          <label>Pls note that while going to Badrinath main temple 60% walk is in flat surface walking and Hilly walk is around 40 %</label>
          <label>In Mana Village - Hilly walk is  90% and flat surface is only 10%</label>
          <label>The Bus drive from our Base to Mana Village is only 4 km.</label>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary className={styles.question} expandIcon={<ExpandMoreIcon />}>4. If we have to walk is there any option for elders who can't walk?</AccordionSummary>
        <AccordionDetails>Ans. Yes, option is there as mentioned above.</AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary className={styles.question} expandIcon={<ExpandMoreIcon />}>5. If there are options how much do they take for such (pallaki etc) services?</AccordionSummary>
        <AccordionDetails>Ans. 1200-1500 per person till 80 KG..</AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary className={styles.question} expandIcon={<ExpandMoreIcon />}>6. Can we travel back in the night from Badrinath to Haridwar?</AccordionSummary>
        <AccordionDetails>
          <label>Ans. Some say you can travel back in night from Badrinath to Haridwar by your own Car. Some same the army closes the roads by evening.</label>
          <label>Even if they permit, it is EXTREMELY RISKY. </label>
          <label>given the road landside and other unpredictable conditions. </label>
          <label>Even the drivers will refuse to take us at night time. </label>
          <label>As per our schedule we are staying in Badrinath for 2 days. And we will travel in the day time. </label>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary className={styles.question} expandIcon={<ExpandMoreIcon />}>7. Will yatra organizers have bus arrangement from Haridwar to Badrinath and back?</AccordionSummary>
        <AccordionDetails>Yes. If you have opted for bus then we will provide.</AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary className={styles.question} expandIcon={<ExpandMoreIcon />}>8. If a devotee who has registered for Badrinath yatra has a respiratory issue and may need oxygen cylinder especially in Badrinath. Should we request someone now to arrange for it or when we come to the yatra?</AccordionSummary>
        <AccordionDetails>Ans.  <a href='https://amzn.in/d/07LPJhIf'>https://amzn.in/d/07LPJhIf</a><br />
          Please buy the above if needed for your own purpose.<br />
          Any company is fine. Need not be same company<br />
        </AccordionDetails>
      </Accordion>

    </div>
  );
}

export default Faqs;
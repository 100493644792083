import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, Button, Grid, Typography, createTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginService from 'services/login.service';
import ApiRequest from '../../services/api.service';
import { ValidationPopup } from '../generics/ValidationPopup';
import FullSummary from '../summaries/FullSummary';
import PrintRegistrationCard from './PrintRegistrationCard';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2196f3',
        },
        secondary: {
            main: '#f50057',
        },
    },
});

const useStyles = makeStyles({
    accordion: {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Initial shadow with elevated effect
        borderRadius: '20px',
        transition: 'box-shadow 0.3s', // Smooth transition for box-shadow change
        margin: '15px 0', // Margin added for top and bottom
        cursor: 'pointer',
        '&:last-child': {
            marginBottom: 0,
        },
        '&:hover': {
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.3)', // Increased shadow on hover
            backgroundColor: 'rgba(0, 0, 0, 0.1)', // Darken background color on hover
        },
    },
    accordionSummary: {
        borderRadius: '20px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    },
});

const RegistrationCard = ({ registration, registrationData, configData, from, updateSingleRegistration, handleReloadData, registrationView, default_expanded = false }) => {

    const classes = useStyles();
    const [roomView, setRoomView] = useState((registrationView && registrationView === 'ROOM_ALLOCATION') || false);
    const [expanded, setExpanded] = useState(roomView || default_expanded);
    const [error, setError] = useState('');
    const [confirmMessage, setConfirmMessage] = useState('')
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupAction, setPopupAction] = useState('error');
    const [popupType, setPopupType] = useState('error');

    const navigate = useNavigate();

    const [isAdmin, setIsAdmin] = useState(false);
    useEffect(() => {
        setIsAdmin(LoginService.isAdmin());
    }, []);

    useEffect(() => {
        const roomViewStatus = (registrationView && registrationView === 'ROOM_ALLOCATION') || false
        setRoomView(roomViewStatus);
        setExpanded(roomViewStatus || default_expanded);
    }, [registrationView]);

    const handleMarkAsRegistered = () => {
        setPopupType('confirm');
        setConfirmMessage('Are you sure you want to mark this application as registered?')
        setIsPopupOpen(true);
        setPopupAction(() => async () => {
            try {
                setConfirmMessage('Started marking it as register.... please wait')
                const registrationDTO = await ApiRequest.handlePostApiRequest('/admin/mark/registration/done', {}, { regId: registration.id });
                if (registrationDTO) {
                    await updateSingleRegistration(registrationDTO);
                }
                setConfirmMessage('Done..!!!')
                setIsPopupOpen(false);
                setPopupType('error');
            } catch (error) {
                alert(error.message)
                alert('Could not mark it as registered')
            }
        });
    }

    const handleMarkAsNotRegistered = () => {
        setPopupType('confirm');
        setConfirmMessage('Are you sure you want to mark this application as not registered?')
        setIsPopupOpen(true);
        setPopupAction(() => async () => {
            try {
                setConfirmMessage('Started marking this as not register.... please wait')
                const registrationDTO = await ApiRequest.handlePostApiRequest('/admin/mark/registration/not', {}, { regId: registration.id });
                if (registrationDTO) {
                    await updateSingleRegistration(registrationDTO);
                }
                setConfirmMessage('Done..!!!')
                setIsPopupOpen(false);
                setPopupType('error');
            } catch (error) {
                alert(error.message)
                alert('Could not mark it as not registered')
            }
        });
    }

    const handleEditRegistration = (registration) => {
        navigate('/registration-form', { state: { inputConfigData: configData, registration: registration, from: from } })
    }

    const handleDeleteRegistration = (id) => {
        setPopupType('confirm');
        setConfirmMessage('Are you sure you want to delete this registration?');
        setIsPopupOpen(true);
        setPopupAction(() => async () => {
            ApiRequest.handleDeleteApiRequest('/registration', { id: id }).then(
                (response) => {
                    if (!response) {
                        alert(response.message);
                    }
                    handleReloadData();
                    // updateSingleRegistration(registration);
                    // window.location.reload();
                }
            );
            setIsPopupOpen(false);
            setPopupType('error');
        });
    };

    const toUpperCase = (str) => {
        if (str) {
            return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        } else {
            return str;
        }
    };

    return (
        <>
            <ValidationPopup
                isOpen={isPopupOpen}
                message={popupType === 'error' ? error : confirmMessage}
                type={popupType}
                onConfirm={popupAction}
                onCancel={() => {
                    setIsPopupOpen(false);
                    setPopupType('error');
                    setError('')
                }}
            />
            <Accordion key={registration.id}
                expanded={expanded}
                onChange={() => setExpanded(!expanded)}
                className={classes.accordion}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={classes.accordionSummary}
                    sx={{
                        backgroundColor: (
                            registration.registrationStatus === 'NOT_REGISTERED' && registration.paymentStatus === 'NOT_PAID' ? '#c6d3f7' :
                                registration.registrationStatus === 'NOT_REGISTERED' && registration.paymentStatus === 'REGISTRATION_PARTIALLY_PAID' ? '#f1b0f5' :
                                    registration.registrationStatus === 'REGISTERED' && registration.paymentStatus === 'NOT_PAID' ? '#f5b0b7' :
                                        registration.registrationStatus === 'REGISTERED' && registration.paymentStatus === 'REGISTRATION_PARTIALLY_PAID' ? '#ebf5b0' :
                                            registration.registrationStatus === 'REGISTERED' && registration.paymentStatus === 'REGISTRATION_PAID' ? '#c5fce5' :
                                                '#c5fce5'
                        )
                    }}
                >
                    <Grid container alignItems="center">
                        <Grid item xs={12} md={8} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="h5"><strong>{toUpperCase(registration.userDetails.name)}</strong></Typography>
                        </Grid>
                        <Grid item xs={8} md={2} textAlign="left">
                            <Typography variant="body2" color="textSecondary">
                                Reg Id : {registration.id}
                            </Typography>
                        </Grid>
                        {registrationData && <>
                            <Grid item xs={1} md={0.25} textAlign="left">
                                {(registration.registrationStatus === "NOT_REGISTERED" || isAdmin) && (
                                    <EditIcon
                                        onClick={(event) => { event.stopPropagation(); handleEditRegistration(registrationData); }}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={1} md={0.25} textAlign="left">
                                {registration.registrationStatus === "NOT_REGISTERED" && registration.paymentStatus === 'NOT_PAID' && (
                                    <DeleteIcon
                                        className='ml-2'
                                        onClick={(event) => { event.stopPropagation(); handleDeleteRegistration(registration.id) }}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={1} md={0.25} textAlign="left">
                                {registration.registrationStatus === "REGISTERED" && (
                                    <PrintRegistrationCard
                                        registration={registrationData}
                                        configData={configData}
                                    />
                                )}
                            </Grid>
                        </>}
                        <Grid item xs={12} md={4} >
                            <Typography variant="body2" color="textSecondary">
                                {registration.userDetails.initiatedName}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="body2" color="textSecondary">
                                {registration.userDetails.areaCoordinator}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <Typography variant="body2" color="textSecondary">
                                {registration.userDetails.email}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <Typography variant="body2" color="textSecondary">
                                {registration.userDetails.countryCode} {registration.userDetails.contactNumber}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <Typography variant="body2" color="textSecondary">
                                {registration.registrationStatus}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <Typography variant="body2" color="textSecondary">
                                {registration.paymentStatus}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} >
                            <Typography variant="body2" color="textSecondary">
                                {registration.numMembers} Member
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} >
                            <Typography variant="body2" color="textSecondary">
                                {registration.numRooms} Room
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4} >
                            {LoginService.isAdmin() && registrationData &&
                                <div className="slim-button" style={{ 'justify-content': 'left' }}>
                                    {registration.registrationStatus === 'NOT_REGISTERED' &&
                                        <Button
                                            variant="contained"
                                            onClick={(event) => { event.stopPropagation(); handleMarkAsRegistered(registration) }}
                                            size="small" // Make the button small
                                            sx={{
                                                // marginLeft: 'auto', // Push the button to the right side
                                                fontSize: 'xx-small',
                                                minHeight: '20px',
                                            }}
                                        >
                                            Mark as Registered
                                        </Button>
                                    }
                                    {registration.registrationStatus !== 'NOT_REGISTERED' &&
                                        <Button
                                            variant="contained"
                                            onClick={(event) => { event.stopPropagation(); handleMarkAsNotRegistered(registration) }}
                                            size="small" // Make the button small
                                            sx={{
                                                // marginLeft: 'auto', // Push the button to the right side
                                                fontSize: 'xx-small',
                                                minHeight: '20px',
                                            }}
                                        >
                                            Mark as Not Registered
                                        </Button>
                                    }
                                </div>
                            }
                        </Grid>
                    </Grid>
                </AccordionSummary>
                {registrationData &&
                    <FullSummary
                        registration={registrationData}
                        configData={configData}
                        defaultExpanded={false}
                        from={from}
                        updateSingleRegistration={updateSingleRegistration}
                        roomView={roomView}
                    />
                }
            </Accordion >
        </>
    )
}

export default RegistrationCard
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, Divider, FormControlLabel, FormHelperText, Paper, Switch, TextField, Typography, createTheme } from '@mui/material';
import { ThemeProvider, makeStyles } from '@mui/styles';
import { useState } from 'react';
import PaymentButton from './PaymentButton';
import { ValidationPopup } from 'components/generics/ValidationPopup';
import ApiRequest from 'services/api.service';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2196f3',
        },
        secondary: {
            main: '#f50057',
        },
    },
});

const AddPayment = ({ registrationDTO, defaultExpanded, from, updateSingleRegistration }) => {

    const [expanded, setExpanded] = useState(defaultExpanded && true);
    const [amountToPay, setAmountToPay] = useState(0);
    const [error, setError] = useState('');
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupAction, setPopupAction] = useState('error');
    const [popupType, setPopupType] = useState('error');
    const [isFinalPayment, setIsFinalPayment] = useState(true);

    const handleAmountChange = (event) => {
        const value = parseInt(event.target.value, 10); // Parse the value as an integer
        setAmountToPay(isNaN(value) ? 0 : value); // Ensure value is a valid integer
    };
    const handlePostPaymentAction = async () => {
        const updatedRegistration = await ApiRequest.handleGetApiRequest('/registration', { registrationId: registrationDTO.id });
        updateSingleRegistration(updatedRegistration);
        setAmountToPay(0);
        setIsPopupOpen(true);
        setError("Your payment for this transaction has been processed. Please check the transaction section for details.");
    }

    const totalAmount = isFinalPayment ? Math.max(registrationDTO.finalBill + registrationDTO.miscCharge - registrationDTO.onlineAmount - registrationDTO.miscPaymentAmount, 0) : amountToPay;
    const gstAmount = (totalAmount * 0.05).toFixed(2);
    const convenienceFee = (totalAmount * 0.02).toFixed(2);

    return (
        <>
            <ValidationPopup
                isOpen={isPopupOpen}
                message={popupType === 'error' ? error : ''}
                type={popupType}
                onConfirm={popupAction}
                onCancel={() => {
                    setIsPopupOpen(false);
                    setPopupType('error');
                    setError('')
                }}
            />
            <Accordion key='acc_registratoin_financial_summary' sx={{ mb: 2 }} expanded={expanded} onChange={() => setExpanded(!expanded)}>
                <AccordionSummary key={'acc_registratoin_financial_summary'} expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="title"><strong>Make the payment</strong></Typography>
                </AccordionSummary>
                <AccordionDetails key='acc__detailsregistratoin_financial_summary'>
                    <ThemeProvider theme={theme}>
                        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <Typography variant="body1">Partial Payment</Typography>
                                <Switch
                                    checked={isFinalPayment}
                                    onChange={(e) => setIsFinalPayment(e.target.checked)}
                                    name="finalPayment"
                                    color="primary"
                                />
                                <Typography variant="body1">Final Payment</Typography>
                            </Box>
                            <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Amount :</span>
                                <span>{totalAmount}</span>
                            </Typography>
                            <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>GST (5%):</span>
                                <span>{gstAmount}</span>
                            </Typography>
                            <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Convenience Fee (2%):</span>
                                <span>{convenienceFee}</span>
                            </Typography>
                            <Typography variant="h5" mt={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Amount to pay:</span>
                                <span>{Math.ceil(totalAmount * 1.07)}</span>
                            </Typography>
                            <FormHelperText>Rounded to next positive integer</FormHelperText>
                            <div className="registration-form-content-actions">
                                {isFinalPayment ? (<div></div>) : (<TextField
                                    id="amount"
                                    name="amount"
                                    label="Amount"
                                    variant="outlined"
                                    value={totalAmount}
                                    onChange={handleAmountChange}
                                    sx={{
                                        minWidth: '50px',
                                        minHeight: '40px',
                                    }}
                                />)}
                                <PaymentButton
                                    regId={registrationDTO.id}
                                    from={from}
                                    amount={totalAmount}
                                    handlePostPaymentAction={handlePostPaymentAction}
                                />
                            </div>
                        </Paper>
                    </ThemeProvider>
                </AccordionDetails>
            </Accordion>


        </>
    );
};

export default AddPayment;

import React from 'react';
import Signup from './Signup';

const ForgotResetPassword = () => {

    return (
        <Signup isPasswordReset={true} buttonText={"Reset password"}/>
    )
}

export default ForgotResetPassword;
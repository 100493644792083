import {
    LocalOffer as AmenitiesIcon,
    KingBed as BedIcon,
    Hotel as HotelIcon,
    LocationOn as LocationIcon,
    Map as MapIcon,
    LocalHotel as MattressIcon,
    Phone as PhoneIcon
} from '@mui/icons-material';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Grid, Icon, Typography } from '@mui/material';

const StyledIcon = (props) => {
    return <Icon style={{ display: 'initial' }} {...props} />;
};

const RoomInventoryCard = ({ item, onEdit, onDelete, destination, option }) => {
    return (
        <Grid container alignItems="center">
            <Grid item xs={10} md={11} textAlign="left">
                <Typography variant="body1">
                    <strong>
                        <StyledIcon><HotelIcon /> </StyledIcon>
                        {item.hotelName}
                    </strong>
                </Typography>
            </Grid>
            <Grid item xs={1} md={0.5} textAlign="left">
                <EditIcon onClick={(event) => {event.stopPropagation(); onEdit(item, destination, option)}} />
            </Grid>
            <Grid item xs={1} md={0.5} textAlign="left">
                <DeleteIcon onClick={(event) => {event.stopPropagation(); onDelete(item)}} />
            </Grid>
            <Grid item xs={8} md={8} textAlign="left">
                <StyledIcon><LocationIcon /> </StyledIcon>
                {item.address}
            </Grid>
            <Grid item xs={4} md={4} textAlign="left">
                <StyledIcon><MapIcon /> </StyledIcon>
                {item.googleMapAddress}
            </Grid>
            <Grid item xs={8} md={8} textAlign="left">
                <StyledIcon><AmenitiesIcon /> </StyledIcon>
                {item.amenities}
            </Grid>
            <Grid item xs={4} md={4} textAlign="left">
                <StyledIcon><BedIcon /> </StyledIcon>
                {item.bedType}
            </Grid>
            <Grid item xs={8} md={8} textAlign="left">
                <StyledIcon><CurrencyRupeeIcon /> </StyledIcon>
                {item.pricePerDay}/<span style={{ fontSize: '0.8em' }}>Night</span>
            </Grid>
            <Grid item xs={4} md={4} textAlign="left">
                <StyledIcon><MattressIcon /> </StyledIcon>
                {item.extraMattressPrice}/<span style={{ fontSize: '0.8em' }}>Mattress</span>
            </Grid>
            <Grid item xs={8} md={8} textAlign="left">
                <StyledIcon><HotelIcon /> </StyledIcon>
                {item.numberOfRooms} <span style={{ fontSize: '0.8em' }}>Rooms</span>
            </Grid>
            <Grid item xs={4} md={4} textAlign="left">
                <StyledIcon><PhoneIcon /> </StyledIcon>
                {item.contactNumber}
            </Grid>
            <Grid item xs={4} md={4} textAlign="left">
                <StyledIcon><HotelIcon /> </StyledIcon>
                {item.numberOfRooms - item.roomsAllocated} <span style={{ fontSize: '0.8em' }}>Rooms Available</span>
            </Grid>
            <Grid item xs={4} md={4} textAlign="left">
                <StyledIcon><HotelIcon /> </StyledIcon>
                {item.roomsAllocated} <span style={{ fontSize: '0.8em' }}>Rooms allocated</span>
            </Grid>
            <Grid item xs={4} md={4} textAlign="left">
                <StyledIcon><HotelIcon /> </StyledIcon>
                {item.roomsShared} <span style={{ fontSize: '0.8em' }}>Rooms shared</span>
            </Grid>
        </Grid>
    );
};

export default RoomInventoryCard;

import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export function ValidationPopup({ isOpen, message, type, onConfirm, onCancel, loading = false }) {

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    onCancel();
  }
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{type === 'error' ? 'Info' : 'Confirmation'}</DialogTitle>
      <DialogContent>
        {message.split('\n').map((line, index) => (
          <DialogContentText key={index}>{line}</DialogContentText>
        ))}
        {/* <DialogContentText>{message}</DialogContentText> */}
      </DialogContent>
      <DialogActions>
        {type === 'error' ? (
          <Button disabled={loading} onClick={handleClose} color="primary">
            OK
          </Button>
        ) : (
          <>
            <Button disabled={loading} onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button disabled={loading} onClick={onConfirm} color="primary">
              Confirm
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

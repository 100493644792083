import React, { useState } from 'react';
import { TextField, Button, Typography, Box, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { AddBox } from '@mui/icons-material';
import UpdateIcon from '@mui/icons-material/Update';

const CountryForm = ({ onCountriesChange }) => {
  const [country, setCountry] = useState('');
  const [countries, setCountries] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [error, setError] = useState('');

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const handleAddCountry = () => {
    if (country.trim() !== '') {
      if (isEditing) {
        const updatedCountries = [...countries];
        updatedCountries[editIndex] = country;
        setCountries(updatedCountries);
        setIsEditing(false);
        setEditIndex(null);
      } else {
        setCountries([...countries, country]);
      }
      setCountry('');
      setError('');
    } else {
      setError('Country name cannot be empty.');
    }
  };

  const handleEditCountry = (index) => {
    setCountry(countries[index]);
    setIsEditing(true);
    setEditIndex(index);
  };

  const handleDeleteCountry = (index) => {
    const updatedCountries = countries.filter((c, i) => i !== index);
    setCountries(updatedCountries);
  };

  const handleClearError = () => {
    setError('');
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ mt: 2 }}>Countries</Typography>
      {error && <Typography variant="body2" color="error">{error}</Typography>}
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
          <tbody>
            {countries.map((c, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>{c}</td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                  <IconButton onClick={() => handleEditCountry(index)} aria-label="edit">
                    <EditIcon />
                  </IconButton>
                </td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                  <IconButton onClick={() => handleDeleteCountry(index)} aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        {countries.map((c, index) => (
          <Box key={index} display="flex" alignItems="center">
            <Typography>{c}</Typography>
            <IconButton onClick={() => handleEditCountry(index)} aria-label="edit">
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleDeleteCountry(index)} aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
      </Box>
      <TextField
        name="country"
        label="Country"
        value={country}
        onChange={handleCountryChange}
        error={error !== ''}
        helperText={error}
        onFocus={handleClearError}
      />
      <br />
      <Button variant="contained" onClick={handleAddCountry} sx={{ mt: 1 }}>
        {isEditing ? <UpdateIcon sx={{ mr: 1 }} /> : <AddBox sx={{ mr: 1 }} />} {isEditing ? 'Update Country' : 'Add Country'}
      </Button>
    </Box>
  );
};

export default CountryForm;

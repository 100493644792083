export const formatTimestamp = (timestamp) => {
    if(!timestamp || timestamp === 0) {
        return "NOT_CAPTURED";
    }
    const date = new Date(timestamp);
    return date.toLocaleString(undefined, { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' });
};

export const formatToIndianRupee = (amount) => {
    return amount.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })
}

export const formatToIndianRupeeFromPaise = (amount) => {
    return (amount / 100).toLocaleString('en-IN', { style: 'currency', currency: 'INR' })
}

export const getDayName = (dateString) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    // Assuming dateString is in a format that's interpreted as UTC or you've converted it to such.
    const date = new Date(dateString);
    const dayIndex = date.getUTCDay();
    const month = date.toLocaleDateString('en', { month: 'short', timeZone: 'UTC' });
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    const dayName = days[dayIndex];
    return `${dayName}, ${month} ${day}, ${year}`;
};

export const getDay = (dateString) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    // Assuming dateString is in a format that's interpreted as UTC or you've converted it to such.
    const date = new Date(dateString);
    const dayIndex = date.getUTCDay();
    const month = date.toLocaleDateString('en', { month: 'short', timeZone: 'UTC' });
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    const dayName = days[dayIndex];
    return `${day} ${month}, ${year}`;
};
import React from 'react';

import styles from './YatraPricing.module.scss';

function YatraPricing() {

  return (
    <div className={styles.yatraPricingMain}>
      <h3 className={styles.heading}>Yatra Pricing (per person)</h3>
      <div className={styles.sectionsList}>
      <section>
        <h4>Food</h4>
        <label>800 per day</label>
      </section>
      <section>
        <h4>AC Bus</h4>
        <label>1000 per day</label>
      </section>
      <section>
        <h4>Non AC Bus</h4>
        <label>500 per day</label>
      </section>
      <section>
        <h4>Misc/other yatra arrangements cost</h4>
        <label>200 per day</label>
      </section>
      <section>
        <h4>Room rent charges (will depend based on your choice)</h4>
        <label>2000 to 9000 per day</label>
      </section>
      <section>
        <h4>Charges per person with basic options for full yatra</h4>
        <label>Rs. 20,000</label>
      </section>
      </div>
    </div>
  );

}

export default YatraPricing;
import React from 'react';

import styles from './ContactUs.module.scss';

function ContactUsPage() {

    return (
        <div className={styles.contactUsMain}>
            <h3 className={styles.heading}>For questions or inquiries, contact us at:</h3>

            <div className={styles.parentSection}>
                <h5 className={styles.sectionHeading}>India Contact:</h5>
                <p>
                    Dham Yatra LLP<br />
                    Flat No: 103 & 104, Raghunath Apartments <br />
                    4-3-65/1, Sultan Bazar <br />
                    Hyderabad - 500001<br />
                    Telangana, INDIA<br />
                    Phone: 9246 560 560<br />
                    Email: dham.yatra.rps@gmail.com<br />
                </p>
                <br />
                <br />
                <h5 className={styles.sectionHeading}>US Contact:</h5>
                <p>
                    Dham Yatra LLP<br />
                    804 Donelson Ct,<br />
                    Naperville, IL 60563<br />
                    USA<br />
                    Phone: (630) 300 4707<br />
                    Email: romapadaswamivideos@gmail.com<br />
                </p>
            </div>

        </div>
    );

}

export default ContactUsPage;

// import React from 'react';
// import { Container, Typography } from '@mui/material';

// const ContactUsPage = () => {
//     return (
//         <Container maxWidth="md" sx={{ marginTop: 4 }}>
//             <Typography variant="h4" gutterBottom>If you have any questions or inquiries, feel free to contact us at:</Typography>
//             <Typography variant="body1">
//                 <br />
//                 <br />
//                 <strong>India Contact:</strong><br />
//                 Dham Yatra LLP<br />
//                 4-1-239, IInd Floor, Hanuman Tekdi,<br />
//                 Troop Bazar, Opp lane to Bank of Maharashtra<br />
//                 Hyderabad - 500001<br />
//                 Telangana, INDIA<br />
//                 Phone: 9246 560 560<br />
//                 Email: romapadaswamivideos@gmail.com<br />

//                 <br />
//                 <strong>US Contact:</strong><br />
//                 Dham Yatra LLP<br />
//                 804 Donelson Ct,<br />
//                 Naperville, IL 60563<br />
//                 USA<br />
//                 Phone: (630) 300 4707<br />
//                 Email: romapadaswamivideos@gmail.com<br />
//             </Typography>
//         </Container>
//     );
// };

// export default ContactUsPage;

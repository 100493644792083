import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Paper, Typography, createTheme } from '@mui/material';
import { ThemeProvider, makeStyles } from '@mui/styles';
import { useState } from 'react';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2196f3',
        },
        secondary: {
            main: '#f50057',
        },
    },
});

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%', // Ensure the container takes full height
        display: 'flex', // Make container flexbox
        flexDirection: 'column', // Set flex direction to column
    },
    card: {
        overflow: 'auto', // Add overflow property to make the card scrollable
        flexGrow: 1, // Allow the card to grow to fill remaining space
    },
    divider: {
        // margin: theme.spacing(2, 0), // Add margin to the divider
    },
    noBorder: {
        border: 'none', // Remove border from TableCell
    },
}));

const RegistrationBill = ({ registrationDTO, defaultExpanded, isFullSummary }) => {

    const [expanded, setExpanded] = useState(defaultExpanded && true);

    // Calculate GST and Convenience fee for all members
    // const members = registrationDTO.members;
    // const registrationAmount = members.reduce((total, member) => total + member.lateFees + member.registrationCharges, 0);
    const credit = registrationDTO.miscPaymentAmount + registrationDTO.onlineAmount;
    const amountToPay = registrationDTO.registrationAmount < credit ? 0 : registrationDTO.registrationAmount - credit;

    const gst = amountToPay * 0.05;
    const convenienceFee = amountToPay * 0.02;
    const grandTotal = amountToPay * 1.07;

    const classes = useStyles();

    return (
        <>
            <Accordion key='acc_registratoin_financial_summary' sx={{ mb: 2 }} expanded={expanded} onChange={() => setExpanded(!expanded)}>
                <AccordionSummary key={'acc_registratoin_financial_summary'} expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="title"><strong>Registration bill</strong></Typography>
                </AccordionSummary>
                <AccordionDetails key='acc__detailsregistratoin_financial_summary'>
                    <ThemeProvider theme={theme}>

                        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                            {registrationDTO.members.map((member, index) => (
                                <Box key={index} mb={1}>
                                    <Typography variant="body1"><strong>{member.name.toUpperCase()}</strong></Typography>
                                    <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>Registration Charge:</span>
                                        <span>{member.registrationCharges}</span>
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>Late Fees:</span>
                                        <span>{member.lateFees}</span>
                                    </Typography>
                                    <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>Total:</span>
                                        <span>{(member.registrationCharges + member.lateFees)}</span>
                                    </Typography>
                                    <Divider sx={{ mt: 2 }} />
                                </Box>
                            ))}
                            <Divider sx={{ mt: 4 }} />
                            <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Registration total:</span>
                                <span>{registrationDTO.registrationAmount}</span>
                            </Typography>
                            {!isFullSummary && <>
                                <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Available credit:</span>
                                    <span> - {credit}</span>
                                </Typography>
                                <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Total:</span>
                                    <span>{amountToPay}</span>
                                </Typography>
                                <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>GST (5%):</span>
                                    <span>{gst.toFixed(2)}</span>
                                </Typography>
                                <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Convenience Fee (2%):</span>
                                    <span>{convenienceFee.toFixed(2)}</span>
                                </Typography>
                                <Typography variant="h5" mt={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Grand Total:</span>
                                    <span>{grandTotal.toFixed(2)}</span>
                                </Typography>
                            </>}
                        </Paper>
                    </ThemeProvider>
                </AccordionDetails>
            </Accordion>


        </>
    );
};

export default RegistrationBill;

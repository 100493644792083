import { AddBox } from '@mui/icons-material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import UpdateIcon from '@mui/icons-material/Update';
import { Autocomplete, Box, Button, Divider, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ClearIcon } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import { useFormik } from 'formik';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { countryCodeData } from '../../constants/jsons/country';
import { ValidationPopup } from '../generics/ValidationPopup';
import { MemberSummary } from '../summaries/ShortSummary';

const MemberDetailsForm = ({ configData, registrationDTO, handleCrudInDB, nextStep, prevStep }) => {

  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [isEditing, setIsEditing] = useState(false);
  // const [members, setMembers] = useState(registrationDTO.members || []);
  const [error, setError] = useState('');
  const [confirmMessage, setConfirmMessage] = useState('')
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupAction, setPopupAction] = useState('error');
  const [popupType, setPopupType] = useState('error');
  const ageLimit = configData.ageLimit || 5;
  const userDetails = registrationDTO.userDetails || {};


  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    initiatedName: Yup.string(),
    age: Yup.number()
      .required('Age is required')
      .min(0, 'No time travelers allowed! Age: 0+.')
      .max(150, 'Stay under 150! No age-limit exceeded!')
      .integer('Stay whole: Age: Integer vibes only!'),
    gender: Yup.string().required('Gender is required'),
    city: Yup.string().required('City is required'),
    countryCode: Yup.string().required('Country code is required')
      .test('validCountryCode', 'Country code is not valid', function (value) {
        return value && countryCodeData.includes(value);
      }),
    contactNumber: Yup.string()
      .required('Contact number is required')
      .test('validPhoneNumber', 'Phone number is Invalid for the country', function (value) {
        const countryCode = this.parent.countryCode;
        try {
          return !countryCode || !value || parsePhoneNumber(countryCode + value).isValid();
        } catch (error) {
          console.log(error);
          return false;
        }
      }),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    preferredHearingLanguage: Yup.string().required('Preferred hearing language is required'),
    arrivalTime: Yup.date()
      .required('Arrival date is required')
      .typeError('Arrival date must be a valid date')
      .test('is-in-range', 'Selected date is out of range', function (value) {
        const startDate = dayjs(configData.startDate).startOf('day');
        const endDate = dayjs(configData.endDate).startOf('day');
        const selectedDate = dayjs(value).startOf('day');
        return !value || (selectedDate.diff(startDate, 'day') >= 0 && selectedDate.diff(endDate, 'day') <= 0);
      }),
    departureTime: Yup.date()
      .required('Departure date is required')
      .typeError('Departure date must be a valid date')
      .test('is-in-range', 'Selected date is out of range', function (value) {
        const startDate = dayjs(configData.startDate).startOf('day');
        const endDate = dayjs(configData.endDate).startOf('day');
        const selectedDate = dayjs(value).startOf('day');
        return !value || (selectedDate.diff(startDate, 'day') >= 0 && selectedDate.diff(endDate, 'day') <= 0);
      })
      .min(Yup.ref('arrivalTime'), 'Departure date must be later than or equal to arrival date'),
    transportationPreference: Yup.string().required('Transportation estimate is required'),
    transportationEstimate: Yup.number(),
    prasadamEstimate: Yup.number().required('Prasadam estimate is required').min(0, 'Prasadam estimate must be a non-negative number'),
    registrationCharges: Yup.number().required('Registration charges are required').min(0, 'Registration charges must be a non-negative number'),
    yatraCharges: Yup.number().required('Yatra charges are required').min(0, 'Yatra charges must be a non-negative number'),
    lateFees: Yup.number().min(0, 'Late fees must be a non-negative number'),
  });

  const formik = useFormik({
    initialValues: {
      id: '',
      index: '',
      name: '',
      initiatedName: '',
      age: '',
      gender: '',
      city: userDetails.city || '',
      countryCode: userDetails.countryCode || '',
      contactNumber: userDetails.contactNumber || '',
      email: userDetails.email || '',
      preferredHearingLanguage: configData.preferredHearingLanguage[0] || '',
      arrivalTime: '',
      departureTime: '',
      transportationPreference: '',
      prasadamEstimate: 0,
      registrationCharges: 0,
      yatraCharges: 0,
      lateFees: 0
    },
    validationSchema: validationSchema,
  });

  const handlePrev = async (event) => {
    event.preventDefault();
    if (formik.dirty) {
      setPopupType('confirm');
      setConfirmMessage('We see that you have added some values in the current form. If you go back, you will lose only the current form; the values added for any other members will remain')
      setIsPopupOpen(true);
      setPopupAction(() => () => {
        setIsPopupOpen(false);
        setPopupType('error');
        prevStep()
      });
      return;
    }
    prevStep()
  }

  const [open, setOpen] = useState(false);
  let tooltipTimeout;
  const handleSubmit = () => {
    // Clear existing timeout to prevent unwanted behavior
    clearTimeout(tooltipTimeout);

    // If the tooltip is already open, close it immediately on click
    if (open) {
      setOpen(false);
      return;
    }

    // Check conditions to show the tooltip
    if (formik.dirty || registrationDTO.members.length === 0 || !registrationDTO.members.some(member => member.age > 5)) {
      setOpen(true);

      // Set a timeout to automatically hide the tooltip after 3 seconds
      tooltipTimeout = setTimeout(() => {
        setOpen(false);
      }, 3000);
    } else {
      nextStep(); // Proceed with the next step if no tooltip condition is met
    }
  };

  const handleEditMember = (index) => {
    const editedMember = registrationDTO.members[index];
    formik.setValues({
      ...editedMember,
      index: index,
    })
    setIsEditing(true);
  }

  const handleReset = () => {
    formik.resetForm();
    setIsEditing(false);
  }

  const handleAddUpdateMember = async () => {
    const errors = await formik.validateForm()
    if (Object.keys(errors).length !== 0) {
      formik.setTouched(errors);
      if (Object.keys(errors).length > 1) {
        setIsPopupOpen(true);
        setError("Please fill all the mandatory fields.");
        return;
      }
      if (errors.arrivalTime) {
        setIsPopupOpen(true);
        setError(errors.arrivalTime);
      }
      if (errors.departureTime) {
        setIsPopupOpen(true);
        setError(errors.departureTime);
      }
      return;
    }
    // Validate form first
    let updatedMembers;
    if (isEditing) {
      updatedMembers = registrationDTO.members.map((m, i) =>
        i === formik.values.index ? formik.values : m
      );
      // setMembers(updatedMembers)
    } else { // Not editing add new member
      updatedMembers = ([
        ...registrationDTO.members,
        formik.values,
      ])
      // setMembers([...members, formik.values]); // Add the new member to the members array
    }
    // setMembers(updatedMembers)
    formik.resetForm()
    setIsEditing(false);
    await handleCrudInDB('members', updatedMembers);
    setIsPopupOpen(true);
    setError('Member ' + (isEditing ? 'updated' : 'added') + ' successfully');
  }

  const handleDeleteMember = (index) => {
    if (formik.dirty) {
      setIsPopupOpen(true);
      setError('Update and delete cannot be done simultaneously. Please either update first or clear out the form.');
      return false;
    }
    setPopupType('confirm');
    setConfirmMessage('Are you sure you want to delete this member?')
    setIsPopupOpen(true);
    setPopupAction(() => async () => {
      const updatedMembers = registrationDTO.members.filter((m, i) => i !== index);
      // setMembers(updatedMembers);
      setIsPopupOpen(false);
      setPopupType('error');
      await handleCrudInDB('members', updatedMembers);
      setIsPopupOpen(true);
      setError('Member deleted successfully');
      // setMembers(registrationDTO.members);
    });
  };

  const handleDateChange = (fieldName, newValue) => {
    // Format the date in local time zone instead of converting it to UTC
    const formattedDate = newValue ? newValue.format('YYYY-MM-DD') : '';

    formik.setFieldValue(fieldName, formattedDate);
  };

  useEffect(() => {
    const updateChargesBasedOnAge = () => {
      if (formik.values.age > ageLimit) {
        const lateFees = dayjs().isAfter(dayjs(configData.lateFeesDate)) ? configData.lateFeesCharge : 0;
        formik.setFieldValue('prasadamEstimate', configData.prasadamEstimate);
        formik.setFieldValue('registrationCharges', configData.registrationCharges);
        formik.setFieldValue('yatraCharges', configData.yatraCharges);
        formik.setFieldValue('lateFees', lateFees);
      } else {
        formik.setFieldValue('prasadamEstimate', 0);
        formik.setFieldValue('registrationCharges', 0);
        formik.setFieldValue('yatraCharges', 0);
        formik.setFieldValue('lateFees', 0);
      }
    };
    if (formik.values.age && formik.values.age !== '') {
      updateChargesBasedOnAge();
    }

    const ageField = document.getElementById('age');
    ageField.addEventListener('change', updateChargesBasedOnAge);

    return () => {
      ageField.removeEventListener('change', updateChargesBasedOnAge);
    };
  }, [formik.values.age]);

  const transportationPreferences = configData.transportationPreferences.reduce(
    (acc, option) => {
      acc[option.displayText] = option;
      return acc;
    }, {});

  const handleTransportationPreference = (displayText) => {
    const selectedOption = transportationPreferences[displayText];
    formik.setFieldValue('transportationEstimate', selectedOption.amount);
    formik.setFieldValue('transportationPreference', selectedOption.displayText);
  }

  return (
    <>
      <div className="page-section">
        <Box>
          <div className="rps-flex section-header">
            <h4 className="flex-one">Members Attending Yatra</h4>
          </div>
          <ValidationPopup
            isOpen={isPopupOpen}
            message={popupType === 'error' ? error : confirmMessage}
            type={popupType}
            onConfirm={popupAction}
            onCancel={() => {
              setIsPopupOpen(false);
              setPopupType('error');
              setError('')
            }}
          />

          {registrationDTO.members.map((member, memberIndex) => (
            <MemberSummary
              member={member}
              memberIndex={memberIndex}
              handleEditMember={handleEditMember}
              handleDeleteMember={handleDeleteMember}
              defaultExpanded={false}
            />
          ))}

          <Typography variant="h6">
            {isEditing ?
              <strong>Updating member : {formik.values.name} </strong>
              :
              <strong>Please add your new member below.</strong>
            }
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="name"
                label="Name *"
                variant="outlined"
                fullWidth
                {...formik.getFieldProps('name')}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* Initiated Name */}
              <TextField
                id="initiatedName"
                label="Initiated Name"
                variant="outlined"
                fullWidth
                {...formik.getFieldProps('initiatedName')}
              />
            </Grid>

            <Grid item xs={12} sm={8}>
              {/* Age */}
              <TextField
                id="age"
                label="Age *"
                variant="outlined"
                fullWidth
                type="number"
                {...formik.getFieldProps('age')}
                error={formik.touched.age && Boolean(formik.errors.age)}
                helperText={
                  <>
                    {formik.touched.age && formik.errors.age ? (
                      formik.errors.age
                    ) : (
                      formik.values.age <= 5 && (
                        <Typography style={{ color: 'green', fontSize: '0.8rem' }}>
                          For members aged {ageLimit} years old or younger, Prasadam, Registration, Yatra, and Late fees will be provided free of charge.
                        </Typography>
                      )
                    )}
                  </>
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              {/* Gender */}
              <FormControl fullWidth variant="outlined" error={formik.touched.gender && Boolean(formik.errors.gender)}>
                <InputLabel id="gender-label">Gender *</InputLabel>
                <Select
                  labelId="gender-label"
                  id="gender"
                  label="Gender *"
                  {...formik.getFieldProps('gender')}
                >
                  <MenuItem value="">Select Gender</MenuItem>
                  <MenuItem value="MALE">Male</MenuItem>
                  <MenuItem value="FEMALE">Female</MenuItem>
                </Select>
                <FormHelperText>{formik.touched.gender && formik.errors.gender}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              {/* Country Code */}
              <Autocomplete
                options={countryCodeData}
                getOptionLabel={(option) => option}
                fullWidth
                value={formik.values.countryCode || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country Code *"
                    variant="outlined"
                    {...formik.getFieldProps('countryCode')}
                    error={formik.touched.countryCode && Boolean(formik.errors.countryCode)}
                    helperText={formik.touched.countryCode && formik.errors.countryCode}
                  />
                )}
                onChange={(event, value) => formik.setFieldValue('countryCode', value || '')}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              {/* Contact Number */}
              <TextField
                id="contactNumber"
                label="Contact Number *"
                variant="outlined"
                fullWidth
                {...formik.getFieldProps('contactNumber')}
                error={formik.touched.contactNumber && Boolean(formik.errors.contactNumber)}
                helperText={formik.touched.contactNumber && formik.errors.contactNumber}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              {/* Email */}
              <TextField
                id="email"
                label="Email *"
                variant="outlined"
                fullWidth
                {...formik.getFieldProps('email')}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* City */}
              <TextField
                id="city"
                label="City *"
                variant="outlined"
                fullWidth
                {...formik.getFieldProps('city')}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* Preferred Hearing Language */}
              <FormControl fullWidth variant="outlined" error={formik.touched.preferredHearingLanguage && Boolean(formik.errors.preferredHearingLanguage)}>
                <InputLabel id="preferredHearingLanguage-label">Preferred Hearing Language *</InputLabel>
                <Select
                  labelId="preferredHearingLanguage-label"
                  id="preferredHearingLanguage"
                  label="Preferred Hearing Language *"
                  {...formik.getFieldProps('preferredHearingLanguage')}
                >
                  <MenuItem value="">Choose your preferred hearing Language</MenuItem>
                  {configData.preferredHearingLanguage.map((code) => (
                    <MenuItem key={code} value={code}>{code}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>{formik.touched.preferredHearingLanguage && formik.errors.preferredHearingLanguage}</FormHelperText>
              </FormControl>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
              {/* Arrival Date */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined" error={formik.touched.arrivalTime && Boolean(formik.errors.arrivalTime)}>
                  <DatePicker
                    label="Arrival Date *"
                    fullWidth
                    sx={{ width: '100%' }}
                    {...formik.getFieldProps('arrivalTime')}
                    value={formik.values.arrivalTime ? dayjs(formik.values.arrivalTime, 'YYYY-MM-DD') : null}
                    onChange={(newValue) => handleDateChange('arrivalTime', newValue)}
                    minDate={dayjs(configData.startDate)}
                    maxDate={formik.values.departureTime ? dayjs(formik.values.departureTime) : dayjs(configData.endDate)}
                    error={formik.touched.arrivalTime && Boolean(formik.errors.arrivalTime)}
                    helperText={formik.touched.arrivalTime && formik.errors.arrivalTime}
                  />
                  <FormHelperText>
                    {formik.touched.arrivalTime && formik.errors.arrivalTime}
                  </FormHelperText>
                </FormControl>
              </Grid>
              {/* Departure Date */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined" error={formik.touched.departureTime && Boolean(formik.errors.departureTime)}>
                  <DatePicker
                    label="Departure Date *"
                    fullWidth
                    sx={{ width: '100%' }}
                    {...formik.getFieldProps('departureTime')}
                    value={formik.values.departureTime ? dayjs(formik.values.departureTime, 'YYYY-MM-DD') : null}
                    onChange={(newValue) => handleDateChange('departureTime', newValue)}
                    minDate={formik.values.arrivalTime ? dayjs(formik.values.arrivalTime) : dayjs(configData.startDate)}
                    maxDate={dayjs(configData.endDate)}
                    error={formik.touched.departureTime && Boolean(formik.errors.departureTime)}
                    helperText={formik.touched.departureTime && formik.errors.departureTime}
                  />
                  <FormHelperText>
                    {formik.touched.departureTime && formik.errors.departureTime}
                  </FormHelperText>
                </FormControl>
              </Grid>
              {formik.values.arrivalTime && formik.values.departureTime &&
                <FormHelperText style={{ fontSize: '0.8rem', color: 'green', paddingLeft: '1rem' }}>
                  {configData.destinations.map((destination, index) => {
                    if (destination.checkOut > configData.endDate) {
                      return;
                    }
                    const arrivalTime = dayjs(formik.values.arrivalTime).startOf('day');
                    const departureTime = dayjs(formik.values.departureTime).startOf('day');
                    const checkIn = dayjs(destination.checkIn).startOf('day');
                    const checkOut = dayjs(destination.checkOut).startOf('day');

                    const singleDay = arrivalTime.isSame(departureTime) && (arrivalTime.isSame(checkIn) || arrivalTime.isSame(checkOut));

                    const isFullYatra = (arrivalTime.isSame(checkIn) || arrivalTime.isBefore(checkIn)) &&
                      (departureTime.isSame(checkOut) || departureTime.isAfter(checkOut));

                    const isPartYatra = (arrivalTime.isAfter(checkIn) && arrivalTime.isBefore(checkOut)) ||
                      (departureTime.isBefore(checkOut) && departureTime.isAfter(checkIn));

                    let conditionText;
                    if (singleDay) {
                      conditionText = 'This day may involve settling up or traveling';
                    } else if (isFullYatra) {
                      conditionText = 'You are attending the full yatra';
                    } else if (isPartYatra) {
                      conditionText = 'You are attending part of the yatra';
                    } else {
                      conditionText = 'You are not attending the yatra';
                    }
                    let chargeInfo = '';
                    if (isPartYatra) {
                      chargeInfo = `You will be charged for all the days in ${destination.location} room`;
                    }

                    // Display messages based on the comparison
                    return (
                      <>
                        {conditionText} at <strong>{destination.location.toUpperCase()}. </strong>
                        We are in {destination.location} from <strong>{dayjs(destination.checkIn).format('DD/MM/YYYY')} to {dayjs(destination.checkOut).format('DD/MM/YYYY')} </strong>.
                        {chargeInfo}
                        <br />
                      </>
                    );
                  })}
                </FormHelperText>
              }
            </LocalizationProvider>
            <Grid item xs={12} sm={12}>
              {/* Transportation Estimate */}
              <FormControl fullWidth variant="outlined" error={formik.touched.transportationPreference && Boolean(formik.errors.transportationPreference)}>
                <InputLabel id="transportationPreference-label">Transportation preference *</InputLabel>
                <Select
                  labelId="transportationPreference-label"
                  id="transportationPreference"
                  label="Transportation Estimate *"
                  {...formik.getFieldProps('transportationPreference')}
                  onChange={(event) => {
                    formik.handleChange(event); // Handle change in Formik
                    handleTransportationPreference(event.target.value); // Custom handling
                  }}
                >
                  <MenuItem value="">Choose your transportation preference</MenuItem>
                  {Object.entries(transportationPreferences).map(([displayText, transportOption], index) => (
                    <MenuItem key={index} value={displayText}>{displayText}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {formik.touched.transportationPreference && formik.errors.transportationPreference ?
                    (formik.errors.transportationPreference) :
                    (
                      <Typography style={{ color: 'green', fontSize: '0.8rem' }}>
                        {transportationPreferences[formik.values.transportationPreference]?.info}
                      </Typography>
                    )
                  }
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              {/* Prasadam Estimate */}
              <TextField
                id="prasadamEstimate-label"
                label="Prasadam per day *"
                variant="outlined"
                fullWidth
                {...formik.getFieldProps('prasadamEstimate')}
                error={formik.touched.prasadamEstimate && Boolean(formik.errors.prasadamEstimate)}
                helperText={formik.touched.prasadamEstimate && formik.errors.prasadamEstimate}
                disabled={true}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              {/* Yatra Charges */}
              <TextField
                id="yatraCharges-label"
                label="Yatra charges per day *"
                variant="outlined"
                fullWidth
                {...formik.getFieldProps('yatraCharges')}
                error={formik.touched.yatraCharges && Boolean(formik.errors.yatraCharges)}
                helperText={formik.touched.yatraCharges && formik.errors.yatraCharges}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              {/* Registration Charges */}
              <TextField
                id="registrationCharges-label"
                label="Registration Charges *"
                variant="outlined"
                fullWidth
                {...formik.getFieldProps('registrationCharges')}
                error={formik.touched.registrationCharges && Boolean(formik.errors.registrationCharges)}
                helperText={formik.touched.registrationCharges && formik.errors.registrationCharges}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              {/* Late Fees */}
              <TextField
                id="lateFees-label"
                label="Late Fees *"
                variant="outlined"
                fullWidth
                {...formik.getFieldProps('lateFees')}
                error={formik.touched.lateFees && Boolean(formik.errors.lateFees)}
                helperText={formik.touched.lateFees && formik.errors.lateFees}
                disabled={true}
              />
            </Grid>
          </Grid>

        </Box>
        <Divider sx={{ mt: 2 }} />
        <FormHelperText style={{ fontSize: '0.8rem', color: 'blue' }}>
          Your final bill may come from {registrationDTO.approxBill} based on your current selection.<br />
          Registration amount will be deducted from your final bill excluding GST and convenience fees.<br />
        </FormHelperText>
      </div>
      <Divider />
      <div className="registration-form-content-actions">
        <Button variant="contained" onClick={handlePrev} sx={{ mt: 1 }} startIcon={<KeyboardArrowLeftIcon className='xyz-white-icon' sx={{ fontSize: isSmallScreen ? 'small' : 'inherit' }} />}>
          {isSmallScreen ? <span style={{ fontSize: '8px' }}>Back</span> : 'Back'}
        </Button>
        <Button variant="contained" onClick={handleReset} sx={{ mt: 1 }} >
          <ClearIcon className='xyz-white-icon' sx={{ fontSize: isSmallScreen ? 'small' : 'inherit', mr: 1 }} />
          {isSmallScreen ? <span style={{ fontSize: '8px' }}>Reset</span> : 'Reset'}
        </Button>
        <Button variant="contained" onClick={handleAddUpdateMember} sx={{ mt: 1 }}>
          {isEditing ? <UpdateIcon className='xyz-white-icon' sx={{ mr: 1 }} /> : <AddBox className='xyz-white-icon' sx={{ mr: 1 }} />}
          {isSmallScreen ? <span style={{ fontSize: '8px' }}>{isEditing ? 'Update Member' : 'Add Member'}</span> : isEditing ? 'Update Member' : 'Add Member'}
        </Button>

        {/* Next button */}
        <Tooltip
          title={
            formik.dirty ? (isEditing ? "Please save changes using 'Update member', or click 'Reset'." : "Please save changes using 'Add member', or click 'Reset'.") :
            registrationDTO.members.length === 0 ? "Please add at least one member." :
                !registrationDTO.members.some(member => member.age > 5) ? "We expect at least one accompanying parent." : ""
          }
          open={open}
          onClose={() => setOpen(false)}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <span onClick={handleSubmit} style={{ cursor: 'pointer' }}> {/* Make the span clickable */}
            <Button
              variant="contained"
              sx={{ mt: 1 }}
              endIcon={<KeyboardArrowRightIcon className='xyz-white-icon' />}
              disabled={formik.dirty || registrationDTO.members.length === 0 || !registrationDTO.members.some(member => member.age > 5)}
            >
              {isSmallScreen ? (
                <span style={{ fontSize: '8px' }}>Next</span>
              ) : (
                'Next'
              )}
              {/* <HelpOutlineIcon fontSize="small" style={{ marginLeft: '4px' }} className='xyz-white-icon' /> */}
            </Button>
          </span>
        </Tooltip>
      </div>

    </>
  );
};

export default MemberDetailsForm;

import React from 'react';

import styles from './LegalResponsibility.module.scss';

function LegalResponsibility() {

  return (
    <div className={styles.legalResponsibilityMain}>
      <h3 className={styles.heading}>Legal Responsibilities</h3>

      <div className={styles.parentSection}>
        {/* <h5 className={styles.sectionHeading}>Payments</h5> */}
        <p>
          Unless <strong>Dham Yatra LLP</strong> explicitly acts as a reseller in certain scenarios, <strong>Dham Yatra LLP</strong> always acts as a facilitator by connecting the User with the respective service providers like hotels, restaurants, bus operators etc. (collectively referred to as "Service Providers"). <strong>Dham Yatra LLP</strong> 's liability is limited to providing the User with a confirmed booking as selected by the User.
        </p>
        <p>
          Any issues or concerns faced by the User at the time of availing any such services shall be the sole responsibility of the Service Provider. <strong>Dham Yatra LLP</strong> will have no liability with respect to the acts, omissions, errors, representations, warranties, breaches or negligence on part of any Service Provider.
        </p>
        <p>
          <p>1) Unless explicitly committed by <strong>Dham Yatra LLP</strong> as a part of any product or service:</p>
          <p>2) <strong>Dham Yatra LLP</strong> assumes no liability for the standard of services as provided by the respective Service Providers.</p>
          <p>3) <strong>Dham Yatra LLP</strong> provides no guarantee with regard to their quality or fitness as represented.</p>
          <p>4) <strong>Dham Yatra LLP</strong> doesn't guarantee the availability of any services as listed by a Service Provider.</p>
        </p>
        <p>
          By making a booking, User understands <strong>Dham Yatra LLP</strong> merely provides a platform for booking of services and products and the ultimate liability rests on the respective Service Provider and not <strong>Dham Yatra LLP</strong>. Thus the ultimate contract of service is between User and Service Provider.
        </p>
      </div>

    </div>
  );

}

export default LegalResponsibility;
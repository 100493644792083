import React from 'react';

import styles from './HealthRecommendation.module.scss';

function HealthRecommendation() {

  return (
    <div className={styles.healthRecommendationMain}>
      <h3 className={styles.heading}>Health recommendation</h3>

      <div className={styles.parentSection}>
        <p>
          Dear Devotees,
        </p>
        <p>Please accept our humble Obeisances, All glories to Srila Prabhupada, All glories to Sri Guru & Gauranga!</p>
        <p>As we all know, we will be visiting the Holy Badrinath Dham later this year (2024).</p>
        <p>We would like to bring to your attention that this Dham is in a <strong>High Altitude Area</strong> situated at an altitude of 10,000 feet, where there will be a lower atmospheric pressures and reduced oxygen levels.</p>
        <p>Due to the altitude, one may encounter some health issues.  The majority of the population is able to tolerate and get acclimatised especially when the ascent is gradual, but still one may encounter minor symptoms like headache, dizziness, nausea, vomiting etc.</p>
        <p>It is important to note that lower atmospheric pressures and oxygen levels may predispose some people  with pre-existing  heart  or  lung or circulation problems to develop  some complications. </p>
        <p>There are some medications available that could be taken to help reduce the complications related to high altitude.  These need to be started a few days before travel and are very helpful, but should be started only after consultation with a Physician. </p>
        <p>
          <strong>
            Here are our recommendations -
          </strong>
          <ol>
            <li>
              Devotees who are 50 years or above should take SPECIAL CARE, including considering MEDICAL SCREENING and/or SEEKING A PHYSICIAN's advice prior to the yatra.
            </li>
            <li>
              We also advise all the devotees to minimally consult a physician before taking a decision to go uphill.
            </li>
          </ol>
        </p>
        <p>
          <strong>
            Those over 50 years of age are recommended to consider undergoing the following tests, based on your physician’s advice -
          </strong>
          <ol>
            <li>Complete Blood Count (CBC)</li>
            <li>Blood Cholesterol Levels</li>
            <li>Blood Sugar (Pre and Post)</li>
            <li>BP chart for 1 week (Morning and evening)</li>
            <li>Resting Heart Rate chart of 1 week</li>
            <li>Oxygen Saturation at Rest and after exercise</li>
            <li>ECG or EKG</li>
            <li>Pulmonary Function Tests</li>
            <li><strong>Devotees above 60 are recommended to get a TMT STRESS TEST</strong></li>
          </ol>
        </p>

        <p>
          <strong>
            Those with conditions below are particularly advised to take extra cautionary measures -
          </strong>
          <ol>
            <li>
              <strong>Heart problems:</strong>
              <ol style={{ listStyle: 'lower-alpha' }}>
                <li>heart attack within the last 3 months, stroke, ICD implantation, thromboembolic event</li>
                <li>Unstable angina pectoris</li>
                <li>Before planned coronary interventions.</li>
                <li>Heart failure</li>
                <li>Congenital cyanotic or severe acyanotic heart defect</li>
                <li>Recent stent procedure  </li>
              </ol>
            </li>
            <li>
              <strong>Lung problems:</strong>
              <ol style={{ listStyle: 'lower-alpha' }}>
                <li>Pulmonary arterial hypertension</li>
                <li>COPD</li>
                <li>Poorly controlled asthma</li>
              </ol>
            </li>
            <li><strong>Poorly controlled diabetes</strong></li>
            <li><strong>BP problems:</strong> High or low or poorly controlled </li>
            <li><strong>Low Platelet Count or Bleeding Disorder</strong></li>
            <li><strong>Patients with low Oxygen saturation</strong></li>
            <li><strong>Raynaud’s disease</strong></li>
          </ol>
        </p>
        <p>
          In conclusion, the yatra team recommends that you please discuss with your health care professional and come up with a plan prior to travel.  We would like for everyone to have a blissful experience at the Holy Badrinath Dham without any medical complications.
        </p>
        <p>
          Please also make note of the fact that <strong>there is only one hospital in Badrinath town with limited bed capacities.</strong>
        </p>
        <p>
          Please remember to carry all your prescription medicines as the Yatra Team would not be able to carry a substantial reserve of medications looking at the challenging journey uphill to Badrinath.
        </p>
        <p>
          Because of the limited medical facilities, the yatra team is also not in a position to take any responsibility with regards to health risks at Badrinath.
        </p>
        <br />
        <br />
        <p>
          Thank you!<br />
          Yatra Organizing Team
        </p>
      </div>
    </div>
  );

}

export default HealthRecommendation;
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Paper, Typography, createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect, useState } from 'react';
import ApiRequest from 'services/api.service';
import dayjs from 'dayjs';
import { formatToIndianRupeeFromPaise } from 'services/utils';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2196f3',
        },
        secondary: {
            main: '#f50057',
        },
    },
});


const SettlementRecordTable = ({ defaultExpanded }) => {
    const [expanded, setExpanded] = useState(defaultExpanded && true);
    const [settlementReports, setSettlementReports] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));

    useEffect(() => {
        ApiRequest.handleGetApiRequest('/accounts/settlement/report', { date: date }).then((response) => {
            setSettlementReports(response);
            setIsLoading(false);
        });
    }, [date]);

    if (isLoading) {
        return (
            <div>Loading</div>
        )
    }

    const handleDateChange = (newValue) => {
        // Format the date in local time zone instead of converting it to UTC
        const formattedDate = newValue ? newValue.format('YYYY-MM-DD') : '';
        setDate(formattedDate)
    };

    return (
        <Container maxWidth="md" sx={{ marginTop: 4, overflow: 'auto' }}>
            <div className="summary-container">
                <div className="rps-flex" style={{ padding: "1em 0em" }}>
                    <Typography variant="h4">
                        Settlements for the day
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb" className="mb-4">
                        <DatePicker
                            label="Select the date *"
                            sx={{ width: '100%' }}
                            value={date ? dayjs(date, 'YYYY-MM-DD') : null}
                            onChange={(newValue) => handleDateChange(newValue)}
                        />
                    </LocalizationProvider>
                </div>


                <div id="registration-form-table">
                    {settlementReports.map((settlement, index) => (
                        <Accordion key='acc_registratoin_financial_summary' sx={{ mb: 2 }} expanded={expanded} onChange={() => setExpanded(!expanded)}>
                            <AccordionSummary key={'acc_registratoin_financial_summary'} expandIcon={<ExpandMoreIcon />}>
                                <div style={{ width: '50%' }}>
                                    <div className="user-details-row">
                                        <div className="user-details">
                                            <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>{settlement.entity_id}</span>
                                                <span>{settlement.payment_id}</span>
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails key='acc__detailsregistratoin_financial_summary'>
                                <ThemeProvider theme={theme}>
                                    <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                                        <Box key={index} mb={1}>
                                            {/* Amount related */}
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Amount</span>
                                                <span>{formatToIndianRupeeFromPaise(settlement.amount)}</span>
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Fee</span>
                                                <span>{formatToIndianRupeeFromPaise(settlement.fee)}</span>
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Tax</span>
                                                <span>{formatToIndianRupeeFromPaise(settlement.tax)}</span>
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Debit</span>
                                                <span>{formatToIndianRupeeFromPaise(settlement.debit)}</span>
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Credit</span>
                                                <span>{formatToIndianRupeeFromPaise(settlement.credit)}</span>
                                            </Typography>

                                            {/* Receipts */}
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Order_receipt</span>
                                                <span>{settlement.order_receipt}</span>
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Entity_id</span>
                                                <span>{settlement.entity_id}</span>
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Order_id</span>
                                                <span>{settlement.order_id}</span>
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Payment_id</span>
                                                <span>{settlement.payment_id}</span>
                                            </Typography>

                                            {/* Setttlement related */}
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Settlement_id</span>
                                                <span>{settlement.settlement_id}</span>
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Settlement_utr</span>
                                                <span>{settlement.settlement_utr}</span>
                                            </Typography>

                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Description</span>
                                                <span>{settlement.description}</span>
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Type</span>
                                                <span>{settlement.type}</span>
                                            </Typography>

                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Card_network</span>
                                                <span>{settlement.card_network}</span>
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Card_issuer</span>
                                                <span>{settlement.card_issuer}</span>
                                            </Typography>
                                            
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Currency</span>
                                                <span>{settlement.currency}</span>
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Method</span>
                                                <span>{settlement.method}</span>
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Settled</span>
                                                <span>{settlement.settled}</span>
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>On_hold</span>
                                                <span>{settlement.on_hold}</span>
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Credit_type</span>
                                                <span>{settlement.credit_type}</span>
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Card_type</span>
                                                <span>{settlement.card_type}</span>
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Dispute_id</span>
                                                <span>{settlement.dispute_id}</span>
                                            </Typography>

                                            {/* Time related fields */}
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Created_at</span>
                                                <span>{settlement.created_at}</span>
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Settled_at</span>
                                                <span>{settlement.settled_at}</span>
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Posted_at</span>
                                                <span>{settlement.posted_at}</span>
                                            </Typography>
                                            {/* Personal information */}
                                            {Object.entries(settlement.notes).map(([key, value]) => (
                                                <Typography key={key} variant="body2" color="TEXTSECONDARY" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <span>{key}</span>
                                                    <span>{value}</span>
                                                </Typography>
                                            ))}
                                        </Box>
                                    </Paper>
                                </ThemeProvider>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            </div>
        </Container>
    );
}

export default SettlementRecordTable;

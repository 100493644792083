import React, { useState, useEffect } from 'react';

import { IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link, useNavigate } from 'react-router-dom';

import Sidebar from './sidebar/Sidebar';

import styles from './TopNavigationBar.module.scss';
import LoginService from 'services/login.service';

function TopNavigationBar(props) {

  const { loginStatus, logOut, isAdmin, isAccountsPermission } = props;
  const [displaySidebar, setDisplaySidebar] = useState(false);
  const [profileAnchorElement, setProfileAnchorElement] = React.useState(null);

  const open = Boolean(profileAnchorElement);

  const navigate = useNavigate();

  function handleAvatarClick(event) {
    setProfileAnchorElement(event.currentTarget);
  }

  function renderSidebar() {

    if (displaySidebar === false) {
      return;
    }

    const sidebarAttributes = {
      loginStatus,
      isAdmin,
      isAccountsPermission,
      onClose() {
        setDisplaySidebar(false);
      }
    };

    return <Sidebar {...sidebarAttributes} />;
  }

  function renderNavigationControls() {

    if (loginStatus) {

      const userProfileAvatarAttributes = {
        className: styles.menuControl,
        onClick: handleAvatarClick
      };

      return (
        <IconButton {...userProfileAvatarAttributes}>
          <AccountCircleIcon />
        </IconButton>
      );
    }

    const loginLinkControlAttributes = {
      to: '/login',
      className: styles.loginSignupLink
    };

    const signupLinkControlAttributes = {
      to: '/signup',
      className: styles.loginSignupLink
    };

    return (
      <div className={styles.loginSignupControls}>
        <Link {...loginLinkControlAttributes}>Login</Link>
        <Link {...signupLinkControlAttributes}>Signup</Link>
      </div>
    );
  }

  function renderProfileMenuOptions() {

    const menuAttributes = {
      anchorEl: profileAnchorElement,
      open,
      onClose() {
        setProfileAnchorElement(null);
      }
    };

    const logoutControlAttributes = {
      onClick() {
        logOut();
        setProfileAnchorElement(null);
      }
    };

    return (
      <Menu {...menuAttributes}>
        <MenuItem {...logoutControlAttributes}>Logout</MenuItem>
      </Menu>
    );

  }

  const dhamYatraLabelControlAttributes = {
    className: styles.logoSectionLabel,
    onClick() {
      navigate('/');
    }
  };

  const menuControlAttributes = {
    className: styles.menuControl,
    onClick() {
      setDisplaySidebar((_displaySidebar) => !_displaySidebar);
    }
  };

  return (
    <div className={styles.topNavigationBartMain}>

      <div className={styles.logoSection}>
        <IconButton {...menuControlAttributes}>
          <MenuIcon />
        </IconButton>
        <label {...dhamYatraLabelControlAttributes}>Dham Yatra</label>
      </div>

      {renderNavigationControls()}
      {renderSidebar()}
      {renderProfileMenuOptions()}

    </div>
  );

}

export default TopNavigationBar;
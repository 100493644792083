import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Button, TextField, Box, Grid, Typography, CircularProgress, Alert, InputAdornment, IconButton } from '@mui/material';
import { LockOutlined as LockOutlinedIcon, Visibility, VisibilityOff } from '@mui/icons-material';
import { useFormik } from 'formik';
import { Link as MuiLink } from '@mui/material';
import * as Yup from 'yup';
import LoginService from '../../services/login.service';

const Login = ({ setLoginStatus, setIsAdmin, setIsAccountsPermission }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Validation Schema
  const validationSchema = Yup.object({
    email: Yup.string().email('Enter a valid email').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  // Formik setup
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setMessage('');
      try {
        const result = await LoginService.handleLogin(values.email, values.password);
        if (result.success) {
          setLoginStatus(true);
          setIsAdmin(LoginService.isAdmin())
          setIsAccountsPermission(LoginService.isAccountsPermission())
          navigate('/'); // Adjust according to your app's needs
        } else {
          setMessage(result.message);
          setLoading(false);
        }
      } catch (error) {
        console.error('Login error:', error);
        setMessage('Failed to login. Please try again later.');
        setLoading(false);
      }
    },
  });

  return (
    <Box sx={{ flexGrow: 1, mt: 2, mx: 'auto', width: '75%', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Login
      </Typography>
      <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'} // Toggle between text and password
          id="password"
          autoComplete="current-password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          InputProps={{ // Add this prop to the TextField
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handlePasswordVisibility}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <MuiLink
          component="button"
          variant="body2"
          type="button"
          onClick={(event) => {
            event.preventDefault(); // Prevent the default form submit action
            navigate('/reset-password'); // Adjust the path as needed
          }}
          sx={{ my: 2 }}
        >
          Forgot password?
        </MuiLink>
        <div className="form-group">
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Sign In'}
          </Button>
        </div>
        {message && <Alert severity="error" sx={{ width: '100%' }}>{message}</Alert>}
      </Box>
    </Box>
  );
};

export default Login;

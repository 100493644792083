import React from 'react';

import { Link } from 'react-router-dom';

import { footerNavigationLinksControls, socialMediaLinks } from 'constants/jsons/footer-controls';

import styles from './Footer.module.scss';

function Footer() {

  function renderNavigationLinkControl(item, index) {

    const linkControlAttributes = {
      key: index,
      to: item.link,
      className: styles.navigationLink
    };

    return <Link {...linkControlAttributes}>{item.label}</Link>
  }

  function renderNavigationLinkControls() {

    return (
      <div className={styles.navigationLinkControls}>
        {
          footerNavigationLinksControls.map((item, index) => (
            renderNavigationLinkControl(item, index)
          ))
        }
      </div>
    );

  }

  function renderSocialMediaLink(item, index) {

    const socialMediaLinkAttributes = {
      href: item.href,
      className: styles.socialMediaLink,
      target: '_blank'
    };

    return (
      <a {...socialMediaLinkAttributes}>
        <item.icon className={styles.icon} />
      </a>
    );
  }

  function renderSocialMediaLinks() {

    return (
      <div className={styles.socialMediaLinks}>
        {
          socialMediaLinks.map((item, index) => (
            renderSocialMediaLink(item, index)
          ))
        }
      </div>
    );
  }

  return (
    <div className={styles.footerMain}>
      {renderNavigationLinkControls()}
      {renderSocialMediaLinks()}
    </div>
  );

}

export default Footer;
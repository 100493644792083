import React from 'react';

import styles from './PrivacyPolicy.module.scss';

function PrivacyPolicy() {

  return (
    <div className={styles.privacyPolicyMain}>
      <h3 className={styles.heading}>Your Information & Privacy</h3>

      <div className={styles.parentSection}>
        <p>
          <strong>PRIVACY POLICY:</strong>
          When you make a booking, we require certain personal information. When you use our website sign up for our newsletter, request a catalog, or interact with us in other ways, we may collect additional or other information, as described in our Privacy Policy. By using our website, booking travel with us, or submitting any personal information to us, you indicate your acceptance of our Privacy Policy.
        </p>
        <p>
          <strong>REQUIRED INFORMATION:</strong>
          To make a booking, we require that you supply certain personal information, including the full legal name, gender, birthdate, and passport information for each traveler, as required by law and/or reasonably required to enable us and our suppliers to provide your purchased travel products and services. We also require contact information, including address, phone number, and email address, from the person making the booking. We request a phone number and email address for all other travelers, except for minors and family members who live together at the same address, so that we may reach you and your travel companions in case of last minute itinerary changes or emergencies. For each traveler, we also require the name and contact information of a designated emergency contact person who is not traveling. You are responsible for verifying that any information you provide for yourself and on behalf of other travelers on your booking is complete and accurate. Under no circumstances will we be liable for any errors or omissions in the information you have provided to us, or any fees or damages related to such errors or omissions.
        </p>
        <p>
          <strong>IMAGES & MARKETING:</strong>
          While participating in any of our packages, images, photos, or videos may be taken by us, our representatives or contractors, or other participants that may contain or feature you. You consent to any such pictures being taken and grant a perpetual, royalty-free, worldwide, irrevocable license to us, our contractors, sub-contractors and assigns, to reproduce for any purpose whatsoever (including marketing, promotions and the creation of promotional materials by or with sub-licensees), in any medium whatsoever, whether currently known or hereinafter devised, without any further obligation or compensation payable to you.
        </p>
      </div>

    </div>
  );

}

export default PrivacyPolicy;
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, CssBaseline, Typography, createTheme } from '@mui/material';
import { ThemeProvider, makeStyles } from '@mui/styles';
import SponsorshipItem from './SponsorshipItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2196f3',
        },
        secondary: {
            main: '#f50057',
        },
    },
    typography: {
        fontFamily: 'Roboto', // Set your preferred font family
        htmlFontSize: 16, // Set the base font size
        body1: {
            fontSize: '1rem', // Default font size for body text
            lineHeight: 1.5, // Default line height for body text
        },
        body2: {
            fontSize: '0.875rem', // Default font size for secondary text (e.g., helper text)
            lineHeight: 1.5, // Default line height for secondary text
            lang: 'en', // Default language for all Typography components
        },
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '1rem',
        maxWidth: 'md', // Set maximum width based on the theme's breakpoints
        margin: 'auto', // Center the content horizontally
    },
    card: {
        // marginBottom: theme.spacing(2),
        padding: '0rem'
    },
    heading: {
        fontSize: theme.typography?.fontSize || '1rem',
        fontWeight: theme.typography?.fontWeightRegular || 'normal',
    },
    comment: {
        fontStyle: 'italic',
    },
    item: {
        padding: '0.2rem'
    },
    emoji: {
        fontSize: '1.5rem', // Adjust the size of the emoji here
        marginRight: '0.5rem', // Add some spacing between the emoji and the text
    },
}));

const SponsorshipPage = ({setTabIndex}) => {

    const sponsorshipItems = [
        { id: 1, type: "BREAKFAST_PRASADAM", title: "Breakfast Prasadam", cost: "Rs. 200/- per person per day", total: "Rs. 1,20,000/- ($1435) per day for 600 devotees", amount: 120000, counts: 1 },
        { id: 2, type: "LUNCH_PRASADAM", title: "Lunch Prasadam", cost: "Rs. 300/- per person per day", total: "Rs. 1,80,000/- ($2150) per day for 600 devotees", amount: 180000, counts: 1 },
        { id: 3, type: "DINNER_PRASADAM", title: "Dinner Prasadam", cost: "Rs. 200/- per person per day", total: "Rs. 1,20,000/- ($1435) per day for 600 devotees", amount: 120000, counts: 1 },
        { id: 4, type: "CAR", title: "Maharaja's Car expenses", cost: "Rs 51,000/- ($601) entire yatra", total: "Rs 51,000/- ($601)", amount: 51000, counts: 1 },
        { id: 5, type: "HH_RPS_MAHARAJA_ACCOMODATION", title: "HH Romapada Maharaja's Accommodation expenses", cost: "Rs. 85,000/- ($1001) for entire yatra", total: "Rs. 85,000/- ($1001)", amount: 85000, counts: 1 },
        { id: 6, type: "HH_BC_MAHARAJA_ACCOMODATION", title: "HH Bhakti Caitanya Maharaja's Accommodation expenses", cost: "Rs. 63,000/- ($751) for entire yatra", total: "Rs. 63,000/- ($751)", amount: 63000, counts: 1 },
        { id: 7, type: "COOKING", title: "Both Maharaja's Cooking expenses", cost: "Rs. 10,000/- ($120) per day ", total: "Rs. 1,00,000/- ($1201)", amount: 100000, counts: 10 },
        { id: 8, type: "BANQUET_HALL", title: "Haridwar Banquet Hall & Lawn Charges", cost: "Rs. 41,000/- ($500) per day", total: "Rs. 2,05,000/- ($2500) for 5 days", amount: 205000, counts: 5 },
        { id: 9, type: "SHUKRATAL_HALL", title: "Shukratal Hall & Charges", cost: "Rs. 35,000/- ($411) for one day", total: "Rs. 35,000/- ($411) for one day", amount: 35000, counts: 1 },
        { id: 10, type: "BADRINATH_HALL", title: "Badrinath Hall & Pandal charges", cost: "Rs. 1,67,000/- ($2000) per day", total: "Rs. 5,01,000/- ($6000) for 3 days", amount: 501000, counts: 3 },
        { id: 11, type: "HELICOPTER_ONWARD", title: "Charter Helicopter for both Maharaja's from Haridwar to Badrinath", cost: "Rs. 4,50,000 ($5,400)", total: "Rs. 4,50,000/- ($5,400)", amount: 450000, counts: 1 },
        { id: 12, type: "HELICOPTER_RETURN", title: "Charter Helicopter for both Maharaja's from Badrinath to Haridwar", cost: "Rs. 4,50,000 ($5,400)", total: "Rs. 4,50,000/- ($5,400)", amount: 450000, counts: 1 },
        { id: 13, type: "GARLAND", title: "Garland expenses for both Guru Maharaja & deities", cost: "Rs 40,000/- ($501) for entire yatra", total: "Rs 40,000/- ($501)", amount: 40000, counts: 1 },
        { id: 14, type: "MISC", title: "Miscellaneous Expenditures", cost: "Rs. 50,000/- ($600)", total: "Rs. 50,000/- ($600) for entire yatra", amount: 50000, counts: 1 },
        { id: 15, type: "INITIATION", title: "Initiation Expenditure", cost: "Rs. 25,000/- ($301)", total: "Rs. 25,000/- ($301)", amount: 25000, counts: 1 },
        { id: 16, type: "SOUND_SYSTEM", title: "Sound System", cost: "Rs. 8000/- ($101) per day", total: "Rs. 80,000/- ($1001) for 10 days", amount: 80000, counts: 10 },
        { id: 17, type: "DECORATION", title: "Banquet Hall Decoration Charges", cost: "Rs. 5000/- ($60) per day", total: "Rs. 50,000/- ($601) for 10 days", amount: 50000, counts: 10 },
        { id: 18, type: "SPONSOR_A_DEVOTEE", title: "Sponsor a devotee", cost: "Rs. 21,000/- ($251) per devotee", total: "Rs. 3,15,000/- ($3766) for 15 devotees", amount: 315000, counts: 15 },
        { id: 19, type: "EVIAN_WATER", title: "Evian water for Maharaja drinking & cooking", cost: "Rs. 3,000/- ($35) per day", total: "Rs. 45,000/- ($540) for 15 days", amount: 45000, counts: 15 },
        { id: 20, type: "BISLERI", title: "Bisleri Drinking Water for devotees during class & prasadam time", cost: "Rs. 5,000/- ($60) per day", total: "Rs. 50,000/- ($601) for 10 days", amount: 50000, counts: 10 },
        { id: 21, type: "BG", title: "Sponsor a Bhagavad Gita to be kept in hotel Rooms", cost: "Rs. 350 ($5) per book", total: "Rs. 1,75,000/- ($2101) for 500 Bhagavad Gita", amount: 175000, counts: 500 }
    ];

    const classes = useStyles();

    return (
        <ThemeProvider theme={theme} >
            <div className={classes.root}>

                <Container maxWidth="md" sx={{ marginTop: 4, overflow: 'auto' }}>
                    <div className="summary-container">
                        <CssBaseline />
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'left',
                            }}
                        >
                            <Typography component="h1" variant="h4" gutterBottom>
                                Haridwar & Badrinath Yatra 2024 Sponsorship
                            </Typography>
                            <Typography variant="body1" paragraph>
                                <p>
                                    Dear Prabhujis and Matajis, <br />
                                    Hare Krishna. Please accept our humble obeisances. All glories to Guru Maharaja and Srila Prabhupada.
                                </p>
                            </Typography>
                            <Typography variant="body1" paragraph>
                                <p>
                                    We are in the process of completing arrangements for the upcoming Haridwar & Badrinath Yatra 2024 along with HH Romapada Maharaja & HH Bhakti Caitanya Maharaja.
                                </p>
                            </Typography>

                            <Typography variant="body1" paragraph>
                                <p>
                                    In the past we have had some devotees approaching the organizers & area coordinators with a wish to sponsor a meal to feed the vaisnavas on a particular day, 
                                    or otherwise to cover some portion of the yatra fees. 
                                    Many devotees came forward & gave sponsorship which were useful in reducing the overall yatra fees. 
                                    We are very grateful to all the devotees who sponsored.
                                </p>
                            </Typography>

                            <Typography variant="body1" paragraph>
                                <p>
                                    Accordingly we are providing you here the itemized costs for prasadam & other expenses.
                                    This is not being done merely as an appeal to you to step forward and help us cover expenses.
                                    It is for your edification, if such an inspiration arises.
                                </p>
                            </Typography>
                            <Box sx={{ width: '100%' }}>
                                {/* {sponsorshipItems.map(item => (
                                    <SponsorshipItem key={item.id} item={item} />
                                ))} */}
                                {sponsorshipItems.map(item => (
                                    <Accordion key={item.id}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`panel${item.id}-content`}
                                            id={`panel${item.id}-header`}
                                        >
                                            <Typography className={classes.heading}> {item.id} - {item.title}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <SponsorshipItem item={item} setTabIndex={setTabIndex}/>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </Box>
                            <Box mt={4} />
                            <Typography variant="body1" paragraph>
                                We request you to kindly confirm your sponsorship before <b>1st August 2024.</b>
                                This will help us to decide on any reduction that is possible in the yatra cost per devotee based on the total sponsorship amount received.
                                Accordingly, registration fees will reflect a more accurate amount for overall expenditures.
                                This time we will be completing the entire registration process in advance by <b>August 15th 2024.</b>
                                Therefore your confirmation of sponsorship by 1st August 2024 will be most helpful.
                            </Typography>

                            <Typography variant="body1" paragraph>
                                If you desire to assist with sponsoring prasadam & other expenses cost in full or part, please contact your Area coordinator or us.
                            </Typography>

                            <Typography variant="body1" paragraph>
                                Fees per attendee (all of the above plus bus, etc), other than your accommodation costs, will be sent to you soon.
                            </Typography>

                            <Typography variant="body1" paragraph>
                                Sponsorship amount can be remitted to your respective area coordinators or upon your arrival for the yatra.
                                In case some of you wish to confirm the sponsorship after 1st August 2024, you are still welcome to do so.
                                In other words we will receive the sponsorship right up to the end of yatra.
                            </Typography>

                            <Typography variant="body1" paragraph>
                            <b>Note : Special Badrinath Silver coins souvenir will be given by maharaja to all sponsors above Rs. 20,000</b>
                            </Typography>

                            <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                Thanking you.<br />
                                Your servant,<br />
                                Padmanabha Das & Guru Das<br />
                            </Typography>
                        </Box>
                    </div >
                </Container>
            </div>
        </ThemeProvider>
    );
}

export default SponsorshipPage;

import { Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect } from 'react';
import ApiRequest from 'services/api.service';
import { useNavigate } from 'react-router-dom';

const PaymentButton = ({ regId, amount, from, handlePostPaymentAction, isSponsor = false, comments = "", sponsorshipType = "" }) => {

    const isSmallScreen = useMediaQuery('(max-width:600px)');

    // Added extra charge for any transaction if not a sponsor
    const amountToPay = isSponsor ? Math.ceil(amount) : Math.ceil(amount * 1.07);

    const navigate = useNavigate();

    useEffect(() => {
        // Dynamically load the Razorpay checkout script
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            // Remove the script on component unmount
            document.body.removeChild(script);
        };
    }, []);

    const onPaymentSuccess = async (response, paymentOder) => {
        try {
            // Await the verification result
            response['orderId'] = paymentOder.id
            const isVerified = await ApiRequest.handlePostApiRequest("/payment/verify", response)
            if (isVerified) {
                navigate(from);
                handlePostPaymentAction && handlePostPaymentAction();
            } else {
                alert("Payment verification failed");
            }
        } catch (error) {
            alert("An error occurred during payment verification.")
        }

        console.log("Payment successful!");
        // console.log(response);
        // Here you can navigate to a success page or handle the post-payment logic
    };

    const onPaymentFailure = async (response, paymentOder) => {
        const errorObject = {
            orderId: paymentOder.id,
            paymentId: response.error.metadata.payment_id,
            code: response.error.code,
            description: response.error.description,
            reason: response.error.reason,
            source: response.error.source,
            step: response.error.step
        }

        const errorAdded = await ApiRequest.handlePostApiRequest("/payment/error", errorObject)
        alert(response.error.description)
    }

    const handlePayment = async () => {
        const paymentOrderEndpoint = isSponsor ? "/payment/createSponsorshipOrder" : "/payment/createRegistrationOrder";
        const paymentOrderParams = isSponsor ?
            { comments: comments, paymentMethod: "Sponsorship", sponsorshipType: sponsorshipType, amount: amountToPay }
            : { regId: regId, amount: amountToPay }
        const paymentOrder = await ApiRequest.handlePostApiRequest(paymentOrderEndpoint, paymentOrderParams)
        const options = {
            key: process.env.REACT_APP_RAZORPAY_API_KEY, // Replace with your actual key
            amount: paymentOrder.amount, // Amount is in paise
            currency: paymentOrder.currency,
            name: 'Dhama Yatra',
            description: 'Test Transaction',
            order_id: paymentOrder.id,

            handler: async function (response) {
                onPaymentSuccess(response, paymentOrder);
            },

            prefill: {
                name: paymentOrder.notes.name,
                email: paymentOrder.notes.email,
                contact: paymentOrder.notes.contactNumber,
            },
            notes: {
                address: "Dham Yatra LLP"
            },
            theme: {
                "color": "#c46800"
            }
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', function (response) {
            onPaymentFailure(response, paymentOrder)
        });

        rzp1.open();
    };

    return (
        <>
            <Button variant="contained" onClick={handlePayment}>
                {isSmallScreen ? <span style={{ fontSize: '8px' }}>Proceed to pay {'\u20B9'}{amountToPay}</span> : `Proceed to pay \u20B9${amountToPay}`}
            </Button>
        </>
    );
};

export default PaymentButton;
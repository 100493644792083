import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import AgeStats from './AgeStats';
import DestinationStats from './DestinationStats';
import TransportationStats from './TransportationStats';


function AdminStatistics() {
    const [tabIndex, setTabIndex] = useState(0);
    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    const tabsContainerStyle = {
        display: 'flex',
        justifyContent: 'center', // Center the tabs horizontally
    };

    return (
        <>
            <Box maxWidth='md' sx={tabsContainerStyle}> {/* Added container for tabs */}
                <Tabs value={tabIndex} onChange={handleTabChange}>
                    <Tab label="Age" />
                    <Tab label="Transportation" />
                    <Tab label="Destination" />
                </Tabs>
            </Box>
            <TabPanel value={tabIndex} index={0}>
                <AgeStats />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
                <TransportationStats />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
                <DestinationStats />
            </TabPanel>
        </>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </Box>
    );
}

export default AdminStatistics;

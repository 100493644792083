import React, { useState } from 'react';
import { TextField, Button, Typography, Box, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { AddBox } from '@mui/icons-material';
import UpdateIcon from '@mui/icons-material/Update';

const AreaCoordinatorForm = ({ onAreaCoordinatorsChange }) => {
  const [areaCoordinator, setAreaCoordinator] = useState('');
  const [areaCoordinators, setAreaCoordinators] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [error, setError] = useState('');

  const handleAreaCoordinatorChange = (e) => {
    setAreaCoordinator(e.target.value);
  };

  const handleAddAreaCoordinator = () => {
    if (areaCoordinator.trim() !== '') {
      if (isEditing) {
        const updatedAreaCoordinators = [...areaCoordinators];
        updatedAreaCoordinators[editIndex] = areaCoordinator;
        setAreaCoordinators(updatedAreaCoordinators);
        setIsEditing(false);
        setEditIndex(null);
      } else {
        setAreaCoordinators([...areaCoordinators, areaCoordinator]);
      }
      setAreaCoordinator('');
      setError('');
    } else {
      setError('Area coordinator name cannot be empty.');
    }
  };

  const handleEditAreaCoordinator = (index) => {
    setAreaCoordinator(areaCoordinators[index]);
    setIsEditing(true);
    setEditIndex(index);
  };

  const handleDeleteAreaCoordinator = (index) => {
    const updatedAreaCoordinators = areaCoordinators.filter((ac, i) => i !== index);
    setAreaCoordinators(updatedAreaCoordinators);
  };

  const handleClearError = () => {
    setError('');
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ mt: 2 }}>Area Coordinators</Typography>
      {error && <Typography variant="body2" color="error">{error}</Typography>}
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
          <tbody>
            {areaCoordinators.map((ac, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>{ac}</td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                  <IconButton onClick={() => handleEditAreaCoordinator(index)} aria-label="edit">
                    <EditIcon />
                  </IconButton>
                </td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                  <IconButton onClick={() => handleDeleteAreaCoordinator(index)} aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        {areaCoordinators.map((ac, index) => (
          <Box key={index} display="flex" alignItems="center">
            <Typography>{ac}</Typography>
            <IconButton onClick={() => handleEditAreaCoordinator(index)} aria-label="edit">
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleDeleteAreaCoordinator(index)} aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
      </Box>
      <TextField
        name="areaCoordinator"
        label="Area Coordinator"
        value={areaCoordinator}
        onChange={handleAreaCoordinatorChange}
        error={error !== ''}
        helperText={error}
        onFocus={handleClearError}
      />
      <br />
      <Button variant="contained" onClick={handleAddAreaCoordinator} sx={{ mt: 1 }}>
        {isEditing ? <UpdateIcon sx={{ mr: 1 }} /> : <AddBox sx={{ mr: 1 }} />} {isEditing ? 'Update Area Coordinator' : 'Add Area Coordinator'}
      </Button>
    </Box>
  );
};

export default AreaCoordinatorForm;

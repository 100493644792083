import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useFormik } from 'formik';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useState } from 'react';
import * as Yup from 'yup';
import { countryData, countryCodeData } from '../../constants/jsons/country';
import { ValidationPopup } from '../generics/ValidationPopup';

const UserDetailsForm = ({ configData, registrationDTO, handleCrudInDB, nextStep, prevStep }) => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const userDetails = registrationDTO.userDetails || {};
  const [error, setError] = useState('');
  const [confirmMessage, setConfirmMessage] = useState('')
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupAction, setPopupAction] = useState('error');
  const [popupType, setPopupType] = useState('error');

  const formik = useFormik({
    initialValues: {
      id: userDetails.id || null,
      name: userDetails.name || '',
      initiatedName: userDetails.initiatedName || '',
      email: userDetails.email || '',
      countryCode: userDetails.countryCode || '',
      contactNumber: userDetails.contactNumber || '',
      refTempleName: userDetails.refTempleName || '',
      city: userDetails.city || '',
      country: userDetails.country || '',
      areaCoordinator: userDetails.areaCoordinator || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      initiatedName: Yup.string(),
      email: Yup.string().email('Invalid email format').required('Email is required'),
      countryCode: Yup.string().required('Country code is required')
        .test('validCountryCode', 'Country code is not valid', function (value) {
          return value && countryCodeData.includes(value);
        }),
      contactNumber: Yup.string()
        .required('Contact number is required')
        .test('validPhoneNumber', 'Phone number is Invalid for the country', function (value) {
          const countryCode = this.parent.countryCode;
          try {
            return !countryCode || !value || parsePhoneNumber(countryCode + value).isValid();
          } catch (error) {
            console.log(error);
            return false;
          }
        }),
      refTempleName: Yup.string().required('Temple reference is required'),
      city: Yup.string().required('City is required'),
      country: Yup.string()
        .required('Country is required')
        .test('validCountry', 'Country is not valid', function (value) {
          return value && countryData.includes(value);
        }),
      areaCoordinator: Yup.string()
        .required('Area Coordinator is required')
        .test(
          'not-first-option',
          'We expect you to know at least one Area Coordinator from the list.',
          value => value !== '' && value !== 'Not listed - not seeing any devotee I know'
        ),
    }),
  });

  const handlePrev = async (event) => {
    event.preventDefault();
    if (formik.dirty) {
      setPopupType('confirm');
      setConfirmMessage('We see that you have added some values in the form. If you go back, you will lose them.')
      setIsPopupOpen(true);
      setPopupAction(() => () => {
        setIsPopupOpen(false);
        setPopupType('error');
        prevStep()
      });
      return;
    }
    prevStep()
  }

  const handleSubmit = async (e) => {
    const errors = await formik.validateForm()
    if (Object.keys(errors).length !== 0) {
      formik.setTouched(errors);
      if (Object.keys(errors).length > 1) {
        setIsPopupOpen(true);
        setError("Please fill all the mandatory fields.");
        return;
      }
      return
    }
    // setUserDetails(formik.values);
    handleCrudInDB('userDetails', formik.values)
    nextStep();
  };

  const areaCoordinatorOptions = configData.areaCoordinator || [];

  return (
    <>

      {/* <form onSubmit={handleSubmit} id="user-details-form"> */}
      <div className="page-section">
        <ValidationPopup
          isOpen={isPopupOpen}
          message={popupType === 'error' ? error : confirmMessage}
          type={popupType}
          onConfirm={popupAction}
          onCancel={() => {
            setIsPopupOpen(false);
            setPopupType('error');
            setError('')
          }}
        />
        <Box >
          <div className="rps-flex section-header">
            <h4 className="flex-one">Primary user information</h4>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" error={formik.touched.areaCoordinator && Boolean(formik.errors.areaCoordinator)}>
                <InputLabel htmlFor="areaCoordinator">Area Coordinator *</InputLabel>
                <Select
                  id="areaCoordinator"
                  name="areaCoordinator"
                  label="Area Coordinator *"
                  {...formik.getFieldProps('areaCoordinator')}
                  input={<OutlinedInput label="Area Coordinator *" />}
                >
                  <MenuItem value="">Select Area Coordinator</MenuItem>
                  {areaCoordinatorOptions.map((coordinator) => (
                    <MenuItem key={coordinator} value={coordinator}>
                      {coordinator}
                    </MenuItem>
                  ))}
                </Select>
                {formik.values.areaCoordinator === areaCoordinatorOptions[0] && ( // Check if the first value is selected
                  <FormHelperText>
                    <Typography style={{ color: 'green', fontSize: '0.8rem' }}>
                      Call (+91 96865 78042) if unsure about the area coordinator.
                    </Typography>
                  </FormHelperText>
                )}
                <FormHelperText>{formik.touched.areaCoordinator && formik.errors.areaCoordinator}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="refTempleName"
                name="refTempleName"
                label="Reference Temple Name *"
                fullWidth
                {...formik.getFieldProps('refTempleName')}
                error={formik.touched.refTempleName && Boolean(formik.errors.refTempleName)}
                helperText={formik.touched.refTempleName && formik.errors.refTempleName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="name"
                name="name"
                label="Name *"
                fullWidth
                {...formik.getFieldProps('name')}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>

              <TextField
                id="initiatedName"
                name="initiatedName"
                label="Initiated Name"
                fullWidth
                {...formik.getFieldProps('initiatedName')}
                error={formik.touched.initiatedName && Boolean(formik.errors.initiatedName)}
                helperText={formik.touched.initiatedName && formik.errors.initiatedName}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                id="email"
                name="email"
                label="Email *"
                type="email"
                fullWidth
                {...formik.getFieldProps('email')}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              {/* Country Code */}
              <Autocomplete
                options={countryCodeData}
                getOptionLabel={(option) => option}
                fullWidth
                value={formik.values.countryCode || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country Code *"
                    variant="outlined"
                    {...formik.getFieldProps('countryCode')}
                    error={formik.touched.countryCode && Boolean(formik.errors.countryCode)}
                    helperText={formik.touched.countryCode && formik.errors.countryCode}
                  />
                )}
                onChange={(event, value) => formik.setFieldValue('countryCode', value || '')}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                id="contactNumber"
                name="contactNumber"
                label="Contact Number *"
                fullWidth
                {...formik.getFieldProps('contactNumber')}
                error={formik.touched.contactNumber && Boolean(formik.errors.contactNumber)}
                helperText={formik.touched.contactNumber && formik.errors.contactNumber}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="city"
                name="city"
                label="City *"
                fullWidth
                {...formik.getFieldProps('city')}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* Country */}
              <Autocomplete
                options={countryData}
                fullWidth
                value={formik.values.country || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country *"
                    variant="outlined"
                    {...formik.getFieldProps('country')}
                    error={formik.touched.country && Boolean(formik.errors.country)}
                    helperText={formik.touched.country && formik.errors.country}
                  />
                )}
                onChange={(event, value) => formik.setFieldValue('country', value || '')}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
      <div className="registration-form-content-actions">
        <Button variant="contained" onClick={handlePrev} sx={{ mt: 1 }} startIcon={<KeyboardArrowLeftIcon className='xyz-white-icon' />}>
          {isSmallScreen ? <span style={{ fontSize: '8px' }}>Back</span> : 'Back'}
        </Button>
        <Button variant="contained" onClick={handleSubmit} sx={{ mt: 1 }} endIcon={<KeyboardArrowRightIcon className='xyz-white-icon' />}>
          {isSmallScreen ? <span style={{ fontSize: '8px' }}>Save and proceed</span> : 'Save and proceed'}
        </Button>
      </div>
      {/* </form> */}
    </>
  );
};

export default UserDetailsForm;

import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ApiRequest from 'services/api.service';
import { useState } from 'react';

const validationSchema = Yup.object().shape({
    amountPaid: Yup.number().required('Amount Paid is required'),
    amountAccounted: Yup.number().required('Amount Accounted is required'),
    type: Yup.string().required('Payment Mode is required'),
    transactionId: Yup.string().required('Transaction ID is required'),
    description: Yup.string().required('Description is required'),
});

const initialValues = {
    amountPaid: '',
    amountAccounted: '',
    type: 'BANK',
    transactionId: '',
    description: '',
};

const AddMiscPaymentDialog = ({ openDialog, handleCloseDialog, registration, updateSingleRegistration }) => {

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (values) => {
        setIsSubmitting(true)
        const response = await ApiRequest.handlePostApiRequest("/admin/add/miscPayment", values, { regId: registration.id });
        if (!response) {
            alert("Error while saving the data");
            setIsSubmitting(false)
            return;
        }
        await updateSingleRegistration(response);
        setIsSubmitting(false)
        handleCloseDialog();
    };

    const handleFormChange = (e, setFieldValue, values) => {
        const { name, value } = e.target;
        if(parseFloat(value) <= 0 || values.type === 'CASH') {
            const amountAccounted = Math.floor(value)
            const amountPaid = Math.floor(value)

            setFieldValue('amountAccounted', amountAccounted); // Update form field with the new value
            setFieldValue('amountPaid', amountPaid);
        } else if (name === 'amountAccounted' && parseFloat(value) !== (107 * parseFloat(values.amountPaid)) / 100) {
            const amountAccounted = Math.floor(value) || '';
            const amountPaid = Math.ceil((107 * parseFloat(value)) / 100) || ''

            setFieldValue('amountAccounted', amountAccounted); // Update form field with the new value
            setFieldValue('amountPaid', amountPaid); 
        } else if (name === 'amountPaid' && parseFloat(value) !== (100 * parseFloat(values.amountAccounted)) / 107) {
            const amountAccounted = Math.floor((100 * parseFloat(value)) / 107) || '';
            const amountPaid = Math.ceil(value) || ''

            setFieldValue('amountAccounted', amountAccounted); // Update form field with the new value
            setFieldValue('amountPaid', amountPaid); 
        }
    };

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
          return;
          handleCloseDialog();
      }

    return (
        <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle>Add Misc Payment</DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm }) => {
                        handleSubmit(values);
                        resetForm();
                    }}
                >
                    {({ values, setFieldValue, errors, touched }) => (
                        <Form>
                            <FormControl fullWidth error={touched.type && Boolean(errors.type)}>
                                <InputLabel id="type-label">Payment Mode *</InputLabel>
                                <Field
                                    as={Select}
                                    name="type"
                                    labelId="type-label"
                                    id="type"
                                    error={errors.type && touched.type}
                                >
                                    <MenuItem value="CASH">CASH</MenuItem>
                                    <MenuItem value="BANK">BANK</MenuItem>
                                </Field>
                                <FormHelperText>{touched.type && Boolean(errors.type)}</FormHelperText>
                            </FormControl>
                            
                            <Field
                                as={TextField}
                                name="amountPaid"
                                label="Amount Paid *"
                                type="number"
                                fullWidth
                                onChange={(e) => handleFormChange(e, setFieldValue, values)}
                                // InputProps={{ inputProps: { min: 0 } }}
                                error={errors.amountPaid && touched.amountPaid}
                                helperText={errors.amountPaid && touched.amountPaid && errors.amountPaid}
                            />

                            <Field
                                as={TextField}
                                name="amountAccounted"
                                label="Amount Accounted"
                                type="number"
                                fullWidth
                                onChange={(e) => handleFormChange(e, setFieldValue, values)}
                                // InputProps={{ inputProps: { min: 0 } }}
                                error={errors.amountAccounted && touched.amountAccounted}
                                helperText={errors.amountAccounted && touched.amountAccounted && errors.amountAccounted}
                            />

                            <Field
                                as={TextField}
                                name="transactionId"
                                label="Transaction ID *"
                                fullWidth
                                error={errors.transactionId && touched.transactionId}
                                helperText={errors.transactionId && touched.transactionId && errors.transactionId}
                            />

                            <Field
                                as={TextField}
                                name="description"
                                label="Description *"
                                fullWidth
                                error={errors.description && touched.description}
                                helperText={errors.description && touched.description && errors.description}
                            />

                            <DialogActions>
                                <div className="registration-form-content-actions" style={{ 'justifyContent': 'space-around' }}>
                                    <Button variant="contained" onClick={handleCloseDialog} disabled={isSubmitting}>Cancel</Button>
                                    <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>Submit</Button>
                                </div>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default AddMiscPaymentDialog;

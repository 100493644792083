import axios from 'axios';
import LoginService from './login.service';


const handleGetApiRequest = async (uri = {},  quaryParam = {}, checkAuth = true) => {
    let headers;
    if(checkAuth) {
        const token = await LoginService.getAccessToken()
        headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        };
    } else {
        headers = {
            'Content-Type': 'application/json'
        };
    }
    
    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + uri, {
            headers: headers,
            params: quaryParam,
        });
        if(response.data.success) {
            return response.data.result;
        } else {
            window.alert(response.data.message);
        }
    } catch(error) {
        window.alert('Server error : '+ error.message);
    }
};

const handlePostApiRequest = async (uri, data = {}, quaryParam = {},) => {
    const token = await LoginService.getAccessToken()

    try {
        const response = await axios.post(process.env.REACT_APP_API_URL + uri, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            params: quaryParam,
        });
        if(response.data.success) {
            return response.data.result;
        } else {
            window.alert(response.data.message);
        }
    } catch(error) {
        window.alert('Server error : '+ error.message);
    }
};

const handlePutApiRequest = async (uri, data = {}) => {
    const token = await LoginService.getAccessToken()

    try {
        const response = await axios.put(process.env.REACT_APP_API_URL + uri, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        if(response.data.success) {
            return response.data.result;
        } else {
            window.alert(response.data.message);
        }
    } catch(error) {
        window.alert('Server error : '+ error.message);
    }
};

const handleDeleteApiRequest = async (uri, quaryParam = {}, data = []) => {
    const token = await LoginService.getAccessToken()
    
    try {
        const response = await axios.delete(process.env.REACT_APP_API_URL + uri, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            params: quaryParam,
            data: data,
        });
        if(response.data.success) {
            return response.data.result;
        } else {
            window.alert(response.data.message);
        }
    } catch(error) {
        window.alert('Server error : '+ error.message);
    }
};
const handleDownloadRequest = async (uri, queryParam={}) => {
    try {
        // Assuming you need to include authentication or other headers
        const token = await LoginService.getAccessToken();
        
        const response = await axios.get(process.env.REACT_APP_API_URL + uri, {
            responseType: 'blob', // Specify that the response is a binary Blob
            headers: {
                'Authorization': `Bearer ${token}`, // Include token if needed
                'Accept': 'application/octet-stream' // Accept binary data
            },
            params: queryParam
        });

        // Extract filename from the Content-Disposition header if available
        const disposition = response.headers['content-disposition'];
        let filename = 'download.csv'; // Default filename

        if (disposition) {
            const matches = /filename="([^"]*)"/.exec(disposition);
            if (matches != null && matches[1]) { 
                filename = matches[1];
            }
        }

        // Create a Blob from the response data and trigger a download
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        const urlBlob = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = urlBlob;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(urlBlob);
    } catch (error) {
        console.error('Error downloading file:', error);
    }
};

const ApiRequest = {
    handleGetApiRequest,
    handlePostApiRequest,
    handlePutApiRequest,
    handleDeleteApiRequest,
    handleDownloadRequest
}

export default ApiRequest;

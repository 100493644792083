import React, { useState } from 'react';
import { TextField, Button, Typography, Box, IconButton, Card, CardContent } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import { AddBox } from '@mui/icons-material';

const RulesForm = ({ onRulesChange }) => {
  const [rule, setRule] = useState({
    id: null,
    title: '',
    value: ''
  });

  const [isEditing, setIsEditing] = useState(false);
  const [rules, setRules] = useState([]);
  const [error, setError] = useState('');

  const handleRuleChange = (e) => {
    const { name, value } = e.target;
    setRule({
      ...rule,
      [name]: value
    });
  };

  const handleAddRule = () => {
    if (rule.title.trim() !== '' && rule.value.trim() !== '') {
      if (isEditing) {
        const updatedRules = rules.map((r, index) =>
          index === rule.index ? rule : r
        );
        setRules(updatedRules);
        setIsEditing(false);
      } else {
        setRules([...rules, rule]);
      }
      resetRule();
      setError('');
    } else {
      setError('Both Title and Value are required.');
    }
  };

  const handleEditRule = (index) => {
    const editedRule = rules[index];
    setRule({
      ...editedRule,
      index: index // Assigning the index as the id of the rule
    });
    setIsEditing(true);
  };

  const handleDeleteRule = (index) => {
    const updatedRules = rules.filter((r, i) => i !== index);
    setRules(updatedRules);
  };

  const resetRule = () => {
    setRule({
      id: null,
      title: '',
      value: ''
    });
  };

  const handleClearError = () => {
    setError('');
  };

  const handleUpdateRule = () => {
    if (isEditing) {
      handleAddRule();
    }
  };

  return (
    <Box>
      <Typography variant="h6">Rules</Typography>
      {error && <Typography variant="body2" color="error">{error}</Typography>}
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
          <tbody>
            {rules.map((r, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>{r.title}</td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>{r.value}</td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                  <IconButton onClick={() => handleEditRule(index)} aria-label="edit">
                    <EditIcon />
                  </IconButton>
                </td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                  <IconButton onClick={() => handleDeleteRule(index)} aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        {rules.map((r, index) => (
          <Card key={index} sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="subtitle1">{r.title}</Typography>
              <Typography variant="body2">{r.value}</Typography>
              <IconButton onClick={() => handleEditRule(index)} aria-label="edit">
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleDeleteRule(index)} aria-label="delete">
                <DeleteIcon />
              </IconButton>
            </CardContent>
          </Card>
        ))}
      </Box>
      <TextField name="title" label="Title" value={rule.title} onChange={handleRuleChange} />
      <TextField name="value" label="Value" value={rule.value} onChange={handleRuleChange} />
      <br />
      <Button variant="contained" onClick={handleAddRule} sx={{ mt: 1 }}>
        {isEditing ? <UpdateIcon sx={{ mr: 1 }} /> : <AddBox sx={{ mr: 1 }} />} {isEditing ? 'Update Rule' : 'Add Rule'}
      </Button>
    </Box>
  );
};

export default RulesForm;

import { Container, createTheme, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ThemeProvider } from '@mui/styles';
import { useEffect, useState } from 'react';
import ApiRequest from 'services/api.service';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2196f3',
        },
        secondary: {
            main: '#f50057',
        },
    },
});

const SponsorshipStats = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [paymentHistoryDTOs, setPaymentHistoryDTOs] = useState([])

    useEffect(() => {
        ApiRequest.handleGetApiRequest('/payment/sponsorshipStats').then((response) => {
            setPaymentHistoryDTOs(response || [])
            setIsLoading(false);
        });
    }, []);

    if (isLoading) {
        return (
            <div>Loading</div>
        )
    }

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="xl" sx={{ marginTop: 4, overflow: 'auto' }}>
                {/* <Paper elevation={3} sx={{ p: 2, mb: 2 }}> */}
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="sponsorship history table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bold' }}>Sr no</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>Sponsorship Type</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>Pledge</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>Paid</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
                                    
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paymentHistoryDTOs.map((transaction, index) => (
                                    <TableRow key={index} >
                                        <TableCell component="th" scope="row"  sx={{ fontWeight: index == 0 ? 'bold' : 'normal' }}>
                                            {index + 1}
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: index == 0 ? 'bold' : 'normal' }}>{transaction.sponsorshipType}</TableCell>
                                        <TableCell sx={{ fontWeight: index == 0 ? 'bold' : 'normal' }}>{transaction.pledgeAmount}</TableCell>
                                        <TableCell sx={{ fontWeight: index == 0 ? 'bold' : 'normal' }}>{transaction.paidAmount}</TableCell>
                                        <TableCell sx={{ fontWeight: index == 0 ? 'bold' : 'normal' }}>{transaction.totalAmount}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                {/* </Paper> */}
            </Container>
        </ThemeProvider>
    );
};

export default SponsorshipStats;

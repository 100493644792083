import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Container, Typography, Divider } from '@mui/material';
import YatraRoomForm from './YatraRoomForm';
import PreferredLanguagesForm from './PreferredLanguagesForm';
import AreaCoordinatorForm from './AreaCoordinatorForm';
import CountryForm from './CountryForm';
import CountryCodeForm from './CountryCodeForm';
import TransportationDetailsForm from './TransportationDetailsForm';
import PrasadamEstimateForm from './PrasadamEstimateForm';
import RegistrationChargesForm from './RegistrationChargesForm';
import YatraChargesForm from './YatraChargesForm';
import RulesForm from './RulesForm';
import ApiRequest from '../../services/api.service';

const AddUpdateYatra = () => {
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    info: '',
    startDate: '', // Initialize with empty string or null
    endDate: '', // Initialize with empty string or null
    preferredHearingLanguage: [],
    roomChoices: [],
    areaCoordinators: [],
    countryCodes: [],
    countries: [],
    prasadamEstimates: [],
    registrationCharges: [],
    transportationDetails: [],
    yatraCharges: [],
    cancellationCharges: '',
    lateFeesCharges: '',
    lateFeesDate: '',
    rules: {}
  });

  useEffect(() => {
    ApiRequest.handleGetApiRequest('/config').then((response) => {
      const inputData = {
        id: response.id,
        name: response.name,
        info: response.info,
        startDate: '', // Initialize with empty string or null
        endDate: '', // Initialize with empty string or null
        preferredHearingLanguage: [],//response.preferredHearingLanguage,
        roomChoices: [],
        areaCoordinators: [],
        countryCodes: [],
        countries: [],
        prasadamEstimates: [],
        registrationCharges: [],
        transportationDetails: [],
        yatraCharges: [],
        cancellationCharges: '',
        lateFeesCharges: '',
        lateFeesDate: '',
        rules: {}
      }
      setFormData(inputData)
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleArrayChange = (fieldName, fieldValue) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [fieldName]: fieldValue
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Handle form submission, formData will contain all details including room choices
  };

  return (
    <Container maxWidth="md" sx={{ marginTop: 4 }}>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          '& .MuiTextField-root': { mb: 2 },
          maxHeight: '80vh',
          overflowY: 'auto'
        }}
      >
        <Typography variant="h4" mb={2}>Yatra Form</Typography>
        <TextField name="id" label="Yatra ID" value={formData.id} onChange={handleChange} fullWidth />
        <TextField name="name" label="Yatra Name" value={formData.name} onChange={handleChange} fullWidth />
        <TextField name="info" label="Yatra Info" multiline rows={4} value={formData.info} onChange={handleChange} fullWidth />
        <Divider sx={{ my: 2 }} />
        <Typography variant="h5" mb={2}>Yatra Dates</Typography>
        <TextField name="startDate" label="Start Date" type="date" value={formData.startDate} onChange={handleChange} InputLabelProps={{ shrink: true }} required fullWidth />
        <TextField name="endDate" label="End Date" type="date" value={formData.endDate} onChange={handleChange} InputLabelProps={{ shrink: true }} required fullWidth />
        <Divider sx={{ my: 2 }} />
        <Typography variant="h5" mb={2}>Late Fees</Typography>
        <TextField name="lateFeesDate" label="Late Fees Date" type="date" value={formData.lateFeesDate} onChange={handleChange} InputLabelProps={{ shrink: true }} required fullWidth />
        <TextField name="lateFeesCharges" label="Late Fees Charges" type="number" value={formData.lateFeesCharges} onChange={handleChange} required fullWidth />
        <Divider sx={{ my: 2 }} />
        <Typography variant="h5" mb={2}>Cancellation Charges</Typography>
        <TextField name="cancellationCharges" label="Cancellation Charges" type="number" value={formData.cancellationCharges} onChange={handleChange} required fullWidth />
        <Divider sx={{ my: 2 }} />
        <RulesForm onRulesChange={(rules) => handleArrayChange('rules', rules)} />
        <Divider sx={{ my: 2 }} />
        <CountryCodeForm onCountryCodesChange={(countryCodes) => handleArrayChange('countryCodes', countryCodes)} />
        <Divider sx={{ my: 2 }} />
        <CountryForm onCountriesChange={(countries) => handleArrayChange('countries', countries)} />
        <Divider sx={{ my: 2 }} />
        <AreaCoordinatorForm onAreaCoordinatorsChange={(areaCoordinators) => handleArrayChange('areaCoordinators', areaCoordinators)} />
        <Divider sx={{ my: 2 }} />
        <PreferredLanguagesForm initialLanguages={formData.preferredHearingLanguage} onPreferredLanguagesChange={(preferredLanguages) => handleArrayChange('preferredHearingLanguage', preferredLanguages)} />
        <Divider sx={{ my: 2 }} />
        <PrasadamEstimateForm onPrasadamEstimateChange={(prasadamEstimates) => handleArrayChange('prasadamEstimates', prasadamEstimates)} />
        <Divider sx={{ my: 2 }} />
        <TransportationDetailsForm onTransportationChange={(transportationDetails) => handleArrayChange('transportationDetails', transportationDetails)} />
        <Divider sx={{ my: 2 }} />
        <YatraChargesForm onYatraChargesChange={(yatraCharges) => handleArrayChange('yatraCharges', yatraCharges)} />
        <Divider sx={{ my: 2 }} />
        <RegistrationChargesForm onRegistrationChargesChange={(registrationCharges) => handleArrayChange('registrationCharges', registrationCharges)} />
        <Divider sx={{ my: 2 }} />
        <YatraRoomForm onRoomChoicesChange={(roomChoices) => handleArrayChange('roomChoices', roomChoices)} />
        <Divider sx={{ my: 2 }} />
        <Button type="submit" variant="contained" sx={{ mt: 2 }}>Submit</Button>
      </Box>
    </Container>
  );
};

export default AddUpdateYatra;

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import ApiRequest from 'services/api.service';
import * as Yup from 'yup';


const RoomInventoryDialog = ({ openDialog, handleCloseDialog, setRoomInventoryItems, roomInventoryItem, destination, option }) => {

    const [isSubmitting, setIsSubmitting] = useState(false);

    if (!destination || !option) {
        return <></>;
    }

    const initialValues = {
        id: roomInventoryItem.id || null,
        hotelName: roomInventoryItem.hotelName || '',
        bedType: roomInventoryItem.bedType || '',
        address: roomInventoryItem.address || '',
        googleMapAddress: roomInventoryItem.googleMapAddress || '',
        contactNumber: roomInventoryItem.contactNumber || '',
        amenities: roomInventoryItem.amenities || '',
        extraMattressPrice: roomInventoryItem.extraMattressPrice || 0, // Set as number
        pricePerDay: roomInventoryItem.pricePerDay || 0, // Set as number
        numberOfRooms: roomInventoryItem.numberOfRooms || 0, // Set as number

        checkIn: destination.checkIn,
        checkOut: destination.checkOut,

        optionType: option.type,
        displayText: option.type,
        destinationNumber: destination.destinationNumber,
        location: destination.location,
    };

    const validationSchema = Yup.object().shape({
        hotelName: Yup.string().required('Hotel Name is required'),
        bedType: Yup.string().required('Bed Type is required'),
        address: Yup.string().required('Address is required'),
        googleMapAddress: Yup.string(),
        contactNumber: Yup.string()
            .matches(/^\d+$/, 'Contact number must contain only digits')
            .min(10, 'Contact number must be at least 10 digits')
            .max(15, 'Contact number must not exceed 15 digits')
            .required('Contact Number is required'),
        amenities: Yup.string(),
        extraMattressPrice: Yup.number(),
        pricePerDay: Yup.number()
            .required('Price Per Day is required')
            .moreThan(0, 'Price Per Day must be greater than 0'),
        numberOfRooms: Yup.number()
            .required('Number of Rooms is required')
            .moreThan(0, 'Number of Rooms must be greater than 0')
            .max(1000, 'Number of Rooms must not exceed 1000')
    });

    const handleSubmit = async (values) => {
        setIsSubmitting(true);
        const response = await ApiRequest.handlePostApiRequest("/admin/roomAllocation/roomInventoryItem", values);
        if (!response) {
            console.log(values)
            alert("Error while saving the data");
        } else {
            setRoomInventoryItems(response)
        }
        setIsSubmitting(false);
        handleCloseDialog();
    };

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        handleCloseDialog();
    }

    return (
        <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle>Add the inventory against the room choice</DialogTitle>
            <DialogContent>
                <Typography>{destination.displayText}</Typography>
                <Typography>{option.displayText}</Typography>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm }) => {
                        handleSubmit(values);
                        resetForm();
                    }}
                >
                    {({ errors, touched }) => (
                        <Form>
                            <Field
                                as={TextField}
                                name="hotelName"
                                label="Hotel Name *"
                                fullWidth
                                error={errors.hotelName && touched.hotelName}
                                helperText={errors.hotelName && touched.hotelName && errors.hotelName}
                            />

                            <Field
                                as={TextField}
                                name="bedType"
                                label="Bed Type *"
                                fullWidth
                                error={errors.bedType && touched.bedType}
                                helperText={errors.bedType && touched.bedType && errors.bedType}
                            />

                            <Field
                                as={TextField}
                                name="address"
                                label="Address *"
                                fullWidth
                                error={errors.address && touched.address}
                                helperText={errors.address && touched.address && errors.address}
                            />

                            <Field
                                as={TextField}
                                name="googleMapAddress"
                                label="Google Map Address"
                                fullWidth
                                error={errors.googleMapAddress && touched.googleMapAddress}
                                helperText={errors.googleMapAddress && touched.googleMapAddress && errors.googleMapAddress}
                            />

                            <Field
                                as={TextField}
                                name="contactNumber"
                                label="Contact Number *"
                                fullWidth
                                error={errors.contactNumber && touched.contactNumber}
                                helperText={errors.contactNumber && touched.contactNumber && errors.contactNumber}
                            />

                            <Field
                                as={TextField}
                                name="amenities"
                                label="Amenities"
                                fullWidth
                            />

                            <Field
                                as={TextField}
                                name="extraMattressPrice"
                                label="Extra Mattress Price"
                                type="number"
                                fullWidth
                                error={errors.extraMattressPrice && touched.extraMattressPrice}
                                helperText={errors.extraMattressPrice && touched.extraMattressPrice && errors.extraMattressPrice}
                            />

                            <Field
                                as={TextField}
                                name="pricePerDay"
                                label="Price Per Day *"
                                type="number"
                                fullWidth
                                error={errors.pricePerDay && touched.pricePerDay}
                                helperText={errors.pricePerDay && touched.pricePerDay && errors.pricePerDay}
                            />

                            <Field
                                as={TextField}
                                name="numberOfRooms"
                                label="Number of Rooms *"
                                type="number"
                                fullWidth
                                error={errors.numberOfRooms && touched.numberOfRooms}
                                helperText={errors.numberOfRooms && touched.numberOfRooms && errors.numberOfRooms}
                            />

                            <DialogActions>
                                <div className="registration-form-content-actions" style={{ 'justifyContent': 'space-around' }}>
                                    <Button variant="contained" onClick={handleCloseDialog} disabled={isSubmitting}>Cancel</Button>
                                    <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>Submit</Button>
                                </div>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default RoomInventoryDialog;

import { Box, Button, Paper, Typography, createTheme } from '@mui/material';
import { ThemeProvider, makeStyles } from '@mui/styles';
import { useState } from 'react';
import LoginService from 'services/login.service';
import { formatTimestamp } from 'services/utils';
import AddMiscPaymentDialog from './AddMiscPaymentDialog';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2196f3',
        },
        secondary: {
            main: '#f50057',
        },
    },
});

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%', // Ensure the container takes full height
        display: 'flex', // Make container flexbox
        flexDirection: 'column', // Set flex direction to column
        overflowY: 'auto', // Add overflow property to enable scrolling
    },
    card: {
        overflow: 'auto', // Add overflow property to make the card scrollable
        flexGrow: 1, // Allow the card to grow to fill remaining space
    },
    divider: {
        // margin: theme.spacing(2, 0), // Add margin to the divider
    },
    noBorder: {
        border: 'none', // Remove border from TableCell
    },
    tableCell: {
        padding: '10px', // Set padding to reduce margin
    },
}));

const MiscPaymentTab = ({ registration, updateSingleRegistration, isPrint }) => {
    const [openDialog, setOpenDialog] = useState(false);
    // const [formData, setFormData] = useState({});

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <ThemeProvider theme={theme}>
            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                <Typography variant="h6" gutterBottom sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <strong>Misc payment</strong>
                    <div className="registration-form-content-actions" style={{ 'justify-content': 'end' }}>
                        {LoginService.isAdmin() && !isPrint &&
                            <Button variant="contained" color="primary" onClick={handleOpenDialog}>Add misc payment</Button>
                        }
                    </div>
                </Typography>
                {registration.miscPayments.map((miscCharge, index) => (
                    <Box key={index} mb={1}>
                        <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span><strong>{`Transaction ${index + 1}`}</strong></span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>Transaction Id:</span>
                            <span>{miscCharge.transactionId}</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>Transaction Mode:</span>
                            <span>{miscCharge.type}</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>Amount Paid:</span>
                            <span>{miscCharge.amountPaid}</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>Amount Accounted:</span>
                            <span>{miscCharge.amountAccounted}</span>
                        </Typography>
                        {miscCharge.amountRefunded > 0 &&
                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Amount Refunded:</span>
                                <span>{miscCharge.amountRefunded}</span>
                            </Typography>}
                        <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>Description:</span>
                            <span>{miscCharge.description}</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>Created at:</span>
                            <span>{formatTimestamp(miscCharge.createdAt)}</span>
                        </Typography>
                    </Box>
                ))}
                <Typography variant="h5" mt={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>Grand Total (P):</span>
                    <span>{registration.miscPaymentAmount}</span>
                </Typography>

                <AddMiscPaymentDialog
                    openDialog={openDialog}
                    handleCloseDialog={handleCloseDialog}
                    registration={registration}
                    updateSingleRegistration={updateSingleRegistration}
                />
            </Paper>
        </ThemeProvider>
    );
};

export default MiscPaymentTab;

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Paper, Typography, createTheme } from '@mui/material';
import { ThemeProvider, makeStyles } from '@mui/styles';
import { useState } from 'react';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2196f3',
        },
        secondary: {
            main: '#f50057',
        },
    },
});

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%', // Ensure the container takes full height
        display: 'flex', // Make container flexbox
        flexDirection: 'column', // Set flex direction to column
    },
    card: {
        overflow: 'auto', // Add overflow property to make the card scrollable
        flexGrow: 1, // Allow the card to grow to fill remaining space
    },
    divider: {
        // margin: theme.spacing(2, 0), // Add margin to the divider
    },
    noBorder: {
        border: 'none', // Remove border from TableCell
    },
}));

const FinancialSummary = ({ registrationDTO, defaultExpanded }) => {

    const [expanded, setExpanded] = useState(defaultExpanded && true);
    const classes = useStyles();

    return (
        <>
            <Accordion key='acc_registratoin_financial_summary' sx={{ mb: 2 }} expanded={expanded} onChange={() => setExpanded(!expanded)}>
                <AccordionSummary key={'acc_registratoin_financial_summary'} expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="title"><strong>Financial Summary</strong></Typography>
                </AccordionSummary>
                <AccordionDetails key='acc__detailsregistratoin_financial_summary'>
                    <ThemeProvider theme={theme}>
                        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                            <Box key="box_summary" mb={1}>
                                <Typography variant="body1" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Final bill(A+B):</span>
                                    <span>{registrationDTO.finalBill === 0 ? 'Not yet available' : registrationDTO.finalBill}</span>
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Total Misc Charge (C): </span>
                                    <span>{registrationDTO.miscCharge}</span>
                                </Typography>
                                <Divider sx={{ mt: 2 }} />
                                <Typography variant="body1" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Total credits (O+P): </span>
                                    <span>{registrationDTO.miscPaymentAmount + registrationDTO.onlineAmount}</span>
                                </Typography>
                                <Divider sx={{ mt: 2 }} />
                                <Typography variant="body1" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Final due: </span>
                                    <span>{registrationDTO.finalBill === 0 ? 'Not yet available' : Math.max(registrationDTO.finalBill + registrationDTO.miscCharge - registrationDTO.miscPaymentAmount - registrationDTO.onlineAmount, 0)}</span>
                                </Typography>
                                <Divider sx={{ mt: 2 }} />
                                <Typography variant="body1" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Registration amount: </span>
                                    <span>{registrationDTO.registrationAmount}</span>
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Registration due: </span>
                                    <span>{Math.max(registrationDTO.registrationAmount - registrationDTO.miscPaymentAmount - registrationDTO.onlineAmount, 0)}</span>
                                </Typography>
                                <Divider sx={{ mt: 2 }} />
                                <Typography variant="body1" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Registration status: </span>
                                    <span>{registrationDTO.registrationStatus}</span>
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Payment status: </span>
                                    <span>{registrationDTO.paymentStatus}</span>
                                </Typography>
                            </Box>
                        </Paper>
                    </ThemeProvider>
                </AccordionDetails>
            </Accordion>


        </>
    );
};

export default FinancialSummary;

import { AddBox } from '@mui/icons-material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import UpdateIcon from '@mui/icons-material/Update';
import { Box, Button, Divider, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ClearIcon } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { RoomSummary } from '../summaries/ShortSummary';
import { ValidationPopup } from '../generics/ValidationPopup';
import dayjs from 'dayjs';

const RoomDetailsForm = ({ configData, registrationDTO, handleCrudInDB, nextStep, prevStep }) => {

  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const destinations = (configData.destinations || []).map(destination => ({
    ...destination,
    options: destination.options.reduce((acc, option) => {
      acc[option.displayText] = option;
      return acc;
    }, {})
  }));

  const [isEditing, setIsEditing] = useState(false);
  // const [rooms, setRooms] = useState(registrationDTO.roomChoices || []);
  const [error, setError] = useState('');
  const [confirmMessage, setConfirmMessage] = useState('')
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupAction, setPopupAction] = useState('error');
  const [popupType, setPopupType] = useState('error');

  const validationSchema = Yup.object({
    rooms: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().nullable(true),
        indexAcrossLocation: Yup.number().required('Index across location is required'),
        displayText: Yup.string().required('Please choose the option from the drop-down menu, even if you are opting for your own arrangement or not attending.'),
      })
    ),
  });

  const formik = useFormik({
    initialValues: {
      rooms: Object.entries(destinations).map((destination, index) => ({
        id: null,
        type: '',
        indexAcrossLocation: registrationDTO.roomChoices.length,
        displayText: '',
        location: destination.location,
        minPrice: '',
        maxPrice: '',
        destinationNumber: index,
      })),
      index: '',
    },
    validationSchema: validationSchema,
  });

  const handlePrev = async (event) => {
    event.preventDefault();
    if (formik.dirty) {
      setPopupType('confirm');
      setConfirmMessage('We see that you have added some values in the current form. If you go back, you will lose only the current form; the values added for any other rooms will remain')
      setIsPopupOpen(true);
      setPopupAction(() => () => {
        setIsPopupOpen(false);
        setPopupType('error');
        prevStep()
      });
      return;
    }
    prevStep()
  }

  const [open, setOpen] = useState(false);
  let tooltipTimeout;
  const handleSubmit = () => {
    // Clear existing timeout to prevent unwanted behavior
    clearTimeout(tooltipTimeout);

    // If the tooltip is already open, close it immediately on click
    if (open) {
      setOpen(false);
      return;
    }

    // Check conditions to show the tooltip
    if (formik.dirty || registrationDTO.roomChoices.length === 0) {
      setOpen(true);

      // Set a timeout to automatically hide the tooltip after 3 seconds
      tooltipTimeout = setTimeout(() => {
        setOpen(false);
      }, 3000);
    } else {
      nextStep(); // Proceed with the next step if no tooltip condition is met
    }
  };

  const handleEditRoom = (index) => {
    const editedRoom = registrationDTO.roomChoices[index];
    formik.setValues({
      rooms: editedRoom,
      index: index,
    })
    setIsEditing(true);
  }

  const handleAddUpdateRoom = async () => {
    const errors = await formik.validateForm()
    if (Object.keys(errors).length !== 0) {
      formik.setTouched(errors);
      if (Object.keys(errors.rooms).length > 1) {
        setIsPopupOpen(true);
        setError("Please fill all the mandatory fields.");
        return;
      }
      return
    }
    // Validate form first
    let updatedRooms;
    if (isEditing) {
      updatedRooms = registrationDTO.roomChoices.map((r, i) =>
        i === formik.values.index ? formik.values.rooms : r
      );
    } else { // Not editing add new room
      const newRoom = formik.values.rooms.map((room, index) => ({
        ...room,
        destinationNumber: index,
        location: destinations[index].location,
        indexAcrossLocation: registrationDTO.roomChoices.length
      }))
      updatedRooms = ([
        ...registrationDTO.roomChoices,
        newRoom
      ])
    }
    // setRooms(updatedRooms)
    formik.resetForm()
    setIsEditing(false);
    await handleCrudInDB('roomChoices', updatedRooms);
    setIsPopupOpen(true);
    setError('Room ' + (isEditing ? 'updated' : 'added') + ' successfully');
  }

  const handleReset = () => {
    formik.resetForm()
    setIsEditing(false);
  }

  const handleDeleteRoom = (index) => {
    if (formik.dirty) {
      setIsPopupOpen(true);
      setError('Update and delete cannot be done simultaneously. Please either update first or clear out the form.');
      return false;
    }
    setPopupType('confirm');
    setConfirmMessage('Are you sure you want to delete this room?');
    setIsPopupOpen(true);
    setPopupAction(() => async () => {
      const updatedRooms = registrationDTO.roomChoices.filter((m, i) => i !== index);
      const reindexedRooms = updatedRooms.map((roomList, listIdx) => (
        roomList.map((room, roomIdx) => ({
          ...room,
          indexAcrossLocation: listIdx
        }))
      ));
      // setRooms(reindexedRooms);
      setIsPopupOpen(false);
      setPopupType('error');
      await handleCrudInDB('roomChoices', reindexedRooms);
      setIsPopupOpen(true);
      setError('Room deleted successfully');
    });
  };

  const handleOptionSelect = async (destinationNumber, displayText) => {
    const selectedOption = destinations[destinationNumber].options[displayText];
    if (selectedOption) {
      const { type, displayText, minPrice, maxPrice } = selectedOption;
      formik.setFieldValue(`rooms[${destinationNumber}].type`, type);
      formik.setFieldValue(`rooms[${destinationNumber}].displayText`, displayText);
      formik.setFieldValue(`rooms[${destinationNumber}].location`, destinations[destinationNumber].displayText);
      formik.setFieldValue(`rooms[${destinationNumber}].minPrice`, minPrice);
      formik.setFieldValue(`rooms[${destinationNumber}].maxPrice`, maxPrice);
      await formik.setFieldTouched(`rooms[${destinationNumber}].displayText`, true, true); // Mark as touched
      await formik.validateField(`rooms[${destinationNumber}].displayText`); // Manually trigger field validation
      return true;
    } else {
      return false;
    }
  };

  if (!destinations) {
    return (<>
      Loading...
    </>)
  }

  return (
    <>
      <div className="page-section">
        <Box>
          <div className="rps-flex section-header">
            <h4 className="flex-one">Hotel Selections</h4>
          </div>
          <ValidationPopup
            isOpen={isPopupOpen}
            message={popupType === 'error' ? error : confirmMessage}
            type={popupType}
            onConfirm={popupAction}
            onCancel={() => {
              setIsPopupOpen(false);
              setPopupType('error');
              setError('')
            }}
          />
          {registrationDTO.roomChoices.map((room, roomIndex) => (
            <RoomSummary
              room={room}
              roomIndex={roomIndex}
              handleEditRoom={handleEditRoom}
              handleDeleteRoom={handleDeleteRoom}
              defaultExpanded={false}
              destinations={configData.destinations}
            />
          ))}
          <Typography variant="h6">
            {isEditing ?
              <strong>Updating ROOM : {formik.values.index + 1} </strong>
              :
              <strong>Please add your new room below.</strong>
            }
          </Typography>

          {/* {destinations.map((destination, destinationNumber) => ( */}
          {destinations.map((room, destinationNumber) => (
            <>
              <FormHelperText style={{ padding: '10px 0px 10px 0px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" align='left' style={{ fontSize: '1rem' }}>
                      <strong>
                        {'Destination ' + (destinationNumber + 1) + ' : ' + destinations[destinationNumber].location.toUpperCase()}
                      </strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" align='right' style={{ fontSize: '0.7rem', color: 'orange' }}>
                      {dayjs(destinations[destinationNumber].checkIn).format('MMM DD, YYYY')} to {dayjs(destinations[destinationNumber].checkOut).format('MMM DD, YYYY')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="body2" align='left' style={{ fontSize: '0.9rem' }}>
                      <strong>{destinations[destinationNumber].info}</strong>
                    </Typography>
                  </Grid>
                </Grid>
              </FormHelperText>
              <FormControl key={destinationNumber} fullWidth error={formik.touched.rooms?.[destinationNumber]?.displayText && formik.errors.rooms?.[destinationNumber]?.displayText}>
                <InputLabel id={`rooms[${destinationNumber}].displayText`} htmlFor={destinations[destinationNumber].displayText}>{'Chose your preference for ' + destinations[destinationNumber].displayText} *</InputLabel>
                <Select
                  // variant="outlined"
                  id={`rooms[${destinationNumber}].displayText`}
                  {...formik.getFieldProps(`rooms[${destinationNumber}].displayText`)}
                  onChange={(event) => {
                    formik.handleChange(event); // Handle change in Formik
                    handleOptionSelect(destinationNumber, event.target.value); // Custom handling
                  }}
                >
                  <MenuItem value="">Select an option</MenuItem>
                  {Object.entries(destinations[destinationNumber].options).map(([displayText, roomOption], optionIndex) => (
                    <MenuItem
                      key={displayText}
                      value={displayText}
                      disabled={!roomOption.available}
                    >
                      {displayText}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.rooms?.[destinationNumber]?.displayText && formik.errors.rooms?.[destinationNumber]?.displayText ? (
                  <FormHelperText>{formik.errors.rooms[destinationNumber].displayText}</FormHelperText>
                ) : (
                  <FormHelperText style={{ color: 'green' }}>{destinations[destinationNumber]?.options?.[formik.values.rooms[destinationNumber]?.displayText]?.info}</FormHelperText>
                )}
                <Divider style={{ padding: '15px' }} />
              </FormControl>
            </>
          ))}
        </Box>
        <Divider sx={{ mt: 2 }} />
        <FormHelperText style={{ fontSize: '0.8rem', color: 'blue' }}>
          Your final bill may come from {registrationDTO.approxBill} based on your current selection.<br />
          Registration amount will be deducted from your final bill excluding GST and convenience fees.<br />
        </FormHelperText>
      </div>
      <Divider />
      <div className="registration-form-content-actions">
        <Button variant="contained" onClick={handlePrev} sx={{ mt: 1 }} startIcon={<KeyboardArrowLeftIcon className='xyz-white-icon' />}>
          {isSmallScreen ? <span style={{ fontSize: '8px' }}>Back</span> : 'Back'}
        </Button>
        <Button variant="contained" onClick={handleReset} sx={{ mt: 1 }} >
          <ClearIcon className='xyz-white-icon' sx={{ fontSize: isSmallScreen ? 'small' : 'inherit', mr: 1 }} />
          {isSmallScreen ? <span style={{ fontSize: '8px' }}>Reset</span> : 'Reset'}
        </Button>
        <Button variant="contained" onClick={handleAddUpdateRoom} sx={{ mt: 1 }}>
          {isEditing ? <UpdateIcon className='xyz-white-icon' sx={{ mr: 1 }} /> : <AddBox className='xyz-white-icon' sx={{ mr: 1 }} />}
          {isSmallScreen ?
            <span style={{ fontSize: '8px' }}>
              {isEditing ? 'Update Room' : 'Add Room'}
            </span> :
            isEditing ? 'Update Room' : 'Add Room'
          }
        </Button>
        {/* Next button */}
        <Tooltip
          title={
            formik.dirty ? (isEditing ? "Please save changes using 'Update room', or click 'Reset'." : "Please save changes using 'Add room', or click 'Reset'.") :
              registrationDTO.roomChoices.length === 0 ? "Please add at least one room." : ""
          }
          open={open}
          onClose={() => setOpen(false)}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <span onClick={handleSubmit} style={{ cursor: 'pointer' }}> {/* Make the span clickable */}
            <Button
              variant="contained"
              sx={{ mt: 1 }}
              endIcon={<KeyboardArrowRightIcon className='xyz-white-icon' />}
              disabled={formik.dirty || registrationDTO.roomChoices.length === 0}
            >
              {isSmallScreen ? (
                <span style={{ fontSize: '8px' }}>Next</span>
              ) : (
                'Next'
              )}
              {/* <HelpOutlineIcon fontSize="small" style={{ marginLeft: '4px' }} className='xyz-white-icon' /> */}
            </Button>
          </span>
        </Tooltip>
      </div>
    </>
  );
};

export default RoomDetailsForm;

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Paper, Typography, createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/styles';
import { useEffect, useState } from 'react';
import ApiRequest from 'services/api.service';
import { formatTimestamp, formatToIndianRupeeFromPaise } from 'services/utils';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2196f3',
        },
        secondary: {
            main: '#f50057',
        },
    },
});


const SettlementTable = ({ defaultExpanded }) => {
    const [expanded, setExpanded] = useState(defaultExpanded && true);
    const [settlements, setSettlements] = useState([])
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        ApiRequest.handleGetApiRequest('/accounts/settlements').then((response) => {
            setSettlements(response);
            setIsLoading(false);
        });
    }, []);

    if (isLoading) {
        return (
            <div>Loading</div>
        )
    }

    return (
        <Container maxWidth="md" sx={{ marginTop: 4, overflow: 'auto' }}>
            <div className="summary-container">
                <div className="rps-flex" style={{ padding: "1em 0em" }}>
                    <Typography variant="h4">
                        Settlements
                    </Typography>
                </div>


                <div id="registration-form-table">
                    {settlements.map((settlement, index) => (
                        <Accordion key='acc_registratoin_financial_summary' sx={{ mb: 2 }} expanded={expanded} onChange={() => setExpanded(!expanded)}>
                            <AccordionSummary key={'acc_registratoin_financial_summary'} expandIcon={<ExpandMoreIcon />}>
                                <div style={{ width: '50%' }}>
                                    <div className="user-details-row">
                                        <div className="user-details">
                                            <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span className='mr-4'><strong>{settlement.id}</strong></span>
                                                <span>{formatToIndianRupeeFromPaise(settlement.amount)}</span>
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails key='acc__detailsregistratoin_financial_summary'>
                                <ThemeProvider theme={theme}>
                                    <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                                        <Box key={index} mb={1}>
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Amount</span>
                                                <span>{formatToIndianRupeeFromPaise(settlement.amount)}</span>
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Fees</span>
                                                <span>{formatToIndianRupeeFromPaise(settlement.fees)}</span>
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Tax</span>
                                                <span>{formatToIndianRupeeFromPaise(settlement.tax)}</span>
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Settlement Id</span>
                                                <span>{settlement.id}</span>
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Utr</span>
                                                <span>{settlement.utr}</span>
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Entity</span>
                                                <span>{settlement.entity}</span>
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Status</span>
                                                <span>{settlement.status}</span>
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Created_at</span>
                                                <span>{formatTimestamp(settlement.created_at)}</span>
                                            </Typography>
                                        </Box>
                                    </Paper>
                                </ThemeProvider>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            </div>
        </Container>
    );
}

export default SettlementTable;

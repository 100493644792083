import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
import ShortSummary from '../summaries/ShortSummary';
import LegalResponsibility from 'components/pages/legal-responsibility/LegalResponsibility';
import { ValidationPopup } from 'components/generics/ValidationPopup';

const SummaryTab = ({ configData, registrationDTO, handleCrudInDB, nextStep, prevStep }) => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [internalComments, setInternalComments] = useState(registrationDTO.comments)

  const [error, setError] = useState('');
  const [confirmMessage, setConfirmMessage] = useState('')
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupAction, setPopupAction] = useState('error');
  const [popupType, setPopupType] = useState('error');

  const handleCommentsChange = (event) => {
    setInternalComments(event.target.value);
    // setComments(event.target.value);
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleSubmit = () => {
    if (isChecked) {
      // setComments(internalComments);
      handleCrudInDB('comments', internalComments);
      nextStep();
    } else {
      setIsPopupOpen(true);
      setError("Please accept the terms and condition");
      return;
    }
  }

  return (
    <>
      <div className="page-section">
        <div className="rps-flex section-header">
          <h4 className="flex-one">Yatra registration Summary </h4>
        </div>

        <ValidationPopup
          isOpen={isPopupOpen}
          message={popupType === 'error' ? error : confirmMessage}
          type={popupType}
          onConfirm={popupAction}
          onCancel={() => {
            setIsPopupOpen(false);
            setPopupType('error');
            setError('')
          }}
        />
        <ShortSummary
          userDetails={registrationDTO.userDetails}
          memberDetails={registrationDTO.members}
          roomDetails={registrationDTO.roomChoices}
          registration={registrationDTO}
          defaultExpanded={true}
          destinations={configData.destinations}
        />
        <LegalResponsibility />
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              onChange={handleCheckboxChange}
              color="primary"
            />
          }
          label="I accept the terms and conditions"
        />
        <div className="room-form">
          <h3>Comments</h3>
          <div className='form-group'>
            <textarea
              style={{ width: '100%', boxSizing: 'border-box' }}
              value={internalComments}
              onChange={handleCommentsChange}
              placeholder="Add any comments here"
            ></textarea>
          </div>
        </div>
      </div>
      <div className="registration-form-content-actions">
        <Button variant="contained" onClick={prevStep} sx={{ mt: 1 }} startIcon={<KeyboardArrowLeftIcon className='xyz-white-icon' />}>
          {isSmallScreen ? <span style={{ fontSize: '8px' }}>Back</span> : 'Back'}
        </Button>
        <Button variant="contained" onClick={handleSubmit} sx={{ mt: 1 }} endIcon={<KeyboardArrowRightIcon className='xyz-white-icon' />}>
          {isSmallScreen ? <span style={{ fontSize: '8px' }}>Save and proceed</span> : 'Save and proceed'}
        </Button>
      </div>
    </>
  );
};

export default SummaryTab;

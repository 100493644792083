import React from 'react';

import styles from './TermsAndConditions.module.scss';

function TermsAndConditions() {

  return (
    <div className={styles.termsAndConditionsMain}>
      <h3 className={styles.heading}>Terms and Conditions</h3>

      <div className={styles.parentSection}>
        <h5 className={styles.sectionHeading}>Changes</h5>
        <label className={styles.sectionSubHeading}>Changes & Customizations</label>
        <p>
          <strong>CHANGING YOUR PACKAGE OR DEPARTURE:</strong>
          Requests to modify an existing booking to change your package or to change your departure (to another scheduled departure date for the same package) are subject to availability of the new package or departure. When such requests are received on or before your final payment due date, the change fees will be applicable as per your package. When such requests are received after your final payment due date, they are considered cancellations and are subject to cancellation fees, but any refund due to you after such fees may be applied as a credit towards the new package or departure.
        </p>
        <p>
          <strong>CUSTOMIZATIONS:</strong>
          If you wish to arrange flights from other cities or otherwise modify or customize one of our packages, please make your request when you book or contact us prior to booking. If you wish to make changes to an existing booking, please contact us as soon as possible. We will let you know if your request is possible and the cost. After your final payment due date, changes to your package are usually difficult and may involve significant additional cost.
        </p>
        <p>
          Once you accept our quote, we will add the amount to your balance due. Some customizations may require immediate payment while others will be payable by your final payment due date. Once a Change or Customization has been quoted, accepted, and confirmed, it is generally subject to the same cancellation policy that applies to your package.
        </p>
        <p>Once flight tickets are issued, they are subject to any applicable airline policies and are generally unchangeable and nonrefundable.</p>
        <p>
          <strong>EXTENDED STAYS:</strong> On some packages, you may be able to extend your stay by departing after and/or arriving before the scheduled dates. We can arrange modified flight dates, extra hotel nights, and/or transfers, subject to availability and other restrictions. See Changes & Customizations for more information.
        </p>
      </div>

      <div className={styles.parentSection}>
        <h5 className={styles.sectionHeading}>Cancellations</h5>

        <label className={styles.sectionSubHeading}>Cancellations by You</label>
        <p>
          If you elect to cancel your booking for any reason, you must notify us in writing. Except when covered by our Booking policy, cancellations are subject to fees which vary by your date of booking, your date of cancellation, and by package; select a package for details. Cancellation fees may change at any time, and your booking is subject to the schedule in effect at the time of booking. A copy of the Terms and fee schedule that govern your booking should be available under Your Booking or attached to your booking receipt email.
        </p>
        <p>When you cancel, we will refund all monies paid, minus:</p>
        <p>1) your deposit or the applicable cancellation fee, whichever is greater</p>
        <p>2) the cost of any issued flight tickets if nonrefundable, or any cancellation fee assessed by the airline if refundable</p>
        <p>When you request cancellation, we will advise you of the total of these fees and your total refund, if any. In some cases, as indicated in the fee schedule, some or the entire cancellation fee we withhold may be applied as a Travel Credit, which does not expire and can be used toward any future booking.</p>

        <label className={styles.sectionSubHeading}>Cancellations by Us</label>
        <p>
          Most of our advertised packages require a minimum number of travelers to operate. We reserve the right to cancel or reschedule any departure due to insufficient demand, unsafe conditions, events beyond our control, or any other reason. If we cancel a departure, we will notify you prior to the final payment due date (unless the cancellation is due to an unforeseen situation that arises after that) and offer alternate dates of travel or a full refund.
        </p>
        <p>
          We are not responsible for any incidental expenses or consequential losses that you incur as a result of a cancelled booking, including but not limited to loss of earnings, loss of enjoyment, or the cost of any connecting flights, accommodations, airport transportation, visas, document fees, vaccinations, or other services you may purchase that are dependent upon the operation of your trip. Where possible, we recommend that you wait until after your final payment due date to purchase any such services.
        </p>
        <p>
          (All refunds will be paid within 4 to 6 days)*
        </p>
      </div>

      <div className={styles.parentSection}>
        <h5 className={styles.sectionHeading}>Accommodations</h5>
        <p>
          Our packages include carefully selected hotels and/or cruise ships. Advertised prices are for standard double occupancy rooms or cabins. You can enter your preferred number of beds (one or two) per room or cabin when you book, and we will do our best to honor your request but cannot guarantee it. Single or triple occupancy rooms or cabins may be available upon request, but availability cannot be guaranteed.
        </p>
        <p>
          At least 8 days prior to departure, we will send you a detailed list of all accommodations on your package, including hotel addresses, telephone, and email. Sometimes it is necessary to change the hotels or cruise ship used on your package. In that event, we will make every effort to ensure that substitutes meet or exceed the quality and location of the original.
        </p>
        <p>
          While we do our best to select upscale accommodations, some hotels may not have air-conditioning, especially in rustic or remote locations. In such cases, other types of climate control are generally available.
        </p>
      </div>

      <div className={styles.parentSection}>
        <h5 className={styles.sectionHeading}>Trip Operation</h5>
        <p>
          <strong>ITINERARIES:</strong>
          We make every effort to ensure our tours & packages run smoothly and according to the posted itineraries. However, international travel is complex, and we reserve the right to modify our itineraries as we deem necessary to keep everything running comfortably. Please refer to the latest version of your itinerary on our website prior to travel, which we will also send you at least 14 days prior to departure. The planned itinerary is representative of the types of accommodations, transport and activities planned, but the actual itinerary, including routes, schedules, amenities, modes of transport, and other items, may still be subject to alteration or substitution without prior notice due to circumstances on the ground or upon recommendation of our partners in destination. In these cases, we will make every effort to ensure the changes result in an equivalent or better experience for our travelers. We will not be held liable for any damages related to these alterations.
        </p>
        <p>
          In rare cases, due to circumstances beyond our control (see Force Majeure below), and for the safety, comfort, and wellbeing of our travelers, it may become necessary to make major changes to our itineraries, including omitting certain destinations or segments of a tour (such as a cruise), or even cancelling a departure entirely. Should this occur, we will inform you as soon as reasonably possible. We will also make every effort to obtain refunds from our suppliers for the unused services, which we will refund to you within 10 working days. However, it may not be possible to obtain refunds from all of our suppliers (especially for ticketed flights), and we cannot guarantee a refund of your entire package price.
        </p>
        <p>
          <strong>FORCE MAJEURE:</strong>
          Certain unexpected circumstances may arise, such as (but not limited to) weather events, pandemics, quarantines, border closures, acts of governments or authorities, wars, hostilities, political uprisings or civil disturbances, riots, strikes, terrorist activities or the threat of terrorist activities, criminal acts committed by third parties, defects of vehicles or breakdown in equipment, or an Act of God, that are beyond anyone's control, which force a cancellation or disruption of services. These circumstances are considered a force majeure. We will not be held responsible or liable in any way for such events, including death, bodily injury, illness, damage, delay, or other losses caused by such events. While we will always strive to assist our travelers wherever they are in the world when such circumstances occur, any losses or costs resulting from these events will be the responsibility of the traveler.
        </p>
        <p>
          <strong>THIRD PARTY SUPPLIERS:</strong>
          DHAM YATRA LLP makes arrangements with airlines, cruise lines, hotels and other accommodation providers, coach companies, transfer operators, shore excursion operators, tour and local guides, activity providers, and other independent parties ("Third Party Suppliers") to provide you with some or all of the components of your booking. Third Party Suppliers may also engage the services of local operators and sub-contractors. We take great care in selecting Third Party Suppliers, but we do not supervise or control these suppliers and cannot be responsible for their acts or omissions.
        </p>
        <p>
          In the absence of our own negligence, we shall not be responsible for any cancellations, delays, diversions or substitution of equipment; loss or damage to baggage or property; injury, illness, or death; or any claims, losses, damages, costs or expenses arising out of inconvenience, loss of enjoyment, upset, disappointment, distress or frustration, whether physical or mental, resulting from the any act, omission, error or negligence whatsoever by air carriers, rail, coach & ship cruise companies, hotels, transportation companies, other persons providing any of the services and accommodations to travelers, or any other person not our direct employee or under our exclusive control. Air carriers, rail, coach & ship cruise companies are not to be held responsible for any act, omissions, or events during the times that passengers are not aboard their aircraft or conveyances. We are not responsible for any criminal conduct by third parties.
        </p>
        <p>
          The international carriage of passengers is subject to international conventions and treaties, where applicable. These international agreements limit and, in some cases, exclude the carrier's liability to travelers. Where any claim or part of a claim (including those involving death or personal injury) concerns or involves any travel arrangements (including the process of getting on or off the transport concerned) provided by any air, sea, inland waterways, rail, or road carrier or any stay in a hotel, our maximum liability is the maximum which would be payable by the carrier, or the hotel keeper concerned under the applicable international convention, treaty, or regulation applicable to the travel arrangements or hotel stay in that situation.
        </p>
      </div>

      <div className={styles.parentSection}>
        <h5 className={styles.sectionHeading}>Participation</h5>
        <p>
          <strong>JOINING A TOUR:</strong>
          If you purchase a package that includes flights and/or transfers, you are responsible for making all necessary arrangements and allowing sufficient time to embark on these flights or transfers. If you miss your flights or transfers, contact us without delay. If you purchase a package that does not include flights or transfers (see Tour Only Option), you are responsible for making your own way to the package starting point, usually the first hotel listed in the itinerary. We will provide instructions for how and where to meet your tour group, manager, or guide. If for any reason you are unable to make it to the starting point on time, contact us without delay. We will make all reasonable efforts to assist you in making alternate arrangements to join your tour, but you are responsible for any extra costs incurred, and no refunds will be issued for unused services.
        </p>
        <p>
          <strong>TOUR MANAGERS:</strong>
          On our escorted group tours, you'll be accompanied by a team of tour managers who is an authority on the destination and is responsible for the safety and wellbeing of our travelers and for the smooth operation of the tour. The decisions of the tour managers are final on all matters likely to affect the safety or wellbeing of all travelers participating in the trip.
        </p>
        <p>
          <strong>GROUP PARTICIPATION:</strong>
          On our group tours, you'll usually be traveling with a group of strangers, and this can sometimes be challenging, especially in a foreign country. To avoid any discord within our groups and to provide our travelers with a positive touring experience, we reserve the right to reject or remove any traveler whose behavior is determined to be incompatible with the group. If you fail to comply with a decision made by our tour manager, behave in a way that is judged to cause or likely to cause danger, distress, or material annoyance to others, interfere with the wellbeing or mobility of the group, are deemed to be fractious to the group, at the sole discretion of Dham Yatra LLP and/or our tour managers and representatives, we may ask you to leave the tour. In this case, we will not refund any portion of the package cost, and all expenses incurred by the termination of the touring program are your responsibility. We may also elect not to carry you on any future trips.
        </p>
        <p>
          <strong>EMERGENCY MEDICAL TREATMENT:</strong>
          Should any medical emergency arise which precludes your ability to consent to emergency treatment when such authorization is required, we or our representatives will endeavor to communicate with your travel companions or previously designated emergency contact to request permission for any necessary treatment. If our representatives believe, at their sole discretion, that time or circumstances do not permit such communication, you authorize our representatives to consent on your behalf to any medical treatment, including all types of medical examinations, diagnoses, medication, treatment, or physician or hospital care, that is deemed advisable by, and is to be rendered under, the supervision of any physician or surgeon. You agree not to hold us or our representatives responsible for actions relating to any such medical or emergency treatment.
        </p>
        <p>
          <strong>LOCAL PURCHASES:</strong>
          We make every effort to give you an opportunity to browse local markets, shops and stores, time permitting. Some itineraries include scheduled stops at specialty stores or factories where you can view a demonstration of a local craft or purchase merchandise. If you decide to purchase these or any other items while on tour, you are responsible for those purchases. We are not responsible for the quality or authenticity of these items. It is always a good idea to comparison-shop and to thoroughly inspect all merchandise before leaving the store or shop, as most stores will not permit you to return or exchange purchased items. Prices may not include shipping/handling fees or customs charges. We are not responsible for the delivery or receipt of any item you purchase while traveling.
        </p>
      </div>

      <div className={styles.parentSection}>
        <h5 className={styles.sectionHeading}>Miscellaneous</h5>
        <p>These Terms represents the entire agreement between travelers and Dham Yatra LLP. There is no additional liability.</p>
        <p>
          If any provision of these Terms is so broad as to be unenforceable, such provision will be interpreted to be only so broad as is enforceable. The invalidity or unenforceability of any provision hereof will in no way affect the validity or enforceability of any other provision.
        </p>
        <p>
          These Terms will inure to the benefit of and be binding upon the parties and their respective heirs, legal and personal representatives, executors, estate trustees, successors, and assigns. In the event that some other person or entity seeks compensation for claims from which you have released us from liability by agreeing to these Terms, you (or your estate) will indemnify and hold harmless Dham Yatra LLP for all sums reasonably incurred in response to such claims, including our reasonable attorney costs and fees.
        </p>
        <p>Any dispute arising from these packages shall be litigated only in the courts of Indore, MP India, which shall have exclusive jurisdiction.</p>
        <p>We reserve the right to update or alter these Terms at any time. We will post amendments to these Terms on our website, and any amendment will take effect immediately upon posting.</p>
        <p>We retain the right at our sole discretion to deny access to anyone to our website and the products and services we offer, at any time and for any reason.</p>
      </div>

    </div>
  );

}

export default TermsAndConditions;
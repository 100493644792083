import { CheckCircleOutline as CheckCircleOutlineIcon } from '@mui/icons-material';
import { Alert, Avatar, Box, Button, Grid, InputAdornment, Step, StepLabel, Stepper, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import LoginService from '../../services/login.service';
import { PersonAddOutlined as PersonAddOutlinedIcon } from '@mui/icons-material';

const Signup = ({ isPasswordReset, buttonText }) => {
  const navigate = useNavigate();
  const [message, setMessage] = React.useState('');
  const [successful, setSuccessful] = React.useState(false);
  const [otpSent, setOtpSent] = React.useState(false);
  const [sendingOTP, setSendingOTP] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);

  const steps = ['Enter Email', 'OTP Verification', 'Set Password'];

  // Validation schema
  const validationSchema = Yup.object({
    email: Yup.string().email('Enter a valid email').required('Email is required'),
    otp: Yup.string().matches(/^[0-9]{6}$/, 'OTP must be exactly 6 digits').required('OTP is required'),
    password: Yup.string().min(6, 'Password must contain at least 6 characters').max(40, 'Password cannot exceed 40 characters').required('Password is required'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirm your password'),
  });
  const formik = useFormik({
    initialValues: {
      email: '',
      otp: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        let result;
        if (isPasswordReset) {
          result = await LoginService.handleResetPassword(values.email, values.otp, values.password, values.confirmPassword);
        } else {
          result = await LoginService.handleSignup(values.email, values.otp, values.password, values.confirmPassword);
        }
        if (result.success) {
          setMessage(result.message);
          setSuccessful(true);
          navigate('/login'); // or any success action
        } else {
          setSuccessful(false);
          setMessage(result.message);
          setSuccessful(false);
        }
      } catch (error) {
        setSuccessful(false);
        console.error('Error during signup:', error);
        setMessage('Failed to sign up. Please try again later.');
        setSuccessful(false);
      }
    },
  });

  const handleSendOTP = async () => {
    setSendingOTP(true);
    // Simulate sending OTP
    try {
      const resetPassword = isPasswordReset ? true : false;
      const response = await LoginService.sendOTP(formik.values.email, resetPassword); // Adjust based on your API
      if (response.success) {
        setSuccessful(true)
        setMessage("OTP sent successfully!");
        setOtpSent(true);
        setActiveStep(1); // Move to OTP Verification step
      } else {
        setMessage(response.message);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setMessage("Failed to send OTP. Please try again later.");
    } finally {
      setSendingOTP(false);
    }
  };

  const isOtpComplete = formik.values.otp.length === 6;
  const isPasswordComplete = formik.values.password === formik.values.confirmPassword && formik.values.password.length > 0;

  return (
    <Box sx={{ flexGrow: 1, mt: 2, mx: 'auto', width: '75%' }}>
      <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 3 }}>
        {steps.map((label, index) => {
          // Determine if the current step is completed
          const stepIsCompleted = (index === 0 && !formik.errors.email && formik.values.email && formik.touched.email)
            || (index === 1 && isOtpComplete)
            || (index === 2 && isPasswordComplete);

          return (
            <Step key={label} completed={stepIsCompleted}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} display="flex" justifyContent="center">
          {/* <Avatar alt="Signup Icon" src="//ssl.gstatic.com/accounts/ui/avatar_2x.png" sx={{ width: 56, height: 56, mb: 2 }} /> */}
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <PersonAddOutlinedIcon />
          </Avatar>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <TextField fullWidth id="email" name="email" label="Email" value={formik.values.email} onChange={formik.handleChange} error={formik.touched.email && Boolean(formik.errors.email)} helperText={formik.touched.email && formik.errors.email} />
            {!otpSent && (
              <div className="form-group">
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth onClick={handleSendOTP}
                  disabled={sendingOTP || !formik.values.email || !!formik.errors.email}
                  sx={{ mt: 1 }}
                >
                  {sendingOTP ? "Sending OTP..." : "Send OTP"}
                </Button>
              </div>
            )}
            {otpSent && (
              <>
                <TextField
                  fullWidth id="otp"
                  name="otp" label="OTP"
                  value={formik.values.otp}
                  onChange={formik.handleChange}
                  error={formik.touched.otp && Boolean(formik.errors.otp)}
                  helperText={formik.touched.otp && formik.errors.otp}
                  InputProps={{
                    endAdornment: formik.values.otp.length === 6 ? (
                      <InputAdornment position="end">
                        <CheckCircleOutlineIcon color="success" />
                      </InputAdornment>
                    ) : null,
                  }}
                />
                <TextField
                  fullWidth id="password"
                  name="password"
                  label="Password"
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                />
                <TextField
                  fullWidth
                  id="confirmPassword"
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                  helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                  InputProps={{
                    endAdornment: formik.values.password && formik.values.password === formik.values.confirmPassword ? (
                      <InputAdornment position="end">
                        <CheckCircleOutlineIcon color="success" />
                      </InputAdornment>
                    ) : null,
                  }}
                />
                <div className="form-group">
                  <Button color="primary" variant="contained" fullWidth type="submit">
                    {isPasswordReset ? buttonText : 'Sign Up'}
                  </Button>
                </div>
              </>
            )}
          </form>
          {message && <Alert severity={successful ? 'success' : 'error'} sx={{ mt: 2 }}>{message}</Alert>}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Signup;

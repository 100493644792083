export const countryData = [
  "India",
  "United States of America",
  "Singapore",
  "China",
  "Australia",
  "Trinidad and Tobago",
  "South Africa",
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Austria",
  "Azerbaijan",
  "Bahrain",
  "Bangladesh",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo (Democratic Republic of the)",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (Democratic People's Republic of)",
  "Korea (Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe"
]

export const countryCodeData = [
  "+91",
  "+1",
  "+65",
  "+86",
  "+61",
  "+27",
  "+93",
  "+355",
  "+213",
  "+376",
  "+244",
  "+672",
  "+54",
  "+374",
  "+297",
  "+43",
  "+994",
  "+973",
  "+880",
  "+375",
  "+32",
  "+501",
  "+229",
  "+975",
  "+591",
  "+387",
  "+267",
  "+55",
  "+673",
  "+359",
  "+226",
  "+257",
  "+855",
  "+237",
  "+238",
  "+236",
  "+235",
  "+56",
  "+57",
  "+269",
  "+242",
  "+682",
  "+506",
  "+225",
  "+385",
  "+53",
  "+357",
  "+420",
  "+45",
  "+253",
  "+670",
  "+593",
  "+20",
  "+503",
  "+240",
  "+291",
  "+372",
  "+251",
  "+500",
  "+298",
  "+679",
  "+358",
  "+33",
  "+594",
  "+689",
  "+241",
  "+220",
  "+995",
  "+49",
  "+233",
  "+350",
  "+30",
  "+299",
  "+502",
  "+224",
  "+245",
  "+592",
  "+509",
  "+504",
  "+852",
  "+36",
  "+354",
  "+62",
  "+98",
  "+964",
  "+353",
  "+972",
  "+39",
  "+81",
  "+962",
  "+7",
  "+254",
  "+686",
  "+850",
  "+82",
  "+965",
  "+996",
  "+856",
  "+371",
  "+961",
  "+266",
  "+231",
  "+218",
  "+423",
  "+370",
  "+352",
  "+261",
  "+265",
  "+60",
  "+960",
  "+223",
  "+356",
  "+692",
  "+222",
  "+230",
  "+52",
  "+691",
  "+373",
  "+377",
  "+976",
  "+382",
  "+212",
  "+258",
  "+95",
  "+264",
  "+674",
  "+977",
  "+31",
  "+64",
  "+505",
  "+227",
  "+234",
  "+47",
  "+968",
  "+92",
  "+680",
  "+970",
  "+507",
  "+675",
  "+595",
  "+51",
  "+63",
  "+48",
  "+351",
  "+974",
  "+40",
  "+250",
  "+685",
  "+378",
  "+239",
  "+966",
  "+221",
  "+381",
  "+248",
  "+232",
  "+421",
  "+386",
  "+677",
  "+252",
  "+211",
  "+34",
  "+94",
  "+249",
  "+597",
  "+46",
  "+41",
  "+963",
  "+886",
  "+992",
  "+255",
  "+66",
  "+670",
  "+228",
  "+676",
  "+216",
  "+90",
  "+993",
  "+688",
  "+256",
  "+380",
  "+971",
  "+44",
  "+598",
  "+998",
  "+678",
  "+379",
  "+58",
  "+84",
  "+967",
  "+260",
  "+263"
]

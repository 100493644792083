import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CloseIcon from '@mui/icons-material/Close';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Drawer, IconButton, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ApiRequest from 'services/api.service';
import RegistrationCard from 'components/registration/RegistrationCard';
import FilterPanel from './FilterlPanel';

const AllRegistrationLite = () => {

    // Styles
    const useStyles = makeStyles({
        tableRow: {
            border: '2px solid',
            borderRadius: '4px',
            marginBottom: '8px',
            borderColor: 'transparent',
        },
        registrationNotRegistered: {
            borderColor: '#dc3545',
        },
        registrationRegistered: {
            borderColor: '#28a745',
        },
        paymentNotPaid: {
            backgroundColor: '#f8d7da',
        },
        paymentPartiallyPaid: {
            backgroundColor: '#fff3cd',
        },
        paymentFullPaid: {
            backgroundColor: '#d0f0c0',
        }
    });
    const classes = useStyles();

    const getRowClass = (registration) => {
        let rowClass = classes.tableRow;

        if (registration.registrationStatus === 'NOT_REGISTERED') {
            rowClass += ` ${classes.registrationNotRegistered}`;
        } else if (registration.registrationStatus === 'REGISTERED') {
            rowClass += ` ${classes.registrationRegistered}`;
        }

        if (registration.paymentStatus === 'NOT_PAID') {
            rowClass += ` ${classes.paymentNotPaid}`;
        } else if (registration.paymentStatus === 'PARTIALLY_PAID' || registration.paymentStatus === 'REGISTRATION_PARTIALLY_PAID' || registration.paymentStatus === 'REGISTRATION_PAID') {
            rowClass += ` ${classes.paymentPartiallyPaid}`;
        } else if (registration.paymentStatus === 'FULL_PAID') {
            rowClass += ` ${classes.paymentFullPaid}`;
        }

        return rowClass;
    };

    const location = useLocation();
    const from = location.pathname;
    const [configData, setConfigData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isConfigLoading, setIsConfigLoading] = useState(true);
    const [uniqueValues, setUniqueValues] = useState([])

    // Pagination and registration
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('rowsPerPage') || 25);
    const [registrations, setRegistrations] = useState([]);

    // Sorting state
    const [sortConfig, setSortConfig] = useState([{ key: 'id', direction: 'desc' }]);

    // Drawer
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedRegistration, setSelectedRegistration] = useState(null);
    const [selectedRegistrationData, setSelectedRegistrationData] = useState(null);

    // Filter parameters
    const [filterParams, setFilterParams] = useState(() => {
        const storedParams = localStorage.getItem('filterParams');
        return storedParams ? JSON.parse(storedParams) : {
            registrationStatus: null,
            paymentStatus: null,
            roomAllocationStatus: null,
            areaCoordinator: null
        };
    });

    const [registrationView, setRegistrationView] = useState(localStorage.getItem('registrationView') || "REGISTRATION");

    useEffect(() => {
        ApiRequest.handleGetApiRequest('/config').then((response) => {
            setConfigData(response);
            setIsConfigLoading(false);
        });
    }, []);

    useEffect(() => {
        handleSetUniqueValues();
    }, [registrations])

    const handleSetUniqueValues = () => {
        if (isLoading || !registrations)
            return;

        const uniqueVs = new Set(); // Initialize a set to store unique values

        registrations.forEach(row => {
            uniqueVs.add(row.id);
            uniqueVs.add(row.userDetails.name);
            uniqueVs.add(row.userDetails.initiatedName);
        });

        setUniqueValues(Array.from(uniqueVs))
    }

    // Reload the data
    const handleReloadData = async () => {
        const registrationsData = await ApiRequest.handleGetApiRequest('/admin/all/registration/lite',
            {
                ...filterParams,
            });
        const sortedData = applySorting(registrationsData, sortConfig);
        setRegistrations(sortedData);
    }

    useEffect(() => {
        setIsLoading(true);
        handleReloadData();
        setIsLoading(false);
    }, [filterParams]);

    // Pagination handlers
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        localStorage.setItem('rowsPerPage', event.target.value);
        setPage(0);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, registrations.length - page * rowsPerPage);

    // Function to handle drawer operations
    const handleRowClick = async (row) => {
        const registrationData = await ApiRequest.handleGetApiRequest('/registration/', { registrationId: row.id });
        setSelectedRegistration(row);
        setSelectedRegistrationData(registrationData);
        setDrawerOpen(true);
    };

    const handleCloseDrawer = () => {
        setDrawerOpen(false);
        setSelectedRegistration(null);
        setSelectedRegistrationData(null);
    };

    // Function to apply sorting
    const applySorting = (data, sortConfig) => {
        return data.sort((a, b) => {
            for (const { key, direction } of sortConfig) {
                let aValue = getNestedValue(a, key);
                let bValue = getNestedValue(b, key);

                // Convert values to numbers if they are numeric
                const aIsNumber = !isNaN(aValue) && aValue !== null && aValue !== '';
                const bIsNumber = !isNaN(bValue) && bValue !== null && bValue !== '';

                if (aIsNumber && bIsNumber) {
                    // Compare as numbers
                    const comparison = (aValue - bValue);
                    if (comparison !== 0) {
                        return direction === 'asc' ? comparison : -comparison;
                    }
                } else {
                    // Convert values to strings if they are not already strings
                    if (typeof aValue !== 'string') aValue = String(aValue);
                    if (typeof bValue !== 'string') bValue = String(bValue);

                    // Compare as strings
                    const comparison = aValue.localeCompare(bValue, undefined, { sensitivity: 'base' });
                    if (comparison !== 0) {
                        return direction === 'asc' ? comparison : -comparison;
                    }
                }
            }
            return 0;
        });
    };

    // Helper function to get nested values
    const getNestedValue = (obj, key) => {
        return key.split('.').reduce((o, i) => (o ? o[i] : undefined), obj);
    };

    // Function to handle sort changes
    const handleSort = (column) => {
        const existingSortIndex = sortConfig.findIndex(config => config.key === column);

        let newSortConfig = [...sortConfig];

        if (existingSortIndex > -1) {
            const currentDirection = newSortConfig[existingSortIndex].direction;
            if (currentDirection === 'asc') {
                newSortConfig[existingSortIndex].direction = 'desc';
            } else {
                newSortConfig.splice(existingSortIndex, 1);
            }
        } else {
            newSortConfig.push({ key: column, direction: 'asc' });
        }

        setSortConfig(newSortConfig);
        const sortedData = applySorting([...registrations], newSortConfig);
        setRegistrations(sortedData);
    };

    // Function to clear all sorting
    const handleClearSorting = () => {
        setSortConfig([{ key: 'id', direction: 'desc' }]);
        const sortedData = applySorting([...registrations], [{ key: 'id', direction: 'desc' }]);
        setRegistrations(sortedData);
    };

    const updateSingleRegistration = async (registrationDTO) => {
        const registrationLiteData =  await ApiRequest.handleGetApiRequest('/registration/lite', {registrationId: registrationDTO.id})
        setSelectedRegistrationData(registrationDTO)
        setSelectedRegistration(registrationLiteData)
        setRegistrations(prevList =>
            prevList.map(obj =>
                obj.id === registrationLiteData.id ? registrationLiteData : obj
            )
        );
    }

    // Generic method to render table cells with sorting
    const renderTableCell = (columnKey, label) => {
        const sortConfigForKey = sortConfig.find(config => config.key === columnKey);
        const isSorted = !!sortConfigForKey;
        const direction = isSorted ? sortConfigForKey.direction : 'asc';

        return (
            <TableCell style={{ paddingBottom: 5, paddingTop: 5 }} onClick={() => handleSort(columnKey)}>
                <strong>{label}</strong>
                {isSorted && (direction === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />)}
            </TableCell>
        );
    };

    if (isLoading || isConfigLoading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Paper>
                <div className="rps-flex section-header" style={{ paddingTop: '30px' }}>
                    <h4 className="flex-one">
                        <strong>
                            All registrations
                        </strong>
                    </h4>
                </div>
                <FilterPanel
                    uniqueValues={uniqueValues}
                    filterParams={filterParams}
                    setFilterParams={setFilterParams}
                    handleReloadData={handleReloadData}
                    setIsLoading={setIsLoading}
                    handleClearSorting={handleClearSorting}
                    configData={configData}
                    registrations={registrations}
                    setRegistrations={setRegistrations}
                    registrationView={registrationView}
                    setRegistrationView={setRegistrationView}
                />
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={registrations.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {renderTableCell('id', 'Reg Id')}
                                {renderTableCell('userDetails.name', 'Name')}
                                {renderTableCell('userDetails.initiatedName', 'Initiated Name')}
                                {renderTableCell('numMembers', 'Members')}
                                {renderTableCell('numRooms', 'Rooms')}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {registrations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                <React.Fragment key={row.id}>
                                    <TableRow onClick={() => handleRowClick(row)} className={getRowClass(row)}>
                                        <TableCell style={{ paddingBottom: 5, paddingTop: 5 }}>{row.id}</TableCell>
                                        <TableCell style={{ paddingBottom: 5, paddingTop: 5 }}>{row.userDetails.name}</TableCell>
                                        <TableCell style={{ paddingBottom: 5, paddingTop: 5 }}>{row.userDetails.initiatedName}</TableCell>
                                        <TableCell style={{ paddingBottom: 5, paddingTop: 5 }}>{row.numMembers}</TableCell>
                                        <TableCell style={{ paddingBottom: 5, paddingTop: 5 }}>{row.numRooms}</TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={5} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={registrations.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={handleCloseDrawer}
                PaperProps={{
                    sx: {
                        width: '75vw',
                    }
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '16px' }}>
                    <IconButton onClick={handleCloseDrawer}>
                        <CloseIcon />
                    </IconButton>
                </div>
                {selectedRegistrationData && (
                    <RegistrationCard
                        default_expanded={true}
                        registration={selectedRegistration}
                        registrationData={selectedRegistrationData}
                        configData={configData}
                        from={from}
                        handleReloadData={handleReloadData}
                        updateSingleRegistration={updateSingleRegistration}
                        registrationView={registrationView}
                    />
                )}
            </Drawer>
        </>
    );
};

export default AllRegistrationLite;

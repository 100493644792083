import { jwtDecode } from 'jwt-decode';

import axios from 'axios';

const getAccessToken = async () => {
    let accessToken = localStorage.getItem('accessToken');

    if (!accessToken || isAccessTokenExpired(accessToken)) {
        accessToken = await generateAccessToken();
        if (!accessToken) {
            window.location.href = '/login';
        }
    }
    return accessToken;
}

const isRefreshTokenExpired = () => {
    const token = localStorage.getItem('refreshToken');
    if (!token)
        return false;
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    if (decodedToken.exp > currentTime)
        return true
    return false
}

const isAccessTokenExpired = (token) => {
    try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        // get new access token if the token is above to expire in 1 min.
        return decoded.exp < (currentTime - 5);
    } catch (error) {
        return true; // If error in decoding, consider token expired
    }
};

const generateAccessToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken)
        return;

    try {
        const credentials = {
            refreshToken: refreshToken,
        };
        const response = await axios.post(process.env.REACT_APP_API_URL + '/token', credentials);

        if (response.data.success) {
            const data = response.data.result;
            localStorage.setItem('accessToken', data.accessToken);
            localStorage.setItem('refreshToken', data.refreshToken);
            return data.accessToken;
        } else {
            console.error('Refresh token error', await response.text());
            window.location.href = '/login';
        }
    } catch (error) {
        console.error('Network error:', error.message);
    }
};

const handleResetPassword = async (email, otp, password, confirmPassword) => {
    try {
        const credentials = {
            email: email,
            otp: otp,
            password: password,
            confirmPassword: confirmPassword,
        };

        const response = await axios.post(process.env.REACT_APP_API_URL + '/reset/password', credentials);
        return response.data;
    } catch (error) {
        return { success: false, message: 'Network error: ' + error.message };
    }
};

const handleSignup = async (email, otp, password, confirmPassword) => {
    try {
        const credentials = {
            email: email,
            otp: otp,
            password: password,
            confirmPassword: confirmPassword,
        };

        const response = await axios.post(process.env.REACT_APP_API_URL + '/signup', credentials);
        return response.data;
    } catch (error) {
        return { success: false, message: 'Network error: ' + error.message };
    }
};

const handleLogin = async (username, password) => {
    try {
        const credentials = {
            username: username,
            password: password,
        };

        const response = await axios.post(process.env.REACT_APP_API_URL + '/login', credentials);
        if(response.data.success) {
            const data = response.data.result;
            localStorage.setItem('accessToken', data.accessToken);
            localStorage.setItem('refreshToken', data.refreshToken);
        }
        return response.data;
    } catch (error) {
        // Return network error message
        return { success: false, message: 'Network error: ' + error.message };
    }
};

const getRoleFromToken = (accessToken) => {
    try {
        // Decode the token
        const decodedToken = jwtDecode(accessToken);
        // Assuming the role is stored in the 'role' claim
        const roles = decodedToken.groups;
        return roles;
    } catch (error) {
        console.error('Error decoding token:', error);
        return null;
    }
};

const isAccountsPermission = () => {
    let accessToken = localStorage.getItem('accessToken');
    // Get role from access token
    if(!accessToken)
        return false;
    const roles = getRoleFromToken(accessToken);
    return roles.includes('ACCOUNTS');
};

const isAdmin = () => {
    let accessToken = localStorage.getItem('accessToken');
    // Get role from access token
    if(!accessToken)
        return false;
    const roles = getRoleFromToken(accessToken);
    return roles.includes('ADMIN');
};

const isUserLoggedIn = () => {
    let accessToken = localStorage.getItem('accessToken');
    let refreshToken = localStorage.getItem('refreshToken');
    if (accessToken && refreshToken)
        return isRefreshTokenExpired();
    return false;
}


const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
};

const sendOTP = async (email, resetPassword) => {
    try {
        const response = await axios.post(process.env.REACT_APP_API_URL + "/send/otp", null, {
            headers: {
                'Content-Type': 'application/json',
            },
            params: {email: email, resetPassword: resetPassword},
        });
        // const response = await axios.post(process.env.REACT_APP_API_URL + "/send/otp?email=" + encodeURIComponent(email));
        return response.data;
    } catch (error) {
        return { success: false, message: 'Network error: ' + error.message };
    }
};

const LoginService = {
    isAdmin,
    isAccountsPermission,
    handleResetPassword,
    handleSignup,
    getAccessToken,
    handleLogin,
    handleLogout,
    isRefreshTokenExpired,
    isUserLoggedIn,
    sendOTP,
}

export default LoginService;

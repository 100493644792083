import { Box, Button, Paper, Typography, createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/styles';
import { ValidationPopup } from 'components/generics/ValidationPopup';
import { useState } from 'react';
import ApiRequest from 'services/api.service';
import LoginService from 'services/login.service';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2196f3',
        },
        secondary: {
            main: '#f50057',
        },
    },
});

const OnlineTransaction = ({ registration, defaultExpanded, isPrint, updateSingleRegistration }) => {

    const [error, setError] = useState('');
    const [confirmMessage, setConfirmMessage] = useState('')
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupAction, setPopupAction] = useState('error');
    const [popupType, setPopupType] = useState('error');

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString(undefined, { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' });
    };

    const captureTransaction = () => {
        setPopupType('confirm');
        setConfirmMessage('Are you sure you want to capture this transaction as paid?')
        setIsPopupOpen(true);
        setPopupAction(() => async () => {
            try {
                setConfirmMessage('Started capturing.... please wait')
                const registrationDTO = await ApiRequest.handlePostApiRequest('/admin/capture/transaction/all', {}, { regId: registration.id });
                if (registrationDTO) {
                    await updateSingleRegistration(registrationDTO);
                }
                setConfirmMessage('Done..!!!')
                setIsPopupOpen(false);
                setPopupType('error');
            } catch (error) {
                alert(error.message)
                alert('Could not capture the payment as paid')
            }
        });
    }

    const captureRefund = () => {
        setPopupType('confirm');
        setConfirmMessage('Are you sure you want to capture this refund')
        setIsPopupOpen(true);
        setPopupAction(() => async () => {
            try {
                setConfirmMessage('Started capturing.... please wait')
                const registrationResponse = await ApiRequest.handlePostApiRequest('/admin/capture/refund/all', {}, { regId: registration.id });
                if (registrationResponse) {
                    await updateSingleRegistration(registrationResponse);
                } else {
                    alert('Could not capture the refund')
                }
                setConfirmMessage('Done..!!!')
                setIsPopupOpen(false);
                setPopupType('error');
            } catch (error) {
                alert(error.message)
                alert('Could not capture the refund')
            }
        });
    }

    return (
        <ThemeProvider theme={theme}>
            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                <ValidationPopup
                    isOpen={isPopupOpen}
                    message={popupType === 'error' ? error : confirmMessage}
                    type={popupType}
                    onConfirm={popupAction}
                    onCancel={() => {
                        setIsPopupOpen(false);
                        setPopupType('error');
                        setError('')
                    }}
                />
                <Typography variant="h6" gutterBottom sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <strong>Online transaction</strong>
                    <div className="registration-form-content-actions" style={{ 'justify-content': 'end' }}>
                        {LoginService.isAdmin() && !isPrint &&
                            <>
                                <Button
                                    variant="contained"
                                    onClick={() => captureTransaction()}
                                    size="small" // Make the button small
                                    sx={{
                                        marginLeft: 'auto' // Push the button to the right side
                                    }}
                                >
                                    Capture delayed
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() => captureRefund()}
                                    size="small" // Make the button small
                                    sx={{
                                        marginLeft: 'auto' // Push the button to the right side
                                    }}
                                >
                                    Capture refund
                                </Button>
                            </>}
                    </div>
                </Typography>
                {registration.paymentHistoryDTOs.map((transaction, index) => (
                    <Box key={index} mb={1}>
                        {/* <Typography variant="body1"><strong>{`Transaction ${index + 1}`}</strong></Typography> */}
                        <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span><strong>{`Transaction ${index + 1}`}</strong></span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>Transaction Id:</span>
                            <span>{transaction.razorpayPaymentId}</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>Amount Paid:</span>
                            <span>{transaction.amountPaid}</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>Amount Accounted:</span>
                            <span>{transaction.amountAccounted}</span>
                        </Typography>
                        {transaction.amountRefunded > 0 &&
                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Amount Refunded:</span>
                                <span>{transaction.amountRefunded}</span>
                            </Typography>}
                        <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>Created at:</span>
                            <span>{formatTimestamp(transaction.creationTimestamp)}</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>Finished at:</span>
                            <span>{formatTimestamp(transaction.successfulTimestamp)}</span>
                        </Typography>
                    </Box>
                ))}
                <Typography variant="h5" mt={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>Grand Total (O):</span>
                    <span>{registration.onlineAmount}</span>
                </Typography>
            </Paper>
        </ThemeProvider>
    );
};

export default OnlineTransaction;

import React, { useState } from 'react';
import { TextField, Button, Typography, Box, IconButton, Card, CardContent } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import { AddBox } from '@mui/icons-material';

const RegistrationChargesForm = ({ onRegistrationChargesChange }) => {
  const [registrationCharge, setRegistrationCharge] = useState({
    id: null,
    displayText: '',
    value: ''
  });

  const [isEditing, setIsEditing] = useState(false);
  const [registrationCharges, setRegistrationCharges] = useState([]);
  const [error, setError] = useState('');

  const handleRegistrationChargeChange = (e) => {
    const { name, value } = e.target;
    setRegistrationCharge({
      ...registrationCharge,
      [name]: value
    });
  };

  const handleAddRegistrationCharge = () => {
    if (registrationCharge.displayText.trim() !== '' && registrationCharge.value.trim() !== '') {
      if (isEditing) {
        const updatedRegistrationCharges = registrationCharges.map((charge, index) =>
          index === registrationCharge.id ? registrationCharge : charge
        );
        setRegistrationCharges(updatedRegistrationCharges);
        setIsEditing(false);
        onRegistrationChargesChange(updatedRegistrationCharges);
      } else {
        setRegistrationCharges([...registrationCharges, registrationCharge]);
        onRegistrationChargesChange([...registrationCharges, registrationCharge]);
      }
      resetRegistrationCharge();
      setError('');
    } else {
      setError('Both Display Text and Value are required.');
    }
  };

  const handleEditRegistrationCharge = (index) => {
    const editedRegistrationCharge = registrationCharges[index];
    setRegistrationCharge({
      ...editedRegistrationCharge,
      id: index,
    });
    setIsEditing(true);
  };

  const handleDeleteRegistrationCharge = (index) => {
    const updatedRegistrationCharges = registrationCharges.filter((charge, i) => i !== index);
    setRegistrationCharges(updatedRegistrationCharges);
    onRegistrationChargesChange(updatedRegistrationCharges);
  };

  const resetRegistrationCharge = () => {
    setRegistrationCharge({
      id: null,
      displayText: '',
      value: ''
    });
  };

  const handleClearError = () => {
    setError('');
  };

  const handleUpdateRegistrationCharge = () => {
    if (isEditing) {
      handleAddRegistrationCharge();
    }
  };

  return (
    <Box>
      <Typography variant="h6">Registration Charges</Typography>
      {error && <Typography variant="body2" color="error">{error}</Typography>}
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Display Text</th>
              <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Value</th>
              <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {registrationCharges.map((charge, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>{charge.displayText}</td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>{charge.value}</td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                  <IconButton onClick={() => handleEditRegistrationCharge(index)} aria-label="edit">
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteRegistrationCharge(index)} aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        {registrationCharges.map((charge, index) => (
          <Card key={index} sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="subtitle1">{charge.displayText}</Typography>
              <Typography variant="body2">{charge.value}</Typography>
              <IconButton onClick={() => handleEditRegistrationCharge(index)} aria-label="edit">
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleDeleteRegistrationCharge(index)} aria-label="delete">
                <DeleteIcon />
              </IconButton>
            </CardContent>
          </Card>
        ))}
      </Box>
      <TextField name="displayText" label="Display Text" value={registrationCharge.displayText} onChange={handleRegistrationChargeChange}/>
      <TextField name="value" label="Value" value={registrationCharge.value} onChange={handleRegistrationChargeChange}/>
      <br />
      <Button variant="contained" onClick={handleAddRegistrationCharge} sx={{ mt: 1 }}>
        {isEditing ? <UpdateIcon sx={{ mr: 1 }} /> : <AddBox sx={{ mr: 1 }} />} {isEditing ? 'Update Registration Charge' : 'Add Registration Charge'}
      </Button>
    </Box>
  );
};

export default RegistrationChargesForm;

import React from 'react';

import styles from './PricingPolicy.module.scss';

function PricingPolicy() {

  return (
    <div className={styles.pricingPolicyMain}>
      <h3 className={styles.heading}>Payments, Pricing Policy</h3>

      <div className={styles.parentSection}>
        <h5 className={styles.sectionHeading}>Payments</h5>
        <p>
          <strong>DEPOSITS:</strong> A deposit, paid via one of our accepted payment methods, is required to secure your Tour Only booking, which will be applied toward the cost of your package. Selected tours will now require an increased deposit when airfare to and from your destination is included. Deposits vary by package, and some departure dates and/or departure cities may require a different deposit. Please check the package page, start a booking, or contact us to find out the required deposit. Changes or customizations you request to our packages may require an increased or additional deposit. Deposits are generally nonrefundable after we confirm your booking.
        </p>
        <p>
          <strong>FINAL PAYMENT:</strong> If any balance is due after making your booking, you must pay this balance via one of our accepted payment methods by your final payment due date. This date varies by package but is most often 60 days prior to departure. The due date for your booking will be listed in the booking process, in your booking receipt email, under Your Booking, and on your invoice. If you have not paid in full by your due date, your booking will be subject to a late fee as described in your package. If you have not paid in full 10 days after your due date, your booking will be cancelled, and your deposit will be forfeited. If you make payments in excess of your balance due, you can choose to receive a refund of the excess amount or to convert it to a Travel Credit with no expiration date.
        </p>
        <p>
          <strong>PAYMENT METHODS:</strong> We accept payments towards your deposit or balance due by the following methods:
        </p>
        <p>1) Visa, MasterCard, Discover, (Debit or Credit) and American Express: You can use one of these cards when booking or making a payment via our website or with an agent by phone. To pay your deposit with multiple credit cards, please call us. You may also pay by credit card by printing our credit card authorization form, completing and signing it, then emailing, faxing, or mailing it to our office.</p>
        <p>2) Checks, including personal checks, cashier's checks To make a payment via check, please make it payable to DHAM YATRA LLP, write your booking number on the check, and mail it to our office. To pay your deposit with a check, we strongly suggest you first call us to start your booking, and then mail your payment via overnight delivery, as we cannot confirm your space or price until we receive it.</p>
        <p>3) Travel Credits issued by us: To redeem travel credits, please contact us.</p>
        <p>If any mode of payment is not working anytime for any reason technical or otherwise Please contact us.</p>
        <p>
          <strong>RETURNED PAYMENTS:</strong> If a check payment made to us is returned due to insufficient funds, stop payment, a closed account, or any other reason, we will notify you, and a returned check fee of INR 500 or $50 (In case payment made other than INR) will be added to your balance due. You are responsible for making alternate payments to pay your balance due by the final payment due date. If we notify you after your final payment due date that a check has been returned, we must receive a replacement payment within 48 hours, or your booking may be subject to cancellation.
        </p>
      </div>

    </div>
  );

}

export default PricingPolicy;